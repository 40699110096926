import React, { useState, useEffect } from "react";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../common/element/Buttons/btnPrimary";

import Validations from "../../../../../common/404/Validations";
import {
  UpdateClientUserProfile,
} from "../../../../../actions/client-portal/AllSettingApis";
import { GetClientUserPermByIdForClient } from "../../../../../actions/client-portal/AllUsersApi";
import { useDispatch } from "react-redux";
import imagePlus from "../../../../../assets/icons-svg/image-plus.svg";
import { updateAvtarfunc } from "../../../../../redux/classes/common/updateAvtar";
import Dialog from "../../../../../common/element/Dialog";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { GetWorkerOverviewDetailsById } from "../../../../../actions/admin-worker/GetWorkerOverviewDetailsById";
import { UpdateProviderUserProfile } from "../../../../../actions/provider-portal/UpdateContractorUserProfile";
import Security from "../Security";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";

const YourProfile = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [dialogAddOpen, setdialogAddOpen] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    job_title: "",
    email: "",
    photo: "",
  });
  const [formErrors, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    job_title: "",
    email: "",
    photo: "",
  });
  const [attachemnt, setAttachment] = useState("");
  const [urlImage, setUrlImage] = useState();
  const [documentError, setdocumentError] = useState("");
  const permissionDisabled = props.permission_data.settings_write == true ? false : true;
  let getApiData;
  let settings_permissions;
  let function_assignment_id;
  let user_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const permissionData = JSON.parse(localStorage.getItem("permission_data"));
  if (permissionData !== null) {
    settings_permissions = permissionData.settings_write;
  }
  if (storedData) {
    getApiData = {
      organisation_id: storedData.organisation_id,
      individual_id: storedData.userName.individual_id,
    };
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
  }
  
  // api call for permissions
  useEffect(() => {
    const apiData = {
      user_id: user_id,
      function_assignment_id: function_assignment_id,
    };
    dispatch(GetClientUserPermByIdForClient(apiData)).then((response) => {
      if (response.success === true) {
        // const arrayData = response.data;
        localStorage.setItem("permission_data", JSON.stringify(response.data));
      } else if (response.status === 401) {
        handleUnauthorizedAccess()
      }
    });
  }, []);

  const updateStateFromApi = (arrayData) => {
    setFormData({
      ...formData,
      first_name: arrayData.first_name,
      last_name: arrayData.last_name,
      job_title: arrayData.job_title ? arrayData.job_title : "",
      email: arrayData.email,
      individual_id: arrayData.individual_id,
      // photo: arrayData.avatar
    });
    setUrlImage(arrayData.avatar);
    setAttachment(arrayData.avatar);
  };

  useEffect(() => {
    dispatch(GetWorkerOverviewDetailsById(getApiData, "contractPortal")).then((response) => {
      if (response.success) {
        const arrayData = response.data;
        updateStateFromApi(arrayData);
      } else if (response.status === 401) {
        handleUnauthorizedAccess()
      }
    });
  }, []);

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      inputChangeHandlerFile(file);
    }
  };

  const inputChangeHandlerFile = (file) => {
    // Check the file extension
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/svg+xml" ||
        fileType.startsWith("image/png") ||
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/gif")
      ) {
        // Check the file size (max. 800x400px)
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.width <= 800 && img.height <= 400) {
            setAttachment(file);
            setUrlImage(URL.createObjectURL(file));
            setdocumentError("");
          } else {
            // Handle size validation error
            setdocumentError("Image dimensions must be within 800x400 pixels.");
          }
        };
      } else {
        // Handle file type validation error
        setdocumentError(
          "Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed."
        );
      }
    }
  };

  const handleInputChange = (e, identifier) => {
    const value = e.target.value;
    setFormData({ ...formData, [identifier]: value });
  };

  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const handleSubmit = (e) => {
    // e.preventDefault()
    const fnameValidation = Validations({
      value: formData.first_name,
      Validations: { required: true },
    });
    const lnameValidation = Validations({
      value: formData.last_name,
      Validations: { required: true },
    });
    const emailValidation = Validations({
      value: formData.email,
      Validations: { required: true },
    });

    if (!fnameValidation.valid) {
      setFormError("nameError", fnameValidation.error_msg);
    }

    if (fnameValidation.valid === true) {
      const payload = {
        individual_id: getApiData.individual_id,
        organisation_id: getApiData.organisation_id,
        first_name: formData.first_name,
        last_name: formData.last_name,
        job_title: formData.job_title,
        avatar: attachemnt,
      };

      dispatch(UpdateProviderUserProfile(payload))
        .then((response) => {
          if (response.success) {

            // props.onDialogClose();
            dispatch(GetWorkerOverviewDetailsById(getApiData, "contractPortal")).then((response) => {
              if (response.success) {
                const arrayData = response.data;
                updateStateFromApi(arrayData);
                // Update the avatar key for the specific user
                const updatedData = {
                  ...storedData,
                  userName: {
                    ...storedData.userName,
                    avatar: arrayData.avatar,
                    first_name: arrayData.first_name,
                    last_name: arrayData.last_name,
                  },
                };
                setdialogAddOpen(true);
                dispatch(updateAvtarfunc(true));
                localStorage.setItem(
                  "LoginUserData",
                  JSON.stringify(updatedData)
                );
              } else if (response.status === 401) {
                handleUnauthorizedAccess()
              }
            });
          } else if (response.status === 401) {
            handleUnauthorizedAccess()
          }
        })
        .catch((error) => {
        });
    }
  };

  const onAddDialogClose = () => {
    setdialogAddOpen(false);
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
        <div>
          <h5 className="text-base text-gray-900 font-semibold mb-1">
            Personal info
          </h5>
          <p className="text-gray-600">
            Update your photo and personal details here.
          </p>
        </div>
        {/* {settings_permissions && ( */}
        {/*permission for Read and Write */}
        <div className="flex justify-end items-center">
          <BtnLight
            disabled={permissionDisabled}
            className="w-auto px-4">Cancel</BtnLight>
          <SmallButton
            disabled={permissionDisabled}
            className="w-auto px-4 ml-3"
            onClick={() => handleSubmit()}
          >
            Save
          </SmallButton>
        </div>
        {/* )} */}
      </div>
      <div className="w-2/3 ">

      <div className="flex items-start mb-5">
        {/*permission for Read and Write */}
        <label className="text-gray-700 font-semibold w-1/4">Full name</label>
        <div className="flex items-center w-9/12 flex-wrap">
          <div className="flex gap-3 w-full">
            <div className="flex-1">
              <input
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter first name"
                value={formData.first_name}
                disabled={permissionDisabled}
                onChange={(e) => handleInputChange(e, "first_name")}
              />
            </div>
            <div className="flex-1">
              <input
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter second name"
                value={formData.last_name}
                disabled={permissionDisabled}
                onChange={(e) => handleInputChange(e, "last_name")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-1/4">Job title</label>
        <div className="flex items-center w-9/12 flex-wrap">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Job title here"
            value={formData.job_title}
            disabled={permissionDisabled}
            onChange={(e) => handleInputChange(e, "job_title")}
          />
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-1/4">
          Email address
        </label>
        <div className="flex items-center w-9/12 flex-wrap relative">
          <span className="absolute left-3 top-2">
            <img src="/assets/icons-svg/mail-01.svg" alt="" />
          </span>
          <input
            disabled={permissionDisabled}
            className="input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline' h-9 w-full pl-10"
            placeholder="name@emailaddress.com"
            value={formData.email}
          />
        </div>
      </div>
      <div className="flex items-start  border-gray-200">
        <label className="text-gray-700 font-semibold w-1/4">Your photo</label>
        <div className="columns-input">
          <div className="flex flex-row">
            <span className="h-16 w-16 bg-gray-100 rounded-full flex justify-center items-center mr-5 avtar_img_round">
              {urlImage ? (
                <img className="w-16 h-16 rounded-full" src={urlImage} alt="" />
              ) : (
                <img src={imagePlus} alt="" />
              )}
            </span>
            <div className="flex items-center justify-center  flex-1 upload-img cursor-pointer">
              <div className="flex items-center justify-center  flex-1 upload-img ">
                <label
                  htmlFor="dropzone-file"
                  className="cursor-pointer flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white"
                >
                  <div
                    className="flex items-center justify-center  flex-1 upload-img ml-8"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={onDropHandler}
                  >
                    <div className="flex flex-col items-center justify-center">
                      <p className="mb-2 text-xs text-gray-600 darks:text-gray-400">
                        <span className="font-semibold text-blue-700">
                          Click to upload
                        </span>
                        <span className="text-xs"> or drag and drop</span>
                      </p>
                      <p className="text-xs text-gray-600 darks:text-gray-400 font-inter">
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </p>
                      {documentError && (
                        <div className="text-red-500 text-sm mt-1">
                          {documentError}
                        </div>
                      )}
                    </div>
                  </div>
                  <input
                    disabled={permissionDisabled}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={(e) => inputChangeHandlerFile(e.target.files[0])}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <Security permission_data={props.permission_data} />
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Your profile has been saved successfully
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={() => onAddDialogClose()}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default YourProfile;
