import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ViewAllClients from "../pages/Client/viewAllClients";
import SpecificClients from "../pages/Client/SpecificClient";
import Notes from "../pages/Client/SpecificClient/Notes";
import ClientSettings from "../pages/Client/SpecificClient/Settings";
import SpecificSite from "../pages/Client/SpecificSite/index";
import Dashboard from "../pages/Client/dashboard";
import Invoice from "../pages/Client/Invoice";
import NewInvoice from "../pages/Client/Invoice/NewInvoice";
import Reports from "../pages/Client/Reports";

function useLocalStoragePath() {
  const location = useLocation();
  
  useEffect(() => {
    localStorage.setItem("currentPath", location.pathname);
  }, [location.pathname]);

  return location.pathname;
}
export const AdminPortalClientRoutes = () => {
  const currentPath = useLocalStoragePath();
  return (
    <React.Fragment>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="view-all-clients" element={<ViewAllClients />} />
        <Route
          path="view-all-clients/specific-client/:id/*"
          element={<SpecificClients />}
        />
        <Route
          path="view-all-clients/specific-client/:id/notes"
          element={<Notes />}
        />
        <Route
          path="view-all-clients/specific-client/:id/settings"
          element={<ClientSettings />}
        />
        <Route
          path="view-all-clients/specific-client/:id/specific-site/:siteId"
          element={<SpecificSite />}
        />
        
        <Route path="invoice" element={<Invoice />} />
        <Route path="invoice/new-invoice" element={<NewInvoice />} />
        <Route path="reports" element={< Reports/>} />
      </Routes>
    </React.Fragment>
  );
};
