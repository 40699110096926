import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import Upload from "../../../../../common/element/Upload";
import BtnLight from '../../../../../common/element/Buttons/btnLight';
import BtnPrimary from '../../../../../common/element/Buttons/btnPrimary';
import * as userActions from '../../../../../actions/index';
import FileItem from "../../../../../common/element/Upload/FileItem";
import CloseButton from "../../../../../common/element/CloseButton";
import Validations from "../../../../../common/404/Validations";
import trashIcon from "../../../../../assets/icons-svg/trash.svg";
import PdfFile from "../../../../../assets/icons-svg/upload-pdf.svg";
import { TailSpin } from "react-loader-spinner";
import Select from "../../../../../common/element/Select";
import { GetClientContactsForSiteList } from "../../../../../actions/client-portal/AllSiteApi";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { useParams } from "react-router-dom";
import { decryptId } from "../../../../../common/encryptDecrypt/encryptdecrypt";
const AddSite = (props) => {
  const { id } = useParams();
  const orgId=decryptId(id)

  const dispatch = useDispatch()
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [search, setSearch] = useState("")
  const [searchOptions, setSearchOptions] = useState([]);
  const [documentError, setdocumentError] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState({
    siteName: '',
    siteAdd1: '',
    siteAdd2: '',
    siteCity: '',
    // siteState: '',
    sitePinCode: '',
    // siteCountry: '',
    validDays: '',
    siteContact_Individual_Ids: [],
  })
  const [formErrors, setFormErrors] = useState({
    nameError: '',
    add1Error: '',
    cityError: '',
    pinCodeError: '',
    stateError: '',
    countryError: '',
    site_Contacts_Ids_Error: ""
  });
  // const [isSaving, setIsSaving] = useState(false);
  const [allStates, setAllStates] = useState();
  const [allCountries, setAllCountries] = useState();
  const [siteState, setSiteState] = useState();
  const [siteCountry, setSiteCountry] = useState();
  const [attachment, setAttachment] = useState();
  const [filesUploaded, setFilesUploaded] = useState(false);
  const functionAssignmentId = useSelector(
    (state) => state.functionAssignmentId.functionAssignmentId
  )

  let organisation_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    organisation_id = storedData.organisation_id;
  }
  const handleInputChange = (e, identifier) => {
    const value = e.target.value;
    setFormData({ ...formData, [identifier]: value });
  }

  const GetAllStatesbyCountryId = useCallback(async () => {
    if (siteCountry) {
      dispatch(userActions.GetAllStatesbyCountryId(siteCountry)).then((response) => {
        if (response?.success === true) {
          const arrayData = response?.data
          let state_data = [];
          for (let key in arrayData) {
            state_data.push({
              label: arrayData[key].state_name,
              value: arrayData[key].state_id,
            })
          }
          setAllStates(state_data)
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        }
        else { }
      });
    }
    // eslint-disable-next-line
  }, [dispatch, siteCountry]);
  const fetchClientContacts = useCallback(() => {
    const emailForApi = { organisation_id: orgId };
    dispatch(GetClientContactsForSiteList(emailForApi)).then((response) => {
      if (response?.success) {
        const filterData = [];
        for (let i of response?.data) {
          filterData.push({ label: `${i.first_name} ${i.last_name}`, value: i.individual_org_id, value_Indi: i.individual_id });
        }
        setSearchOptions(filterData);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
      }
    });
  }, [dispatch, organisation_id]);

  useEffect(() => {
    fetchClientContacts();
  }, [fetchClientContacts]);

  const handleSearchChange = (newInputValue) => {
    setSearch(newInputValue);
  };
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : [...searchOptions];

  const handleSelectChange = (selectedOption) => {
    let filterData = selectedOption.map((item) => item.value_Indi)
    setSelectedOption(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      siteContact_Individual_Ids: filterData
    }))
    setFormError("site_Contacts_Ids_Error", "");

  };

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
            // country_id: arrayData[key].country_id,
          })
        }
        setAllCountries(country_data)
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      } else { }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAllCountries();
    GetAllStatesbyCountryId();
  }, [siteCountry])

  // const handleFileChange = (files) => {

  //   if (files?.[0]?.size <= 25 * 1024 * 1024) {
  //     setAttachment({ files });
  //     setdocumentError("");
  //     setFilesUploaded(true)
  //   }
  //   else {
  //     setdocumentError("File size exceeds the maximum limit of 25MB.")
  //   }
  // };
  const handleFileChange = (files) => {

    let totalSize = 0;
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    // Filter and validate files
    let fileTypeError = false;
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          setdocumentError(
            "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
          );
          fileTypeError = true
          return false;
        }

        // Check individual file size (2MB limit)
        if (fileSize > 25 * 1024 * 1024) {
          setdocumentError("File size exceeds the 25MB limit.");
          totalSize += fileSize;
          return false;
        }
        else {
          totalSize += fileSize;
          return true;
        }

      }
      return false;
    });
    
    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
      return;
    }

    // Update state if everything is valid
    if (validatedFiles?.length > 0) {
      setAttachment({ files: validatedFiles });

      if (fileTypeError) {
        setdocumentError(
          "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
        );
        return false;
      }
      else {
        if (totalSize > 25 * 1024 * 1024) {
          setdocumentError("Total file size exceeds the maximum limit of 25MB.");
          return;
        }
        else {
          setdocumentError(""); // Clear any existing errors
        }
      }
    } else {
      setAttachment({ files: [] }); // Clear files if no valid files
    }
  };
  const handleFileChangeAdditional = (files) => {

    // let totalSize = 0;
    // // Calculate the total size of all files
    // files?.forEach((item) => {
    //   if (item.size) {
    //     totalSize += item?.size;
    //   }
    // });
    // // Check if the total size is within the 25MB limit
    // if (totalSize <= 25 * 1024 * 1024) {
    //   setAttachment({ files });
    //   setdocumentError(""); // Clear any existing errors if the size is within the limit
    // } else {
    //   setdocumentError("File size exceeds the maximum limit of 25MB.");
    // }
    let totalSize = 0;
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    // Filter and validate files
    let fileTypeError = false;
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          setdocumentError(
            "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
          );
          fileTypeError = true
          return false;
        }

        // Check individual file size (2MB limit)
        if (fileSize > 25 * 1024 * 1024) {
          setdocumentError("File size exceeds the 25MB limit.");
          return false;
        }

        totalSize += fileSize;
        return true;
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
      return;
    }

    // Update state if everything is valid
    if (validatedFiles?.length > 0) {
      setAttachment({ files: validatedFiles });

      if (fileTypeError) {
        setdocumentError(
          "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
        );
        return false;
      }
      else {
        setdocumentError(""); // Clear any existing errors
      }
    } else {
      setAttachment({ files: [] }); // Clear files if no valid files
    }
  };


  // const handleFileRemove = (files) => {
  //   let totalSize = 0;
  //   // Calculate the total size of all files
  //   files?.forEach((item) => {
  //     if (item.size) {
  //       totalSize += item?.size;
  //     }
  //   });
  //   // Check if the total size is within the 25MB limit
  //   if (totalSize <= 25 * 1024 * 1024) {
  //     setAttachment({ files });
  //     setdocumentError(""); // Clear any existing errors if the size is within the limit
  //   } else {
  //     setdocumentError("File size exceeds the maximum limit of 25MB.");
  //   }

  //   if (files?.length === 0) {
  //     setFilesUploaded(false)
  //   }
  // };

  const handleFileRemove = (files) => {
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    let totalSize = 0;
    let fileTypeError = false;

    // Revalidate remaining files after one is removed
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          fileTypeError = true;
          return false;
        }

        totalSize += fileSize;
        return true;
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
    } else if (fileTypeError) {
      setdocumentError(
        "Invalid file type detected. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
      );
    } else {
      setdocumentError(""); // Clear any existing errors if valid
    }

    // Update state with the validated files
    setAttachment({ files: validatedFiles });

    // Handle edge case when all files are removed
    if (validatedFiles?.length === 0) {
      setFilesUploaded(false);
    }
  };


  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment?.files?.forEach((item) => {
      if (item.size) { totalSize += item?.size; }
    });
    return totalSize;
  }


  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };
  const handleAddSite = (e) => {
    // e.preventDefault()
    const nameValidation = Validations({ value: formData?.siteName, Validations: { required: true } })
    const add1Validation = Validations({ value: formData?.siteAdd1, Validations: { required: true } })
    const cityValidation = Validations({ value: formData?.siteCity, Validations: { required: true } })
    const pinCodeValidation = Validations({ value: formData?.sitePinCode, Validations: { required: true } })
    const stateValidation = Validations({ value: siteState, Validations: { required: true } })
    const countryValidation = Validations({ value: siteCountry, Validations: { required: true } })
    const siteIdsValidation = Validations({ value: formData?.siteContact_Individual_Ids?.length >= 1 ? true : "", Validations: { required: true } });
    if (!nameValidation.valid) { setFormError("nameError", nameValidation.error_msg) }
    if (!add1Validation.valid) { setFormError("add1Error", add1Validation.error_msg) }
    if (!cityValidation.valid) { setFormError("cityError", cityValidation.error_msg) }
    if (!pinCodeValidation.valid) { setFormError("pinCodeError", pinCodeValidation.error_msg) }
    if (!stateValidation.valid) { setFormError("stateError", stateValidation.error_msg) }
    if (!countryValidation.valid) { setFormError("countryError", countryValidation.error_msg) }
    if (!siteIdsValidation.valid) { setFormError("site_Contacts_Ids_Error", siteIdsValidation.error_msg); }
    const totalSize = calculateTotalAttachmentSize();
    if (totalSize > 25 * 1024 * 1024) {
      return;
    }

    if (nameValidation.valid === true &&
      add1Validation.valid === true &&
      cityValidation.valid === true &&
      documentError == "" &&
      pinCodeValidation.valid === true &&
      stateValidation.valid === true &&
      siteIdsValidation.valid === true &&
      countryValidation.valid === true) {
      props?.setIsSaving(true);
      const payload = {
        function_assignment_id: functionAssignmentId,
        country_id: siteCountry,
        state_id: siteState,
        site_name: formData?.siteName,
        site_address: formData?.siteAdd1,
        site_address_other: formData?.siteAdd2,
        site_suburb: formData?.siteCity,
        site_zipcode: formData?.sitePinCode,
        induction_valid_days: formData?.validDays,
        evacuation_diagram: attachment?.files,
        siteContactIndividualIds: JSON.stringify(formData?.siteContact_Individual_Ids)

      };
      props?.onCreateSite(payload)
        .then((response) => {
          if (response?.success === true) {
            props?.onDialogClose()
            props?.GetAllSitesOfClient()
            setTimeout(() => {
              props?.setIsSaving(false);
            }, 100);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
            props?.setIsSaving(false);
          } else {
            props?.setIsSaving(false);
          }
        }).catch((error) => {
          props?.setIsSaving(false);
        });
    }
  }
  return (
    <React.Fragment>
      <form className="px-6">
        {!props?.isSaving && <div>
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/5">Site name*</label>
            <div className="w-4/5 pl-2">
              <input type="text" placeholder="Enter site name here"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={formData?.siteName}
                onChange={(e) => handleInputChange(e, "siteName")} />
              {formErrors.nameError && (!formData?.siteName ? <p className='text-xs text-red-600 mt-1'>{formErrors.nameError}</p> : '')}
            </div>

          </div>
          <div className="flex">
            <label className="font-medium text-gray-700 w-1/5">Site Address*</label>
            <div className="w-4/5 pl-2">

              <div className="mb-5 ">
                <input type="text" placeholder="Street Address*"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData?.siteAdd1}
                  onChange={(e) => handleInputChange(e, "siteAdd1")} />
                {formErrors.add1Error && (!formData?.siteAdd1 ? <p className='text-xs text-red-600 mt-1'>{formErrors.add1Error}</p> : '')}
              </div>
              <div className="flex">
                <div className="flex-1 pr-1 mb-5 ">
                  <input type="text" placeholder="Apartment, suite, etc "
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    value={formData?.siteAdd2}
                    onChange={(e) => handleInputChange(e, "siteAdd2")} />
                </div>
                <div className="flex-1 pl-1 mb-5">
                  <input type="text" placeholder="Suburb*"
                    className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    value={formData?.siteCity}
                    onChange={(e) => handleInputChange(e, "siteCity")} />
                  {formErrors.cityError && (!formData?.siteCity ? <p className='text-xs text-red-600 mt-1'>{formErrors.cityError}</p> : '')}
                </div>
              </div>
              <select
                value={siteCountry}
                onChange={(e) => setSiteCountry(e.target.value)}
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Country</option>
                {allCountries && allCountries.map(country => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </select>
              {formErrors.countryError && (!siteCountry ? <p className='text-xs text-red-600 mt-1'>{formErrors.countryError}</p> : '')}

              <div className="flex mt-5">
                <div className="flex-1 pr-1 ">
                  <select
                    value={siteState}
                    onChange={(e) => setSiteState(e.target.value)}
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option >State*</option>
                    {allStates && allStates.map(state => (
                      <option key={state.value} value={state.value}>{state.label}</option>
                    ))}
                  </select>
                  {formErrors.stateError && (!siteState ? <p className='text-xs text-red-600 mt-1'>{formErrors.stateError}</p> : '')}
                </div>
                <div className="flex-1 pl-1">
                  <input type="number" placeholder="Postcode*"
                    className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    value={formData?.sitePinCode}
                    onChange={(e) => {
                      if (e?.target?.value?.length <= 10) {
                        handleInputChange(e, "sitePinCode");
                      }
                    }}
                    />
                  {formErrors.pinCodeError && (!formData?.sitePinCode ? <p className='text-xs text-red-600 mt-1'>{formErrors.pinCodeError}</p> : '')}
                </div>
              </div>

            </div>
          </div>
          <div className="flex mb-5 border-t mt-5 pt-5">
            <label className="font-medium text-gray-700 w-1/5">Valid days*</label>
            <div className="w-4/5 pl-2">
              <input type="number" placeholder="000"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={formData?.validDays}
                onChange={(e) => handleInputChange(e, "validDays")} />
            </div>
          </div>
          <div className="flex mb-5 border-t mt-5 pt-5">
            <label className="font-medium text-gray-700 w-1/5">Evacuation Diagram</label>
            <div className="w-4/5 pl-2 hideIcons">
              {filesUploaded ? (
                // Show the second Upload component when files are uploaded
                <Upload
                  className="text-blue-700 flex font-semibold text-xs items-center justify-end"
                  onChange={handleFileChangeAdditional}
                  onFileRemove={handleFileRemove}
                  maxFileSize={25 * 1024 * 1024}
                >
                  Upload additional
                  <span className="pl-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 4.167v11.666M4.167 10h11.666"
                        stroke="#5786CC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Upload>
              ) : (
                // Show the first Upload component when no files are uploaded
                <Upload
                  draggable
                  className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                  onChange={handleFileChange}
                  onFileRemove={handleFileRemove}
                  multiple={true}
                  maxFileSize={25 * 1024 * 1024}
                ><span className="text-blue-700 font-semibold">
                    Click to upload
                  </span>{" "}
                  <p className="text-gray-600">   
                  PDF, JPG, JPEG, PNG, GIF (MAX. 25MB)
                  </p></Upload>
              )}

              {documentError && <p className='text-xs text-red-600 mt-1'>{documentError}</p>}

            </div>
          </div>
        </div>}
        {props?.isSaving && (
          <div>
            <div className="blur">
              <div className="flex">
                <label className="font-medium text-gray-700 w-1/5">
                  Site Address*
                </label>
                <div className="w-4/5 pl-2">
                  <input type="text" placeholder="Street Address*" className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                  <input type="text" placeholder="Street Address*" className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                </div>
              </div>

            </div>
            <div className="flex justify-center mb-5 mt-5 pt-5">
              <TailSpin color="blue" radius={"8px"} /></div>
            <div className="blur">
              <div className="flex mb-5 border-t mt-5 pt-5">
                <label className="font-medium text-gray-700 w-1/5">
                  Evacuation Diagram
                </label>
                <div className="w-4/5 pl-2 hideIcons">
                  <Upload className="text-blue-700 flex font-semibold text-xs items-center justify-end" >
                    Upload additional
                    <span className="pl-2"> </span>
                  </Upload>
                </div>
              </div>
            </div>


          </div>
        )}
        {/* Site's Contact */}
        <div className="flex mb-5 border-t mt-5 pt-5">
          <label className="font-medium text-gray-700 w-1/5">
            Site’s contacts*
          </label>
          <div className="w-4/5 pl-2">
            <Select
              type="text"
              placeholder="Select"
              className="w-full  h-full select-options"
              isClearable={true}
              isSearchable={true}
              options={updatedOptions ? updatedOptions : selectedOption}
              value={selectedOption}
              onChange={handleSelectChange}
              isMulti={true}
              handleSearchChange={handleSearchChange}
            />
            {formErrors.site_Contacts_Ids_Error && (
              <p className="text-xs text-red-600 mt-1">
                {formErrors.site_Contacts_Ids_Error}
              </p>
            )}

          </div>
        </div>
      </form>
      <div className="flex dailog-footer ">
        {!props?.isSaving && <BtnLight className="mr-1.5" onClick={() => props?.onDialogClose()}>Cancel</BtnLight>}
        <BtnPrimary className="ml-1.5"
          onClick={() => handleAddSite()}
          disabled={props?.isSaving}
        >
          {props?.isSaving ? "Saving..." : "Add Site"}
          {/* Add Site */}
        </BtnPrimary>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  onCreateSite: userActions.CreateSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSite);