import React, { useState, useEffect, useCallback } from "react";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import { useDispatch } from "react-redux";
import Table from "../../../../../../common/element/Table";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Button from "../../../../../../common/element/Buttons";
import Tooltip from "../../../../../../common/element/Tooltip";
import Switcher from "../../../../../../common/element/Switcher";
import Dialog from "../../../../../../common/element/Dialog";
import { GetAdminPersonalNotifypermissions, UpdateAdminPersonalNotifypermissions } from "../../../../../../actions/Admin";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
const Notification = () => {
    const { Tr, Th, Td, THead, TBody } = Table;
    const dispatch = useDispatch()
    const handleUnauthorizedAccess = useUnauthorizedHandler()
    const [dialogAddOpen, setdialogAddOpen] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
    let user_id;
    let role_name;
    if (loginData != null) {
        role_name = loginData?.data?.role?.role_name;
        user_id = loginData?.data?.user_id;
        const roleMapping = {
            "super admin": "superadmin",
            "client service team": "client_service_team",
            "support team": "support_team"
        };
        role_name = roleMapping[role_name] || role_name;
    }




    const [notificationList, setNotificationList] = useState({
        client: [],
        provider: [],
        worker: []
    }); const onAddDialogClose = () => {
        setdialogAddOpen(false);
    };
    const toggleRow = (index) => {
        setExpandedRows((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };


    // Get notification list 
    const OnGetAdminPersonalNotifypermissions = useCallback(async () => {
        // setNotificationList(notificationLstff);
        try {
            const response = await dispatch(GetAdminPersonalNotifypermissions({ user_id: user_id ,user_type: role_name}));
            if (response?.success) {
                setNotificationList(response?.data);

            } else if (response?.status === 401) {
                handleUnauthorizedAccess();
            }
        } catch (error) {
            console.error('Error fetching global permissions:', error);
        }
    }, [dispatch]);

    useEffect(() => {
        OnGetAdminPersonalNotifypermissions();
    }, []);


    const onSwitcherToggle = (val, section, notifi_personal_id, type) => {
        let updatedNotificationObject = null;
        // Map through the notifications to find and update the correct notification
        const updatedNotifications = notificationList[section]?.map(notification => {
            if (notification.notifi_personal_id === notifi_personal_id) {
                // Store the updated notification in the variable
                updatedNotificationObject = {
                    ...notification,
                    [type]: !notification[type],
                };
                return updatedNotificationObject; // Return the updated notification
            }
            return notification;
        });

        // Update the notification list
        setNotificationList(prev => ({
            ...prev,
            [section]: updatedNotifications
        }));

        // Prepare the payload using the updated notification object
        console.log(updatedNotificationObject,"updatedNotificationObject")
        const payload = {
            notifi_personal_id: updatedNotificationObject?.notifi_personal_id,
            notifi_global_id:  updatedNotificationObject?.notifi_global_id,
            user_id: user_id,
            notify_bell: updatedNotificationObject?.notify_bell,
            notify_email: updatedNotificationObject?.notify_email,
            notify_task: updatedNotificationObject?.notify_task,
        };


        dispatch(UpdateAdminPersonalNotifypermissions(payload))
            .then((response) => {
                if (response?.success) {
                    // Handle success logic here
                } else {
                    console.error("Failed to update notification");
                }
            })
            .catch((error) => {
                console.error("Error while updating notification:", error);
            });
    };

    const handleAllOnOff = (section, item) => {
        const allEnabled = notificationList[section]?.every(notification => notification[item]);
        const updatedNotifications = notificationList[section]?.map(notification => ({
            ...notification,
            [item]: !allEnabled,
        }));

        setNotificationList(prev => ({
            ...prev,
            [section]: updatedNotifications
        }));
    };

    const submitNotifications = () => {
        setdialogAddOpen(true)
    }

    return (
        <React.Fragment>
            <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
                <div>
                    <h5 className="text-base text-gray-900 font-semibold mb-1">Notifications</h5>
                    <p className="text-gray-600">Customize your notification preferences to stay informed and in control of your alerts and updates.</p>
                </div>
                {/* <div className="flex justify-end items-center">
                    <BtnLight className="w-auto px-4">Cancel</BtnLight>
                    <SmallButton className="w-auto px-4 ml-3" onClick={() => submitNotifications()}>Save</SmallButton>
                </div> */}
            </div>

            <Table className="notification-table">
                <THead>
                    <Tr>
                        <Th>Notify me when...</Th>
                        <Th>
                            Task <br />
                            {/* <Button
                                className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700"
                                onClick={() => handleAllOnOff('client', 'notify_task')}
                            >
                                {notificationList?.client?.every(notification => notification?.notify_task)
                                    ? "Turn off all tasks"
                                    : "Turn on all tasks"}
                            </Button> */}
                        </Th>
                        <Th>
                            Bell <br />
                            {/* <Button
                                className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700"
                                onClick={() => handleAllOnOff('client', 'notify_bell')}
                            >
                                {notificationList?.client?.every(notification => notification?.notify_bell)
                                    ? "Turn off all bells"
                                    : "Turn on all bells"}
                            </Button> */}
                        </Th>
                        <Th>
                            Email <br />
                            {/* <Button
                                className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700"
                                onClick={() => handleAllOnOff('client', 'notify_email')}
                            >
                                {notificationList?.client?.every(notification => notification?.notify_email)
                                    ? "Turn off all emails"
                                    : "Turn on all emails"}
                            </Button> */}
                        </Th>
                    </Tr>
                </THead>
                <TBody>
                    {Object.keys(notificationList).map((section, sectionIndex) => (
                        <React.Fragment key={sectionIndex}>
                            <Tr
                                onClick={() => toggleRow(section)}
                                className={`cursor-pointer ${expandedRows[section] ? "bg-blue-50" : ""}`}
                            >
                                <Td className="flex gap-2 items-center">
                                    {expandedRows[section] ? (
                                        <svg
                                            className="cursor-pointer"
                                            width="24"
                                            height="25"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect y="0.5" width="24" height="24" rx="12" fill="#D2EBFF" />
                                            <path
                                                d="M6 9.5L12 15.5L18 9.5"
                                                stroke="#5786CC"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            className="cursor-pointer"
                                            width="24"
                                            height="25"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                y="24.5"
                                                width="24"
                                                height="24"
                                                rx="12"
                                                transform="rotate(-90 0 24.5)"
                                                fill="#F9FAFB"
                                            />
                                            <path
                                                d="M9 18.5L15 12.5L9 6.5"
                                                stroke="#5786CC"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    )}
                                    <span>{section.charAt(0).toUpperCase() + section.slice(1)}</span>
                                </Td>
                                <Td></Td>
                                <Td></Td>
                                <Td></Td>
                            </Tr>

                            {expandedRows[section] && (
                                <>
                                    {notificationList[section]?.map((notification, idx) => (

                                        <Tr
                                            key={idx} >
                                            <Td>
                                                <span className="block font-semibold text-gray-700">
                                                    {notification?.permission_name}
                                                </span>
                                                <span className="text-gray-600 text-xs">
                                                    {notification?.message_template || "Description not available"}
                                                </span>
                                            </Td>
                                            {/* <Td>
                                                <Switcher
                                                    className={`${notification[role_name] ? "" : "personal-notify"}`}
                                                    checked={notification?.notify_task && notification[role_name]}
                                                    onChange={(val) =>
                                                        notification[role_name] ? onSwitcherToggle(val, section, notification?.notifi_personal_id, 'notify_task') : null
                                                    }
                                                    disabled={!notification[role_name]} // Disable switcher if role type is false
                                                />
                                                <span className="text-xs text-gray-700 font-medium ml-2">Task</span>
                                            </Td> */}
                                            <Td>
                                                {notification[role_name] ? (
                                                    <Switcher
                                                        className=""
                                                        checked={notification?.notify_task && notification[role_name]}
                                                        onChange={(val) =>
                                                            onSwitcherToggle(val, section, notification?.notifi_personal_id, 'notify_task')
                                                        }
                                                        disabled={false}
                                                    />
                                                ) : (
                                                    <Tooltip title="Disabled">
                                                        <Switcher
                                                            className="personal-notify"
                                                            disabled={true}
                                                            checked={false}
                                                        />
                                                    </Tooltip>
                                                )}
                                                <span className="text-xs text-gray-700 font-medium ml-2">Task</span>
                                            </Td>

                                            {/* <Td>
                                                <Switcher
                                                    className={`${notification[role_name] ? "" : "personal-notify"}`}
                                                    checked={notification?.notify_bell && notification[role_name]}
                                                    onChange={(val) =>
                                                        notification[role_name] ? onSwitcherToggle(val, section, notification?.notifi_personal_id, 'notify_bell') : null
                                                    }
                                                    disabled={!notification[role_name]} // Disable switcher if role type is false
                                                />
                                                <span className="text-xs text-gray-700 font-medium ml-2">Bell</span>
                                            </Td> */}
                                            <Td>
                                                {notification[role_name] ? (
                                                    <Switcher
                                                        className=""
                                                        checked={notification?.notify_bell && notification[role_name]}
                                                        onChange={(val) =>
                                                            onSwitcherToggle(val, section, notification?.notifi_personal_id, 'notify_bell')
                                                        }
                                                        disabled={false}
                                                    />
                                                ) : (
                                                    <Tooltip title="Disabled">
                                                        <Switcher
                                                            className="personal-notify"
                                                            disabled={true}
                                                            checked={false}
                                                        />
                                                    </Tooltip>
                                                )}
                                                <span className="text-xs text-gray-700 font-medium ml-2">Bell</span>
                                            </Td>

                                            {/* <Td>
                                                <Switcher
                                                    className={`${notification[role_name] ? "" : "personal-notify"}`}
                                                    checked={notification?.notify_email && notification[role_name]}
                                                    onChange={(val) =>
                                                        notification[role_name] ? onSwitcherToggle(val, section, notification?.notifi_personal_id, 'notify_email') : null
                                                    }
                                                    disabled={!notification[role_name]} // Disable switcher if role type is false
                                                />
                                                <span className="text-xs text-gray-700 font-medium ml-2">Email</span>
                                            </Td> */}
                                            <Td>
                                                {notification[role_name] ? (
                                                    <Switcher
                                                        className=""
                                                        checked={notification?.notify_email && notification[role_name]}
                                                        onChange={(val) =>
                                                            onSwitcherToggle(val, section, notification?.notifi_personal_id, 'notify_email')
                                                        }
                                                        disabled={false}
                                                    />
                                                ) : (
                                                    <Tooltip title="Disabled">
                                                        <Switcher
                                                            className="personal-notify"
                                                            disabled={true}
                                                            checked={false}
                                                        />
                                                    </Tooltip>
                                                )}
                                                <span className="text-xs text-gray-700 font-medium ml-2">Email</span>
                                            </Td>

                                        </Tr>

                                    ))}
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </TBody>

            </Table>
            <Dialog
                isOpen={dialogAddOpen}
                onClose={onAddDialogClose}
                onRequestClose={onAddDialogClose}
                bodyOpenClassName="overflow-hidden"
                className="customAlert"
            >
                <div className="px-6 pt-6">
                    <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
                        <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                                stroke="#344054"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                    <p className="text-base text-gray-900 font-semibold text-center mt-4">
                        Your profile has been saved successfully
                    </p>
                </div>
                <div className="mt-8 flex justify-between pb-6 px-6">
                    <BtnPrimary onClick={() => onAddDialogClose()}>Close</BtnPrimary>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default Notification;
