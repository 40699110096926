import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// import {notify} from 'react-notify-toast';
import Upload from "../../../../../../../common/element/Upload";
import Tag from "../../../../../../../common/element/Tag";
import Button from "../../../../../../../common/element/Buttons";
import Dialog from "../../../../../../../common/element/Dialog";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import Alert from "../../../../../../../common/element/Alert";
import * as userActions from "../../../../../../../actions/index";
import Validations from "../../../../../../../common/404/Validations";
import trashIcon from "../../../../../../../assets/icons-svg/trash.svg";
import PdfFile from "../../../../../../../assets/icons-svg/upload-pdf.svg";
import { useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import Select from "../../../../../../../common/element/Select";
// change this Api GetClientContactsForSiteList
import { GetClientContactsForSiteList } from "../../../../../../../actions/client-portal/AllSiteApi";
import { decryptId } from "../../../../../../../common/encryptDecrypt/encryptdecrypt";
// import { saveAs } from 'file-saver';

const SiteSettings = (props) => {
  const dispatch = useDispatch();
  const { id, siteId } = useParams();
  const siteOrgId = decryptId(siteId);
  const orgId = decryptId(id);

  const { ClientSpecificProfileData } = useSelector((state) => state);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [allStates, setAllStates] = useState();
  const [documentError, setdocumentError] = useState("");
  const [allCountries, setAllCountries] = useState();
  const [siteState, setSiteState] = useState();
  const [siteCountry, setSiteCountry] = useState();
  const [dialogAddOpen, setDialogAddOpen] = useState(false);
  const [attachment, setAttachment] = useState({});
  const [files, setFiles] = useState();
  const [uploadedFiles, setUploadedFiles] = useState();
  const [fileRemovalSuccess, setFileRemovalSuccess] = useState(false);
  const [search, setSearch] = useState("");
  const [optionsValue, setOptionsValue] = useState([]);
  const [alreadySiteContIds, setAlreadySiteContIds] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [changeValue, setChangeValue] = useState(false);
  const [dataSaveSuccess, setDataSaveSuccess] = useState(false);

  const [formData, setFormData] = useState({
    site_id: "",
    country_id: "",
    state_id: "",
    site_name: "",
    site_address: "",
    site_address2: "",
    site_suburb: "",
    site_zipcode: "",
    induction_valid_days: "",
    siteContact_Individual_Ids: [],
    siteCont_Delete_Indiv_Ids: [],
    allready_siteContacts_Site_id: [],
  });
  const [formErrors, setFormErrors] = useState({
    nameError: "",
    add1Error: "",
    cityError: "",
    pinCodeError: "",
    stateError: "",
    countryError: "",
    site_Contacts_Ids_Error: "",
  });
  const [userInactive, setUserInactive] = useState(false);
  const [swapActive, setActive] = useState(true);
  let organisation_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

  if (storedData !== null) {
    // user_id = storedData?.userName?.user_id;
    // function_assignment_id = storedData.function_assignment_id;
    organisation_id = storedData.organisation_id;
  }

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Clients",
          url: "/admin/client/view-all-clients",
          home: "/admin/client/dashboard",
        },

        {
          label: ClientSpecificProfileData?.trading_name
            ? ClientSpecificProfileData?.trading_name
            : "",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}`,
        },
        {
          label: "Sites",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}`,
        },
        {
          label: props?.siteData?.site_name ? props?.siteData?.site_name : "",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}/specific-site/${encodeURIComponent(siteId)}`,
        },
        {
          label: "Site Settings",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}/specific-site/${encodeURIComponent(siteId)}`,
        },
      ])
    );
  }, [props?.siteData]);

  const onAddDialogClose = () => {
    setDialogAddOpen(false);
  };
  // Merge first name and end name and set in label
  function addLabelToObjects(dataArray) {
    return dataArray.map((data) => {
      const { first_name, last_name } = data;
      return {
        ...data,
        label: `${first_name} ${last_name}`,
      };
    });
  }
  // useEffect(() => {
  //    const siteData = props.siteData

  //    setFormData({
  //       ...formData,
  //       site_name: siteData?.site_name,
  //       site_address: siteData?.site_address,
  //       site_id: siteData?.site_id,
  //       site_address2: siteData?.site_address_other,
  //       site_suburb: siteData?.site_suburb,
  //       site_zipcode: siteData?.site_zipcode,
  //       induction_valid_days: siteData?.induction_valid_days,
  //     });
  //    setSiteState(siteData?.state_id)
  //    setSiteCountry(siteData?.country_id)
  //    setFiles(siteData?.evacuation_diagram)
  //    const fileData = [];
  //    siteData?.evacuation_diagram.forEach((data) => {
  //       const file = data
  //       // handleFileChange(file)
  //       const segments = file.split("/");

  //       // Get the last segment, which should be the file name
  //       const fileNameWithQueryParams = segments[segments.length - 1];

  //       // Decode the percent-encoded characters
  //       const decodedFileName = decodeURIComponent(fileNameWithQueryParams);

  //       fileData.push({
  //         file: file,
  //         fileName: decodedFileName
  //       })
  //   })
  //   setUploadedFiles(fileData)

  // }, [])
  const fetchClientContacts = useCallback(() => {
    if (searchOptions.length === 0 || optionsValue.length === 0) {
      const emailForApi = { organisation_id: orgId };
      dispatch(GetClientContactsForSiteList(emailForApi)).then((response) => {
        if (response.success) {
          const filterData = response.data.map((i) => ({
            label: `${i.first_name} ${i.last_name}`,
            value: i.individual_id,
            individual_org_id: i.individual_org_id,
          }));
          setSearchOptions(filterData);
          setOptionsValue(filterData);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      });
    }
  }, [dispatch, orgId, searchOptions.length, optionsValue.length]);

  useEffect(() => {
    fetchClientContacts();
    GetAllCountries();
  }, []);

  // useEffect(() => {
  //    if (searchOptions.length == 0 || optionsValue.length == 0) {
  //       fetchClientContacts();
  //    }
  // }, [fetchClientContacts, ,]);

  const handleSearchChange = (newInputValue) => {
    setSearch(newInputValue);
  };
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : [...searchOptions];

  const handleSelectChange = (selectedOption) => {
    let filterData = Array.isArray(selectedOption)
      ? selectedOption.map((item) => item.value)
      : [];
    setSelectedOption(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      siteContact_Individual_Ids: filterData,
    }));
    setChangeValue(true);
    setFormError("site_Contacts_Ids_Error", "");

    // update options for site Contacts
    const notSelectedData = optionsValue.filter((option) => {
      return !selectedOption.some(
        (selected) => selected.value === option.value
      );
    });
    setSearchOptions(notSelectedData);

    // Delete site_contact_id set
    if (formData?.allready_siteContacts_Site_id?.length) {
      const matchedData = [];
      const unmatchedData = [];

      for (let i of formData.allready_siteContacts_Site_id) {
        const matched = selectedOption.filter(
          (ele) => ele.value === i.individual_id
        );
        if (matched.length > 0) {
          matchedData.push(...matched);
        } else {
          unmatchedData.push(i);
        }
      }

      // Removing unmatched data from formData.allready_siteContacts_Site_id
      setFormData((prevState) => ({
        ...prevState,
        allready_siteContacts_Site_id:
          prevState.allready_siteContacts_Site_id.filter(
            (item) =>
              !unmatchedData.some(
                (unmatched) => unmatched.value === item.individual_id
              )
          ),
        siteCont_Delete_Indiv_Ids: unmatchedData
          ? unmatchedData?.map((item) => item?.site_contact_id)
          : [],
      }));
    }
  };

  // fetch site_contacts data and removed selected options
  useEffect(() => {
    const filteredSearchOptions = searchOptions?.filter((item) => {
      const conditionMet = formData?.siteContact_Individual_Ids?.some((i) => {
        let qwe = i?.value == item?.value;
        return qwe;
      });
      return !conditionMet && item;
    });
    setSearchOptions(filteredSearchOptions);
  }, [formData.siteContact_Individual_Ids]);

  // const GetClientSiteById = useCallback(async () => {
  //    dispatch(userActions.GetClientSiteById(siteId)).then((response) => {
  //       if (response.success === true) {
  //          const arrayData = response.data
  //          const storeIndiviualId = []
  //          if (arrayData?.siteContacts) {
  //             const extractedData = arrayData?.siteContacts.map(contact => contact.siteContIndi).filter(Boolean);
  //             storeIndiviualId.push(extractedData);
  //          }
  //          const updatedArray = addLabelToObjects(storeIndiviualId[0]);
  //          const storeDeleteSiteContactId = [];
  //          if (arrayData?.siteContacts) {
  //             for (let i of arrayData?.siteContacts) {
  //                storeDeleteSiteContactId.push({ individual_id: i.individual_id, site_contact_id: i.site_contact_id })
  //             }
  //          }

  //          let alreadyStoredData = [];
  //          for (let key in updatedArray) {
  //             alreadyStoredData.push({
  //                label: updatedArray[key].label,
  //                value: updatedArray[key].individual_id,
  //             });
  //          }
  //          setSelectedOption(alreadyStoredData);
  //          setFormData({
  //             ...formData,
  //             site_name: arrayData?.site_name,
  //             site_address: arrayData?.site_address,
  //             site_id: arrayData?.site_id,
  //             site_address2: arrayData?.site_address_other,
  //             site_suburb: arrayData?.site_suburb,
  //             site_zipcode: arrayData?.site_zipcode,
  //             induction_valid_days: arrayData?.induction_valid_days,
  //             siteContact_Individual_Ids: alreadyStoredData ? alreadyStoredData : [],
  //             allready_siteContacts_Site_id: storeDeleteSiteContactId ? storeDeleteSiteContactId : []
  //          });

  //          // setSelectedOption(updatedArray);
  //          setSelectedOption(alreadyStoredData);
  //          setAlreadySiteContIds(updatedArray)
  //          setSiteState(arrayData?.state_id)
  //          setSiteCountry(arrayData?.country_id)
  //          setFiles(arrayData?.evacuation_diagram)
  //          setActive(arrayData?.is_site_active)
  //          const fileData = [];
  //          arrayData?.evacuation_diagram.forEach((data) => {
  //             const file = data
  //             // handleFileChange(file)
  //             const segments = file.split("/");

  //             // Get the last segment, which should be the file name
  //             const fileNameWithQueryParams = segments[segments.length - 1];

  //             // Decode the percent-encoded characters
  //             const decodedFileName = decodeURIComponent(fileNameWithQueryParams);

  //             fileData.push({
  //                file: file,
  //                fileName: decodedFileName
  //             })
  //          })
  //          setUploadedFiles(fileData)

  //       } else if (response.status === 401) {
  //          handleUnauthorizedAccess()
  //       } else { }
  //    });

  // }, [dispatch]);

  // useEffect(() => {
  //    GetClientSiteById();
  // }, [siteId])

  useEffect(() => {
    if (props?.siteData) {
      const arrayData = props?.siteData;
      const storeIndiviualId = [];
      if (arrayData?.siteContacts) {
        const extractedData = arrayData?.siteContacts
          .map((contact) => contact.siteContIndi)
          .filter(Boolean);
        storeIndiviualId.push(extractedData);
      }
      const updatedArray = addLabelToObjects(storeIndiviualId[0]);
      const storeDeleteSiteContactId = [];
      if (arrayData?.siteContacts) {
        for (let i of arrayData?.siteContacts) {
          storeDeleteSiteContactId.push({
            individual_id: i.individual_id,
            site_contact_id: i.site_contact_id,
          });
        }
      }

      let alreadyStoredData = [];
      for (let key in updatedArray) {
        alreadyStoredData.push({
          label: updatedArray[key].label,
          value: updatedArray[key].individual_id,
        });
      }
      setSelectedOption(alreadyStoredData);
      setFormData({
        ...formData,
        site_name: arrayData?.site_name,
        site_address: arrayData?.site_address,
        site_id: arrayData?.site_id,
        site_address2: arrayData?.site_address_other,
        site_suburb: arrayData?.site_suburb,
        site_zipcode: arrayData?.site_zipcode,
        induction_valid_days: arrayData?.induction_valid_days,
        siteContact_Individual_Ids: alreadyStoredData ? alreadyStoredData : [],
        allready_siteContacts_Site_id: storeDeleteSiteContactId
          ? storeDeleteSiteContactId
          : [],
      });

      // setSelectedOption(updatedArray);
      setSelectedOption(alreadyStoredData);
      setAlreadySiteContIds(updatedArray);
      setSiteState(arrayData?.state_id);
      setSiteCountry(arrayData?.country_id);
      setFiles(arrayData?.evacuation_diagram);
      setActive(arrayData?.is_site_active);
      const fileData = [];
      arrayData?.evacuation_diagram.forEach((data) => {
        const file = data;
        // handleFileChange(file)
        const segments = file.split("/");

        // Get the last segment, which should be the file name
        const fileNameWithQueryParams = segments[segments.length - 1];

        // Decode the percent-encoded characters
        const decodedFileName = decodeURIComponent(fileNameWithQueryParams);

        fileData.push({
          file: file,
          fileName: decodedFileName,
        });
      });
      setUploadedFiles(fileData);
    }
  }, [props?.siteData, siteId]);
  // const downloadFile = async (file) => {
  //    try {
  //      const response = await fetch(file);
  //      const blob = await response.blob();
  //      const fileName = file.split('/').pop(); // Extract the file name
  //      saveAs(blob, fileName); // Download the file
  //    } catch (error) {
  //      console.error('Error downloading file:', error);
  //    }
  //  };

  //  const handleFileChange = (selectedFiles) => {
  //    const newFiles = [...(attachment?.files || []), ...selectedFiles];
  //    newFiles.forEach((file) => {
  //      downloadFile(file); // Download each newly added file
  //    });

  //    setAttachment({ files: newFiles });
  //  };

  const onCloseClick = () => {};

  const inputChangeHandler = (e, field) => {
    let value = e.target.value;
    setFormData({ ...formData, [field]: value });
  };

  const GetAllStatesbyCountryId = useCallback(async () => {
    if (siteCountry) {
      dispatch(userActions.GetAllStatesbyCountryId(siteCountry)).then(
        (response) => {
          if (response.success === true) {
            const arrayData = response.data;
            let state_data = [];
            for (let key in arrayData) {
              state_data.push({
                label: arrayData[key].state_name,
                value: arrayData[key].state_id,
              });
            }
            setAllStates(state_data);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          } else {
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [dispatch, siteCountry]);

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response.success === true) {
        const arrayData = response.data;
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
          });
        }
        setAllCountries(country_data);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAllStatesbyCountryId();
  }, [siteCountry]);

  const handleFileChange = (files) => {
    let totalSize = 0;
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf",
    ];

    // Filter and validate files
    let fileTypeError = false;
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          setdocumentError(
            "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
          );
          fileTypeError = true;
          return false;
        }

        // Check individual file size (2MB limit)
        if (fileSize > 25 * 1024 * 1024) {
          setdocumentError("File size exceeds the 25MB limit.");
          totalSize += fileSize;
          return false;
        } else {
          totalSize += fileSize;
          return true;
        }
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
      return;
    }

    // Update state if everything is valid
    if (validatedFiles?.length > 0) {
      setAttachment({ files: validatedFiles });

      if (fileTypeError) {
        setdocumentError(
          "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
        );
        return false;
      } else {
        if (totalSize > 25 * 1024 * 1024) {
          setdocumentError(
            "Total file size exceeds the maximum limit of 25MB."
          );
          return;
        } else {
          setdocumentError(""); // Clear any existing errors
        }
      }
    } else {
      setAttachment({ files: [] }); // Clear files if no valid files
    }
  };

  const handleFileRemove = (files) => {
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf",
    ];

    let totalSize = 0;
    let fileTypeError = false;

    // Revalidate remaining files after one is removed
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          fileTypeError = true;
          return false;
        }

        totalSize += fileSize;
        return true;
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
    } else if (fileTypeError) {
      setdocumentError(
        "Invalid file type detected. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
      );
    } else {
      setdocumentError(""); // Clear any existing errors if valid
    }

    // Update state with the validated files
    setAttachment({ files: validatedFiles });
  };
                                                                                  
  const clickHandlerInactive = () => {
    const payload = {
      is_site_active: !swapActive,
      site_id: siteOrgId,
    };
    props.onUpdateSiteActiveStatus(payload).then((response) => {
      if (response.success === true) {
        // here apply changes done
        setDialogAddOpen(true);
        props?.GetClientSiteById();
        userInactiveClose();
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
  };
  const userInactiveOpen = () => {
    setUserInactive(true);
  };
  const userInactiveClose = () => {
    setUserInactive(false);
  };

  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment?.files?.forEach((item) => {
      if (item.size) {
        totalSize += item?.size;
      }
    });
    return totalSize;
  };

  const handleRemoveFile = (e) => {
    let fileArray = files.filter((item) => item !== e.file);
    const payload = {
      site_id: formData.site_id,
      evacuation_diagram_url: e.file,
      evacuation_diagram: fileArray,
    };

    props.onDeleteSiteEvacuationDiagramById(payload).then((response) => {
      if (response.success === true) {
        setFileRemovalSuccess(true);
        props?.GetClientSiteById();
        //  props.onDialogClose()
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
  };

  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const handleAddSite = (e) => {
    let alreadySiteContactsIds = [];
    if (changeValue == true) {
      if (
        formData?.siteContact_Individual_Ids &&
        alreadySiteContIds.length > 0
      ) {
        alreadySiteContactsIds = formData.siteContact_Individual_Ids.filter(
          (ele) => {
            return alreadySiteContIds.every((i) => ele !== i.individual_id);
          }
        );
      } else {
        alreadySiteContactsIds = formData?.siteContact_Individual_Ids;
      }
    }

    const nameValidation = Validations({
      value: formData.site_name,
      Validations: { required: true },
    });
    const add1Validation = Validations({
      value: formData.site_address,
      Validations: { required: true },
    });
    const cityValidation = Validations({
      value: formData.site_suburb,
      Validations: { required: true },
    });
    const pinCodeValidation = Validations({
      value: formData.site_zipcode,
      Validations: { required: true },
    });
    const stateValidation = Validations({
      value: siteState,
      Validations: { required: true },
    });
    const countryValidation = Validations({
      value: siteCountry,
      Validations: { required: true },
    });
    const siteIdsValidation = Validations({
      value: formData?.siteContact_Individual_Ids?.length >= 1 ? true : "",
      Validations: { required: true },
    });

    if (!nameValidation.valid) {
      setFormError("nameError", nameValidation.error_msg);
    }
    if (!add1Validation.valid) {
      setFormError("add1Error", add1Validation.error_msg);
    }
    if (!cityValidation.valid) {
      setFormError("cityError", cityValidation.error_msg);
    }
    if (!pinCodeValidation.valid) {
      setFormError("pinCodeError", pinCodeValidation.error_msg);
    }
    if (!stateValidation.valid) {
      setFormError("stateError", stateValidation.error_msg);
    }
    if (!countryValidation.valid) {
      setFormError("countryError", countryValidation.error_msg);
    }
    if (!siteIdsValidation.valid) {
      setFormError("site_Contacts_Ids_Error", siteIdsValidation.error_msg);
    }

    const totalSize = calculateTotalAttachmentSize();
    if (totalSize > 25 * 1024 * 1024) {
      return;
    }

    if (
      nameValidation.valid === true &&
      add1Validation.valid === true &&
      cityValidation.valid === true &&
      documentError == "" &&
      pinCodeValidation.valid === true &&
      stateValidation.valid === true &&
      siteIdsValidation.valid === true &&
      countryValidation.valid === true
    ) {
      const payload = {
        site_id: formData.site_id,
        country_id: siteCountry,
        state_id: siteState,
        site_name: formData.site_name,
        site_address: formData.site_address,
        site_address_other: formData.site_address2,
        site_suburb: formData.site_suburb,
        site_zipcode: formData.site_zipcode,
        induction_valid_days: formData.induction_valid_days,
        evacuation_diagram: attachment,
        evacuation_diagramUrl: files,
        siteContactIndividualIds: JSON.stringify(
          alreadySiteContactsIds ? alreadySiteContactsIds : []
        ),
        deleteSiteContactIds: JSON.stringify(
          formData.siteCont_Delete_Indiv_Ids
            ? formData.siteCont_Delete_Indiv_Ids
            : []
        ),
      };
      props?.onUpdateClientSite(payload).then((response) => {
        if (response.success === true) {
          //  props.onDialogClose()
          setDialogAddOpen(true);
          // setDataSaveSuccess(true)
          setChangeValue(false);
          props?.GetClientSiteById(); // this is for overview page update content instant
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        } else {
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-6 border-b border-gray-200 pb-6">
        <div className="flex flex-col">
          <h5 className="text-base text-gray-900 font-semibold mb-1">
            Site Settings
          </h5>
          <p className="text-gray-600">
            Update the client’s logo and details here.
          </p>
        </div>
        <div className="flex">
          <BtnLight className="w-auto flex items-center px-4 mr-1.5">
            Cancel
          </BtnLight>
          <BtnPrimary
            className="w-auto flex items-center px-4 ml-1.5"
            onClick={(e) => handleAddSite(e)}
          >
            Save
          </BtnPrimary>
        </div>
      </div>
      <div className="flex items-center border-b border-gray-200 pb-5 mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Status</label>
        <div className="flex items-center">
          {/* <span className="bg-success-50 text-success-700 flex items-center px-2 py-0.5 rounded-full font-medium"> */}
          {/* <span className="w-2 h-2 bg-success-500 block rounded-full mr-0.5"></span>Active</span>
         <span className="text-gray-600 text-xs font-semibold ml-2">Make inactive</span> */}
          {swapActive === true && (
            <div>
              <Tag
                prefix
                prefixClass="bg-success-500"
                className="text-success-700 bg-success-50 gap-1"
              >
                Active
              </Tag>
              <Button
                onClick={userInactiveOpen}
                className="text-gray-600 text-xs font-semibold ml-4 hover:text-gray-900 focus:text-gray-600"
              >
                Make inactive
              </Button>
            </div>
          )}
          {swapActive === false && (
            <div>
              <Tag className="text-error-700 bg-error-50 gap-1">Inactive</Tag>
              <Button
                onClick={clickHandlerInactive}
                className="text-gray-600 text-xs font-semibold ml-4 hover:text-gray-900 focus:text-gray-600"
              >
                Make active
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Site name*</label>
        <div className="flex items-center w-6/12">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="Site name here"
            value={formData.site_name}
            onChange={(e) => inputChangeHandler(e, "site_name")}
          />
          {formErrors.nameError &&
            (!formData.site_name ? (
              <p className="text-xs text-red-600 mb-4 mt-0">
                {formErrors.nameError}
              </p>
            ) : (
              ""
            ))}
        </div>
      </div>
      <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Valid Days</label>
        <div className="flex items-center w-6/12">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="000"
            value={formData.induction_valid_days}
            onChange={(e) => inputChangeHandler(e, "induction_valid_days")}
          />
        </div>
      </div>
      <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Site Address*
        </label>
        <div className="flex items-center w-6/12 flex-wrap">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
            id="email"
            type="text"
            placeholder="Street Address*"
            value={formData.site_address}
            onChange={(e) => inputChangeHandler(e, "site_address")}
          />
          {formErrors.add1Error &&
            (!formData.site_address ? (
              <p className="text-xs text-red-600 mb-4 mt-0">
                {formErrors.add1Error}
              </p>
            ) : (
              ""
            ))}

          <div className="flex w-full">
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-1 mb-5"
              id="email"
              type="text"
              placeholder="Suburb*"
              value={formData.site_address2}
              onChange={(e) => inputChangeHandler(e, "site_address2")}
            />

            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-1 mb-5"
              id="email"
              type="text"
              placeholder="Suburb*"
              value={formData.site_suburb}
              onChange={(e) => inputChangeHandler(e, "site_suburb")}
            />
            {formErrors.add1Error &&
              (!formData.site_address ? (
                <p className="text-xs text-red-600 mb-4 mt-0">
                  {formErrors.add1Error}
                </p>
              ) : (
                ""
              ))}
          </div>

          <div className="flex w-full">
            {/* <select
                     value={siteState}
                     onChange={(e) => setSiteState(e.target.value)}
                     className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  >
                     <option >State*</option>
                     {allStates && allStates.map(state => (
                        <option key={state.value} value={state.value}>{state.label}</option>
                     ))}
                  </select>
                  {formErrors.stateError && (!siteState ? <p className='text-xs text-red-600 mb-4 mt-0'>{formErrors.stateError}</p> : '')} */}
            <select
              value={siteCountry}
              onChange={(e) => setSiteCountry(e.target.value)}
              className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">Country</option>
              {allCountries &&
                allCountries.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
            </select>
            {formErrors.countryError &&
              (!siteCountry ? (
                <p className="text-xs text-red-600 mb-4 mt-0">
                  {formErrors.countryError}
                </p>
              ) : (
                ""
              ))}

            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-1 mb-5"
              id="email"
              type="number"
              placeholder="Postcode*"
              value={formData.site_zipcode}
              onChange={(e) => {
                if (e?.target?.value?.length <= 10) {
                  inputChangeHandler(e, "site_zipcode");
                }
              }}
            />
          </div>
          {/* <select
                  value={siteCountry}
                  onChange={(e) => setSiteCountry(e.target.value)}
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
               >
                  <option value="">Country</option>
                  {allCountries && allCountries.map(country => (
                     <option key={country.value} value={country.value}>
                        {country.label}
                     </option>
                  ))}
               </select>
               {formErrors.countryError && (!siteCountry ? <p className='text-xs text-red-600 mb-4 mt-0'>{formErrors.countryError}</p> : '')} */}

          <select
            value={siteState}
            onChange={(e) => setSiteState(e.target.value)}
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option>State*</option>
            {allStates &&
              allStates.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.label}
                </option>
              ))}
          </select>
          {formErrors.stateError &&
            (!siteState ? (
              <p className="text-xs text-red-600 mb-4 mt-0">
                {formErrors.stateError}
              </p>
            ) : (
              ""
            ))}
        </div>
      </div>
      <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Evacuation Diagram
        </label>
        <div className="flex  w-6/12 flex-col">
          <Upload
            draggable
            className="w-full"
            onChange={handleFileChange}
            onFileRemove={handleFileRemove}
            multiple={true}
            accept={".jpg, .jpeg, .png, .pdf,"}
            maxFileSize={25 * 1024 * 1024}
          >
            <div className="text-center">
              <div className="flex items-center justify-center flex-col">
                <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                      stroke="#475467"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
                <span className="pt-4">
                  <span className="text-blue-700 font-semibold mr-1">
                    Click to upload
                  </span>{" "}
                  <span className="text-xs text-gray-600">
                    PDF, JPG, JPEG, PNG, GIF (max. 25MB)
                  </span>
                </span>
              </div>
            </div>
          </Upload>
          {uploadedFiles &&
            uploadedFiles.map((item, index) =>
              item ? (
                <div
                  key={index}
                  className="upload-file flex items-center justify-between mt-2"
                >
                  <div className="upload-file-left flex items-center">
                    <div className="upload-file-thumbnail">
                      <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                        <img src={PdfFile} alt="" />
                      </span>
                    </div>
                    <a href={item} target="_blank" rel="noopener noreferrer">
                      <span className="upload-file-name font-medium text-xs text-gray-700">
                        {item.fileName}
                      </span>
                    </a>
                  </div>
                  <span
                    className="upload-file-remove w-5"
                    onClick={() => handleRemoveFile(item)}
                  >
                    <span className="">
                      <img src={trashIcon} alt="" />
                    </span>
                  </span>
                </div>
              ) : (
                ""
              )
            )}
          {documentError && (
            <p className="text-xs text-red-600 mt-1">{documentError}</p>
          )}
        </div>
      </div>
      {/* Site's Contact */}
      <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
        <label className="font-medium text-gray-700 w-1/6">
          Site’s contacts*
        </label>
        <div className="flex items-center w-6/12 flex-wrap">
          <Select
            type="text"
            placeholder="Select"
            className="w-full h-full select-options"
            isClearable={true}
            isSearchable={true}
            options={updatedOptions ? updatedOptions : searchOptions}
            value={selectedOption}
            onChange={handleSelectChange}
            isMulti={true}
            handleSearchChange={handleSearchChange}
          />
          {formErrors.site_Contacts_Ids_Error && (
            <p className="text-xs text-red-600 mt-1">
              {formErrors.site_Contacts_Ids_Error}
            </p>
          )}
        </div>
      </div>

      {fileRemovalSuccess && (
        <Alert
          type="success"
          showIcon
          closable
          onClose={() => setFileRemovalSuccess(false)}
        >
          The selected document has been successfully deleted.
        </Alert>
      )}
      {dataSaveSuccess && (
        <Alert
          type="success"
          showIcon
          closable
          onClose={() => setDataSaveSuccess(false)}
        >
          Changes have been saved successfully.
        </Alert>
      )}

      <Dialog
        isOpen={userInactive}
        onClose={userInactiveClose}
        onRequestClose={userInactiveClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to <br />
            make this Site inactive?
          </h4>
          <p className=" text-gray-600 text-center mt-1">
            This site will no longer have access to the app.
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => userInactiveClose()} className="mr-1.5">
            Cancel
          </BtnLight>
          <BtnPrimary onClick={clickHandlerInactive} className="ml-1.5">
            Confirm
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Updated successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={() => onAddDialogClose()}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  onUpdateClientSite: userActions.UpdateClientSite,
  onDeleteSiteEvacuationDiagramById:
    userActions.DeleteSiteEvacuationDiagramById,
  onUpdateSiteActiveStatus: userActions.UpdateSiteActiveStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSettings);
