import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Validations from "../../../../../common/404/Validations";
import * as userActions from "../../../../../actions/index";
import Select from "../../../../../common/element/Select/Select";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { notify } from "react-notify-toast";
import Switcher from "../../../../../common/element/Switcher";
import { debounce } from "../../../../../common/snippet/Debouncing";
import { AddClientContact, GetAllIndividualListForContact, GetAllClientContacts } from "../../../../../actions/client-portal/AllContactsApi"
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";

const AddContactForm = (props) => {
  const dispatch = useDispatch()
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [siteContact, setSiteContact] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [individualId, setIndividualId] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [search, setSearch] = useState();
  const [allContactsData, setAllContactsData] = useState([])
  const [jobFromOption, setJobFromOption] = useState(false);
  const [phoneFromOption, setPhoneFromOption] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    job_title: "",
    phone: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    first_name: "",
    last_name: "",
    job_title: "",
    phone: "",
  });

  let organisation_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    organisation_id = storedData.organisation_id;
  }
  const onSwitcherToggle = (e) => {
    setSiteContact(!siteContact);
  };

  const AllContactType = useSelector((state) => state?.AllContactTypes);


  // Handle the search input change
  // const handleSearchChange = (newInputValue) => {
  //   setSearch(newInputValue);
  //   if (search?.length >= 3) {
  //     setFormData({ ...formData, email: newInputValue });
  //     clearFormErrors();
  //     // GetAllIndividualListForContact();
  //   }
  // };
  //  props?.contactsData    for match and show already exist user 
  useEffect(() => {
    const fetchClientContacts = async () => {
      try {
        const response = await dispatch(
          userActions.GetAllClientContacts(
            organisation_id,
            "",
            1,
            "desc",
            "first_name",
            ""
          )
        );

        if (response?.success === true) {
          const arrayData = response?.data?.rows;
          setAllContactsData(arrayData)

        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        } else {
          // Handle other response statuses
        }
      } catch (error) {
        console.error("Error fetching client contacts:", error);
      }
    };

    fetchClientContacts();
  }, [organisation_id]);


  // Handle the search input change
  const handleSearchChange = debounce((newInputValue) => {
    setSearch(newInputValue);
    if (newInputValue?.length >= 3) {
      setFormData({ ...formData, email: newInputValue });
      clearFormErrors();
      // GetAllIndividualListForContact();
    }
  }, 500); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

  // Function to clear form errors
  const clearFormErrors = () => {
    setFormErrors({
      email: "",
      first_name: "",
      last_name: "",
      job_title: "",
      phone: "",
    });
  };

  // Handle the select option change
  const handleSelectChange = (selectedOption) => {
    // Check if the selected email already exists in props?.contactsData
    const emailExists = allContactsData?.some(contact => contact?.email === selectedOption?.label);
    if (emailExists) {
      setFormErrors((preError) => ({
        ...preError,
        email: "This Contact has already exists !"
      }))

    }
    else {
      setFormErrors((preError) => ({
        ...preError,
        email: ""
      }))
    }
    setSelectedOption(selectedOption);
    const newFormData = {
      email: selectedOption?.label,
      first_name: selectedOption?.first_name || "",
      last_name: selectedOption?.last_name || "",
      job_title: selectedOption?.job_title || "",
      phone: selectedOption?.phone || "",
    };
    setFormData(newFormData);
    setIndividualId(selectedOption?.individualId || "");
    setJobFromOption(!!selectedOption?.jobTitle);
    setPhoneFromOption(!!selectedOption?.phone);
  };
  // Define a custom option for the Select component
  const customOption = { value: search, label: search };
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : [...searchOptions, customOption];

  const updateListFromApi = (arrayData) => {
    let contactData = [];
    for (let key in arrayData) {
      contactData.push({
        label: arrayData[key].email,
        value: arrayData[key].email,
        individualId: arrayData[key]?.individual_id,
        first_name: arrayData[key].first_name,
        last_name: arrayData[key].last_name,
        job_title: arrayData[key].job_title,
        phone: arrayData[key].phone,
      });
    }
    setSearchOptions(contactData);
  }

  useEffect(() => {
    let emailForApi = { search: formData.email }
    if (formData.email?.length >= 3) {
      dispatch(GetAllIndividualListForContact(emailForApi)).then(
        (response) => {
          if (response?.success) {
            const arrayData = response?.data;
            updateListFromApi(arrayData)
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          }
        }
      );
    }
  }, [formData.email]);

  const GetIndividualByEmail = useCallback(async () => {
    if (formData.email) {
      dispatch(userActions.GetIndividualByEmail(formData.email))
        .then(
          (response) => {
            if (response?.success === true) {
              const arrayData = response?.data;
              if (arrayData && arrayData?.is_conserve_team === false) {
                let name =
                  arrayData?.arrayData?.first_name +
                  " " +
                  arrayData?.arrayData?.last_name;
                // setIndividualId(arrayData?.arrayData?.individual_id);
                if (!individualId) { setIndividualId(arrayData?.arrayData?.individual_id); }
              } else if (arrayData && arrayData?.is_conserve_team === true) {
                setFormError("email", "This email cannot be selected");
              }
            } else if (response?.status === 401) {
              handleUnauthorizedAccess()
              props?.setButtonDisabled(false);
            } else {
            }
          }
        ).catch((error) => {
          // handle the error
          console.error("API call failed: ", error);
          props?.setButtonDisabled(false);
        });
    }
    // eslint-disable-next-line
  }, [dispatch, search]);
  // Handle input changes
  const inputChangeHandler = (e, field) => {
    let value = e.target.value;
    if (field === "phone") {
      // Remove non-numeric characters
      value = value.replace(/\D/g, "");
      // Convert the value to a number
      value = value === "" ? "" : parseInt(value, 10);
    }
    setFormData({ ...formData, [field]: value });
  };

  // Set a form error
  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const submitContact = (e) => {
    e.preventDefault();
    props?.setButtonDisabled(true)
    GetIndividualByEmail();
    let contactType = AllContactType.filter(
      (item) => item?.contact_type === "client contact"
    );
    const contactTypeId = contactType[0]?.contact_type_id;
    if (formErrors.email === "") {
      clearFormErrors();
      //Validate
      const emailValidation = Validations({
        value: formData.email,
        Validations: { required: true, email: true },
      });
      const firstNameValidation = Validations({
        value: formData.first_name,
        Validations: { required: true },
      });
      const lastNameValidation = Validations({
        value: formData.last_name,
        Validations: { required: true },
      });
      const jobValidation = Validations({
        value: formData.job_title,
        Validations: { required: true },
      });
      const phoneValidation = Validations({
        value: formData.phone,
        Validations: { required: true },
      });

      if (!emailValidation.valid) {
        setFormError("email", emailValidation.error_msg);
      }
      if (!firstNameValidation.valid) {
        setFormError("first_name", firstNameValidation.error_msg);
      }
      if (!lastNameValidation.valid) {
        setFormError("last_name", lastNameValidation.error_msg);
      }
      if (!jobValidation.valid) {
        setFormError("job_title", jobValidation.error_msg);
      }
      if (!phoneValidation.valid) {
        setFormError("phone", phoneValidation.error_msg);
      }
      if (
        emailValidation.valid === true &&
        firstNameValidation.valid === true &&
        lastNameValidation.valid === true &&
        jobValidation.valid === true &&
        phoneValidation.valid === true
      ) {
        const payload = {
          organisation_id: organisation_id,
          ...formData,
          contact_type_id: contactTypeId,
          individual_id: individualId || "",
          is_client_site_contact: siteContact,
        };

        dispatch(AddClientContact(payload))
          .then((response) => {
            if (response?.success) {
              props?.setButtonDisabled(false)
              props?.onDialogClose();
              clearFormErrors();
              props?.fetchClientContacts()
            } else if (response?.status === 409) {
              setFormError("email", response?.message);
            } else if (response?.status === 401) {
              handleUnauthorizedAccess()
            } else {
            }
          })
          .catch((error) => {
            console.log("error");
            props?.setButtonDisabled(false);
          }).finally(() => {
            props?.setButtonDisabled(false);
          });
      }
      else{
        props?.setButtonDisabled(false)
      }
    }
    else{
      props?.setButtonDisabled(false)
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={submitContact}>
        <div className="mt-6 add-client-form px-6">
          <div className="flex justify-between flex-col">
            <div className="mb-3">
              <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                Email address*
              </label>
              <Select
                type="text"
                placeholder="Enter Contact's email address"
                className="w-full h-10 select-options"
                isClearable={true}
                isSearchable={true}
                options={updatedOptions ? updatedOptions : selectedOption}
                value={selectedOption || customOption}
                onChange={handleSelectChange}
                handleSearchChange={handleSearchChange}
              />
              {formErrors.email ? (
                <p className="text-xs text-red-600 mt-1">
                  {formErrors.email}
                </p>
              ) : ''}
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  First name*
                </label>
                <input
                  type="text"
                  placeholder="Enter Contact's first name"
                  class="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.first_name}
                  readOnly={individualId ? true : false}
                  onChange={(e) => inputChangeHandler(e, "first_name")}
                />
                {formErrors.first_name &&
                  (!formData.first_name ? (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.first_name}
                    </p>
                  ) : (
                    ""
                  ))}
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Last name*
                </label>
                <input
                  type="text"
                  placeholder="Enter Contact's last name"
                  class="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.last_name}
                  readOnly={individualId ? true : false}
                  onChange={(e) => inputChangeHandler(e, "last_name")}
                />
                {formErrors.last_name &&
                  (!formData.last_name ? (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.last_name}
                    </p>
                  ) : (
                    ""
                  ))}
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Job title
                </label>
                <input
                  type="text"
                  placeholder="Enter Contact's job role"
                  class="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.job_title}
                  readOnly={jobFromOption ? true : false}
                  onChange={(e) => inputChangeHandler(e, "job_title")}
                />
                {formErrors.job_title &&
                  (!formData.job_title ? (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.job_title}
                    </p>
                  ) : (
                    ""
                  ))}
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Phone number
                </label>
                <input
                  type="number"
                  placeholder="Enter Contact's phone number"
                  class="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.phone}
                  readOnly={phoneFromOption ? true : false}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    if (value?.length <= 15) {
                      inputChangeHandler(e, "phone");
                    }
                  }}
                  min="1000000"  // Min value for 7 digits
                  max="999999999999999"  // Max value for 15 digits

                />
                {formErrors.phone &&
                  (!formData.phone ? (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.phone}
                    </p>
                  ) : (
                    ""
                  ))}
              </div>
            </div>
            <div className="mb-3">
              <Accordion open={1}>
                <AccordionBody >
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e)}
                    checked={siteContact}
                  />
                  <span className="text-gray-600 ml-4 font-semibold">
                    Set as a site contact?
                  </span>
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </div>

        <div className="flex dailog-footer ">
          <BtnLight disabled={props?.buttonDisabled} onClick={props?.onDialogClose}>Cancel</BtnLight>
          <BtnPrimary disabled={props?.buttonDisabled} type="submit" className="ml-1.5">
            Add contact
          </BtnPrimary>
        </div>
      </form>
    </React.Fragment>
  );
};


export default AddContactForm;
