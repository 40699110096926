import React, { useState, useEffect, useCallback } from "react";
import BtnPrimaryLight from "../../../../../common/element/Buttons/primaryLightBtn";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import { Link } from "react-router-dom";
import { debounce } from "../../../../../common/snippet/Debouncing";
import Segment from "../../../../../common/element/Segment";
import Heading from "../../../../../common/element/Heading";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import InvoiceList from "./InvoiceList";
import ReactPaginate from "react-paginate";
import { Pagination } from "../../../../../common/pagination";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import searchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import { connect, useDispatch } from "react-redux";
import RiseInvoice from "./RiseInvoice";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
const Invoices = () => {
    const dispatch = useDispatch();
    const handleUnauthorizedAccess = useUnauthorizedHandler()
    const [countData, setCountData] = useState(0);
    const [filterpage, setfilterpage] = useState(1);
    const [searchTerm, setsearchTerm] = useState("");
    const [filterlimit, setfilterlimit] = useState(10);
    const [filterorder, setFilterOrder] = useState("desc");
    const [filtersort, setFiltersort] = useState("invoice_no");
    const [selectedRole, setSelectedRole] = useState(null);
    const [viewType, setViewType] = useState("all")
    const [totalPages, setTotalPages] = useState(1);
    const [singleSegmentValue, setSingleSegmentValue] = useState(["view all"]);
    const [allInvoicesData, setAllInvoicesData] = useState([])

    const getData = [
        { client_uuid: 1, invoiceNumber: "000000001", recipient: "Recipient A", payee: "Client", amount: "$100.00", billingData: "01/01/2022", paymentData: "05/01/2022", status: "Paid", invoiceUrl: "https://conservedev.s3.ap-south-1.amazonaws.com/1728018166364-643095962-sample.pdf", email: "recipientA@example.com" },
        { client_uuid: 2, invoiceNumber: "000000002", recipient: "Recipient B", payee: "Provider", amount: "$200.00", billingData: "02/01/2022", paymentData: "-", status: "Overdue", invoiceUrl: "https://conservedev.s3.ap-south-1.amazonaws.com/1728017017266-554973309-BillFormat.pdf", email: "recipientB@example.com" },
        { client_uuid: 3, invoiceNumber: "000000003", recipient: "Recipient C", payee: "Client", amount: "$300.00", billingData: "03/01/2022", paymentData: "-", status: "Upcoming", invoiceUrl: "https://conservedev.s3.ap-south-1.amazonaws.com/1728017794003-303364362-agreement.pdf", email: "recipientC@example.com" },
        { client_uuid: 4, invoiceNumber: "000000004", recipient: "Recipient D", payee: "Client", amount: "$150.00", billingData: "04/01/2022", paymentData: "-", status: "Draft", invoiceUrl: "https://example.com/invoice-draft.pdf", email: "recipientD@example.com" } // New Draft Invoice
    ];



    useEffect(() => {
        dispatch(setBreadcrumbs([{ label: "Invoices", url: "/admin/invoices", home: "/admin/dashboard" },]));
    }, []);

    const onGetAllInvoiceData = useCallback(async () => {
        setAllInvoicesData(getData)
        setCountData(getData.length)
        setTotalPages(Math.ceil(getData.length / filterlimit));

        // try {
        //     const payload = {
        //         search: searchTerm,
        //         page: filterpage,
        //         limit: filterlimit,
        //         sort: filtersort,
        //         order: filterorder,
        //         viewType: viewType
        //     }
        //     const response = await dispatch(GetAllAdminInvoices(payload));
        //     if (response?.success) {
        //         const arrayData = response?.data.rows;
        //         setAllInvoicesData(arrayData);
        //         setCountData(response?.data?.count)
        //         setTotalPages(Math.ceil(response.data.count / filterlimit));

        //     } else if (response?.status === 401) {
        //         handleUnauthorizedAccess();
        //     }
        // } catch (error) {
        //     console.error('Error fetching global permissions:', error);
        // }
    }, [dispatch, viewType, searchTerm, filterpage, filterorder, filtersort, filterlimit])

    useEffect(() => {
        onGetAllInvoiceData();
    }, [viewType, searchTerm, filterpage, filterorder, filtersort, filterlimit]);

    const handleSeachChange = (e) => {
        setsearchTerm(e.target.value.trim());
        setfilterpage(1);
    };
    const inputchangeSearch = useCallback(debounce(handleSeachChange), []);
    const handleSort = (column) => {
        if (column === filtersort) {
            setFilterOrder(filterorder === "asc" ? "desc" : "asc");
        } else {
            setFiltersort(column);
            setFilterOrder(filterorder === "asc" ? "desc" : "asc");
        }
    };
    const handlePageChange = ({ selected }) => {
        setfilterpage(selected + 1);

    };
    const onSingleSelectionSegmentChange = useCallback(
        (val) => {
            setSingleSegmentValue(val);
            if (val[0] === "view all") {
                setfilterpage(1);
                setViewType("all")
            } else {
                if (val[0] == "paid") {
                    setViewType("paid")
                }
                else if (val[0] == "upcoming") {
                    setViewType("upcoming")
                }
                else if (val[0] == "overdue") {
                    setViewType("overdue")
                }
                else if (val[0] == "draft") {
                    setViewType("draft")
                }
            }
        },
        [setSingleSegmentValue]
    );

    return (
        <React.Fragment>
            <div className="flex items-center justify-between mb-7 gap-3 flex-wrap">
                <Heading>Invoices</Heading>
                <div className="flex flex-wrap gap-3">
                    <Link to="raise-invoice">
                        <BtnPrimary className="flex w-40 items-center justify-center gap-2 text-12"
                        >
                            Raise an invoice
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.712 4.167v11.666M4.878 10h11.667" stroke="#fff" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </BtnPrimary>
                    </Link>
                </div>
            </div>
            <div className="flex justify-between mb-5  flex-wrap gap-y-2.5">
                <Segment
                    className="docType-btn"
                    value={singleSegmentValue}
                    onChange={(val) => onSingleSelectionSegmentChange(val)}
                >
                    <Segment.Item value="view all">View All</Segment.Item>
                    <Segment.Item value="paid">Paid</Segment.Item>
                    <Segment.Item value="upcoming">Upcoming</Segment.Item>
                    <Segment.Item value="overdue">Overdue</Segment.Item>
                    <Segment.Item value="draft">Draft</Segment.Item>
                </Segment>
                <div className="w-80 h-10">
                    <div className="input-wrapper">
                        <div className="input-suffix-start left-3.5">
                            <img src={searchIcon} alt="" className="h-5" />
                        </div>
                        <input
                            type="text"
                            name="hs-table-search"
                            className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Search"
                            onChange={(e) => inputchangeSearch(e)}
                        />
                    </div>
                </div>
            </div>
            {/* <Filter /> */}
            <InvoiceList
                handleSort={handleSort}
                filtersort={filtersort}
                setViewType={setViewType}
                viewType={viewType}
                filterorder={filterorder}
                allInvoicesData={allInvoicesData}
            />
            <div className="flex justify-between mt-3">
                <Pagination totalCount={countData} pageNumber={filterpage} displayPage={allInvoicesData?.length} UpdatePageLimit={setfilterlimit} UpdatePage={setfilterpage} />
                {countData > 0 && <ReactPaginate
                    className="flex justify-content-end p-2 align-items-center pagination-wrap"
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    previousLinkClassName={"prev-link"}
                    nextLinkClassName={"page-link"}
                    breakLinkClassName={"prev-link"}
                    previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
                    nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
                    pageLinkClassName={"px-4 rounded-md"}
                    activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
                    disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
                    renderOnZeroPageCount={null}
                    forcePage={filterpage - 1}
                />}
            </div>

        </React.Fragment>
    )
}
export default Invoices
