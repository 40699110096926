import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import Segment from "../../../../../common/element/Segment";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../common/element/Buttons/btnPrimary";
import * as userActions from "../../../../../actions/index";
import imagePlus from "../../../../../assets/icons-svg/image-plus.svg";
import Validations from "../../../../../common/404/Validations";
import Select from "../../../../../common/element/Select/Select";
import { GetClientUserPermByIdForClient } from "../../../../../actions/client-portal/AllUsersApi";
import { GetAlreadyExistingOrgCheck } from "../../../../../actions/client-portal/AllSettingApis";
import Dialog from "../../../../../common/element/Dialog";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";

import { GetProviderDetailsById } from "../../../../../actions/provider-portal/GetSpecificContractorDetailsById";

import { UpdateProviderOrgDetails } from "../../../../../actions/provider-portal/UpdateContractorOrgDetails";
import { GetAllOrgIndustryTypeList } from "../../../../../actions/index";
import Label from "../../../../../common/element/Label";
import Input from "../../../../../common/element/Input";
import { Link } from "react-router-dom";
const CompanyProfile = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [logo, setLogo] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [dialogAddOpen, setdialogAddOpen] = useState(false);
  const [StateOptions2, setStateOptions2] = useState();
  const [mailingStateID, setMailingStateID] = useState("");
  const [countryOptions, setCountryOptions] = useState();
  const [mailingCountryID, setMailingCountryID] = useState("");
  const [formData, setFormData] = useState({
    trading_name: "",
    provider_org_detail_id: "",
    abn_nzbn: "",
    // acn: "",
    no_of_emp: "",
    ind_master_setting_id: "",
    website_url: "",
    businessAddres1: "",
    businessAddres2: "",
    businessAddresCity: "",
    businessAddresPostCode: "",
    businessAddresState: "",
    businessAddresCountry: "",
    businessId: "",

    mailingAddres1: "",
    mailingAddres2: "",
    mailingAddresCity: "",
    mailingAddresPostCode: "",
    mailingAddresState: "",
    mailingAddresCountry: "",
    mailingId: "",
  });
  const [formErrors, setFormErrors] = useState({
    abn_nzbn: "",
    // acn: "",
    no_of_emp: "",
    ind_master_setting_id: "",
    trading_name: "",
    businessAddres1: "",
    businessAddresCity: "",
    businessAddresPostCode: "",
    businessAddresState: "",
    businessAddresCountry: "",

    mailingAddres1: "",
    mailingAddresCity: "",
    mailingAddresPostCode: "",
    mailingAddresState: "",
    mailingAddresCountry: "",
    org_type: "",
    existClient: "",
  });

  const [organizationId, setOrganizationId] = useState("");
  const [parentLevel, setParentLevel] = useState("");
  const [countries, setCountries] = useState();
  const [statesOne, setStatesOne] = useState();
  const [statesTwo, setStatesTwo] = useState();
  const [sameAddress, setSameAddress] = useState();
  const [businessAddresCountry, setBusinessAddresCountry] = useState("");
  const [documentError, setdocumentError] = useState("");
  const [industoryOptions, setIndustoryOptions] = useState([]);
  const [noOfEmp, setNoOfEmp] = useState([
    { label: "0-50", value: "0-50" },
    { label: "50-100", value: "50-100" },
    { label: "100-200", value: "100-200" },
    { label: "200-300", value: "200-300" },
    { label: "300-400", value: "300-400" },
    { label: "400-500", value: "400-500" },
  ]);
  const [businessAddresState, setBusinessAddresState] = useState("");
  const [mailingAddresCountry, setMailingAddresCountry] = useState("");
  const [mailingAddresState, setMailingAddresState] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [mailingId, setMailingId] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  // state to set abn without having space
  const [abn, setAbn] = useState();
  const [callApi, setcallApi] = useState(false);
  const permissionDisabled =
    props.permission_data.settings_write == true ? false : true;
  let getApiData;
  let settings_permissions;
  let function_assignment_id;
  let user_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const permissionData = JSON.parse(localStorage.getItem("permission_data"));
  if (permissionData !== null) {
    settings_permissions = permissionData.settings_write;
  }
  if (storedData) {
    getApiData = {
      organisation_id: storedData.organisation_id,
    };
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
  }

  // api call for permissions
  useEffect(() => {
    const apiData = {
      user_id: user_id,
      function_assignment_id: function_assignment_id,
    };
    dispatch(GetClientUserPermByIdForClient(apiData)).then((response) => {
      if (response.success === true) {
        // const arrayData = response.data;
        localStorage.setItem("permission_data", JSON.stringify(response.data));
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      }
    });
  }, []);

  const clearFormErrors = () => {
    setFormErrors({
      abn_nzbn: "",
      // acn: "",
      no_of_emp: "",
      ind_master_setting_id: "",
      trading_name: "",
      businessAddres1: "",
      businessAddresCity: "",
      businessAddresPostCode: "",
      businessAddresState: "",
      businessAddresCountry: "",
      mailingAddres1: "",
      mailingAddresCity: "",
      mailingAddresPostCode: "",
      mailingAddresState: "",
      mailingAddresCountry: "",
      org_type: "",
    });
  };

  const clearFormData = () => {
    setFormData({
      trading_name: "",
      abn_nzbn: "",
      // acn: "",
      no_of_emp: "",
      provider_org_detail_id: "",
      ind_master_setting_id: "",
      website_url: "",
      businessAddres1: "",
      businessAddres2: "",
      businessAddresCity: "",
      businessAddresPostCode: "",
      businessAddresState: "",
      businessAddresCountry: "",

      mailingAddres1: "",
      mailingAddres2: "",
      mailingAddresCity: "",
      mailingAddresPostCode: "",
      mailingAddresState: "",
      mailingAddresCountry: "",
    });

    setBusinessType("");
  };

  const GetAllStatesbyCountryId = useCallback(
    async (formData) => {
      if (formData.businessAddresCountry) {
        dispatch(
          userActions.GetAllStatesbyCountryId(formData.businessAddresCountry)
        ).then((response) => {
          if (response.success === true) {
            const arrayData = response.data;
            let state_data = [];
            for (let key in arrayData) {
              state_data.push({
                label: arrayData[key].state_name,
                value: arrayData[key].state_id,
              });
            }
            setStatesOne(state_data);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          } else {
          }
        });
      }
      if (formData.mailingAddresCountry) {
        dispatch(
          userActions.GetAllStatesbyCountryId(formData.mailingAddresCountry)
        ).then((response) => {
          if (response.success === true) {
            const arrayData = response.data;
            let state_data = [];
            for (let key in arrayData) {
              state_data.push({
                label: arrayData[key].state_name,
                value: arrayData[key].state_id,
              });
            }
            setStatesTwo(state_data);
            setStateOptions2(state_data);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          } else {
          }
        });
      }
      // eslint-disable-next-line
    },
    [dispatch, formData.businessAddresCountry, formData.mailingAddresCountry]
  );

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response.success === true) {
        const arrayData = response.data;
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
            country_id: arrayData[key].country_id,
          });
        }

        setCountryOptions(country_data);
        setCountries(country_data);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAllStatesbyCountryId(formData);
  }, [formData.businessAddresCountry, formData.mailingAddresCountry]);

  useEffect(() => {
    if (callApi) {
      GetAllCountries();
    }
  }, [callApi]);

  const updateClientDatafromAPi = (arrayData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      trading_name: arrayData?.trading_name,
      abn_nzbn: arrayData?.abn_nzbn,
      // acn: arrayData?.acn,
      provider_org_detail_id:
        arrayData?.provider_org_detail?.provider_org_detail_id,
      no_of_emp: arrayData?.provider_org_detail?.no_of_emp,
      ind_master_setting_id:
        arrayData?.provider_org_detail?.ind_master_setting_id,
      website_url: arrayData?.website_url,
      businessAddres1: "",
      businessAddres2: "",
      businessAddresCity: "",
      businessAddresPostCode: "",

      mailingAddres1: "",
      mailingAddres2: "",
      mailingAddresCity: "",
      mailingAddresPostCode: "",
    }));
    setUrlImage(arrayData?.logo);
    setLogo(arrayData?.logo);
    setBusinessType(arrayData?.org_type);
    setOrganizationId(arrayData?.organisation_id);
    arrayData?.org_address_data.map((item) => {
      if (item.address_type === "business") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          businessAddres1: item.address_one,
          businessAddres2: item?.address_two,
          businessAddresCity: item?.suburb,
          businessAddresPostCode: item?.zipcode,
          businessId: item?.address_id,
          businessAddresCountry: item?.country_id,
          businessAddresState: item?.state_id,
        }));
        setBusinessId(item?.address_id);
        setBusinessAddresCountry({ label: item.country_name });
        setBusinessAddresState({ label: item.state_name });
        // setBusinessStateID(item?.state_id)
        // setbusinessCountryID(item?.country_id)
      }
    });

    arrayData?.org_address_data.map((item) => {
      if (item.address_type === "mailing") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          mailingAddres1: item?.address_one,
          mailingAddres2: item?.address_two,
          mailingAddresCity: item?.suburb,
          mailingAddresPostCode: item?.zipcode,
          mailingId: item?.address_id,
          mailingAddresCountry: item?.country_id,
          mailingAddresState: item?.state_id,
        }));
        setMailingId(item?.address_id);
        setMailingCountryID(item?.country_id);
        setMailingAddresCountry({ label: item.country_name });
        setMailingAddresState({ label: item.state_name });
        setMailingStateID(item?.state_id);
      }
    });

    setcallApi(true);
  };

  useEffect(() => {
    dispatch(GetProviderDetailsById(getApiData)).then((response) => {
      if (response.success) {
        const arrayData = response.data;
        updateClientDatafromAPi(arrayData);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      }
    });
  }, []);

  const inputchangehandler = (e, field) => {
    setFormErrors((prevalue) => {
      return {
        ...prevalue,
        [field]: "",
      };
    });
    let value;
    if (field == "ind_master_setting_id" || field == "no_of_emp") {
      value = e.value;
    } else if (field === "website_url") {
      value = e;
      // if (value.includes("www.") && !value.startsWith("www.")) {
      //   value = value.replace("www.", "");
      // }

      // if (!value.startsWith("www") && !value.startsWith("www.")) {
      //   value = `www.${value}`;
      // }
    } else if (field === "abn_nzbn") {
      value = value.replace(/\D/g, "");
    } else {
      if (e?.target?.value != undefined) {
        value = e.target.value;
      } else {
        value = e;
      }
    }
    setFormData({ ...formData, [field]: value });
    setHasChanges(true);
  };

  const handleFocusWebsite = () => {
    // Check if the current value doesn't already start with "www"
    if (!formData.website_url.startsWith("www")) {
      // Prepend "www" to the input value
      // setWebsite('www.' + website);
      setFormData({ ...formData, website_url: `www. ${formData.website_url}` });
    }
  };

  // function to auto space in abn number
  const handleABN = () => {
    let newValue = formData.abn_nzbn.replace(/\D/g, ""); // Remove non-digit characters
    const length = newValue.length;
    if (length <= 11) {
      // newValue = `${newValue.slice(0, 2)} ${newValue.slice(2)}`;
      newValue = `${newValue.slice(0, 2)} ${newValue.slice(
        2,
        5
      )} ${newValue.slice(5, 8)} ${newValue.slice(8, 11)}`;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      abn_nzbn: newValue,
    }));
    setAbn(newValue);
    let clientData = {
      trading_name: formData?.trading_name,
      abn_nzbn: formData?.abn_nzbn,
      organisation_id: organizationId,
    };

    dispatch(GetAlreadyExistingOrgCheck(clientData)).then((response) => {
      if (response.success) {
        const arrayData = response.data;
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          existClient: "",
        }));

        if (
          arrayData &&
          arrayData?.abn_nzbn == formData.abn_nzbn &&
          arrayData?.trading_name == formData.trading_name
        ) {
          // setClientExist(true)
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            existClient:
              "The Name and ABN/NZBN you have provided are already registered on our system. In order to register as a new organization, please choose a different name or ABN/NZBN",
          }));
        } else {
          // setClientExist(false)
        }
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      }
    });
  };

  // function to handle business Type
  const onBusinessType = useCallback(
    (val) => {
      setBusinessType(val);
      setHasChanges(true);
    },
    [setBusinessType]
  );

  const handleCountryChange = (selectedOption, identifier) => {
    if (identifier === "businessAddress") {
      //  setbusinessCountryID(selectedOption.value)
      setFormData((prevFormData) => ({
        ...prevFormData,
        businessAddresCountry: selectedOption.value,
        businessAddresState: "",
      }));
      setBusinessAddresCountry(selectedOption);
    }
    if (identifier === "mailingAddress") {
      setMailingCountryID(selectedOption.value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        mailingAddresCountry: selectedOption.value,
        mailingAddresState: "",
      }));
      setMailingStateID("");
      setMailingAddresCountry(selectedOption);
    }
    setHasChanges(true);
  };

  const handleStateChange = (selectedOption, identifier) => {
    if (identifier === "businessAddress") {
      //  setBusinessStateID(selectedOption.value)
      setFormData((prevFormData) => ({
        ...prevFormData,
        businessAddresState: selectedOption.value,
      }));
      setBusinessAddresState(selectedOption);
    }
    if (identifier === "mailingAddress") {
      setMailingStateID(selectedOption.value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        mailingAddresState: selectedOption.value,
      }));
      setMailingAddresState(selectedOption);
    }
    setHasChanges(true);
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      inputChangeHandlerFile(file);
    }
  };

  const inputChangeHandlerFile = (file) => {
    // Check the file extension
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/svg+xml" ||
        fileType.startsWith("image/png") ||
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/gif")
      ) {
        // Check the file size (max. 800x400px)
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.width <= 800 && img.height <= 400) {
            setLogo(file);
            setUrlImage(URL.createObjectURL(file));
            setdocumentError("");
            setHasChanges(true);
          } else {
            // Handle size validation error
            setdocumentError("Image dimensions must be within 800x400 pixels.");
          }
        };
      } else {
        // Handle file type validation error
        setdocumentError(
          "Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed."
        );
      }
    }
  };

  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const submitClient = (e) => {
    if (!formErrors.existClient) {
      if (!sameAddress) {
        // clearFormErrors();

        const abnValidation = Validations({
          value: formData.abn_nzbn,
          Validations: { required: true, ValidateABN: true },
        });
        const typeValidation = Validations({
          value: businessType,
          Validations: { required: true },
        });
        const nameValidation = Validations({
          value: formData.trading_name,
          Validations: { required: true },
        });

        const ind_master_setting_id = Validations({
          value: formData.ind_master_setting_id,
          Validations: { required: true },
        });
        const no_of_emp = Validations({
          value: formData.no_of_emp,
          Validations: { required: true },
        });

        if (!typeValidation.valid) {
          setFormError("org_type", typeValidation.error_msg);
        }
        if (!nameValidation.valid) {
          setFormError("trading_name", nameValidation.error_msg);
        }
        if (!ind_master_setting_id.valid) {
          setFormError(
            "ind_master_setting_id",
            ind_master_setting_id.error_msg
          );
        }
        if (!no_of_emp.valid) {
          setFormError("no_of_emp", no_of_emp.error_msg);
        }
        if (!abnValidation.valid) {
          setFormError("abn_nzbn", abnValidation.error_msg);
        }

        const add1BusinessValidation = Validations({
          value: formData.businessAddres1,
          Validations: { required: true },
        });
        const cityBusinessValidation = Validations({
          value: formData.businessAddresCity,
          Validations: { required: true },
        });
        const postCodeBusinessValidation = Validations({
          value: formData.businessAddresPostCode,
          Validations: { required: true },
        });
        const countryBusinessValidation = Validations({
          value: formData.businessAddresCountry,
          Validations: { required: true },
        });
        const stateBusinessValidation = Validations({
          value: formData.businessAddresState,
          Validations: { required: true },
        });

        const add1MailingValidation = Validations({
          value: formData.mailingAddres1,
          Validations: { required: true },
        });
        const cityMailingValidation = Validations({
          value: formData.mailingAddresCity,
          Validations: { required: true },
        });
        const postCodeMailingValidation = Validations({
          value: formData.mailingAddresPostCode,
          Validations: { required: true },
        });
        const countryMailingValidation = Validations({
          value: formData.mailingAddresCountry,
          Validations: { required: true },
        });
        const stateMailingValidation = Validations({
          value: formData.mailingAddresState,
          Validations: { required: true },
        });

        if (!add1BusinessValidation.valid) {
          setFormError("businessAddres1", add1BusinessValidation.error_msg);
        }
        if (!cityBusinessValidation.valid) {
          setFormError("businessAddresCity", cityBusinessValidation.error_msg);
        }
        if (!postCodeBusinessValidation.valid) {
          setFormError(
            "businessAddresPostCode",
            postCodeBusinessValidation.error_msg
          );
        }
        if (!countryBusinessValidation.valid) {
          setFormError(
            "businessAddresState",
            countryBusinessValidation.error_msg
          );
        }
        if (!stateBusinessValidation.valid) {
          setFormError(
            "businessAddresCountry",
            stateBusinessValidation.error_msg
          );
        }

        if (!add1MailingValidation.valid) {
          setFormError("mailingAddres1", add1MailingValidation.error_msg);
        }
        if (!cityMailingValidation.valid) {
          setFormError("mailingAddresCity", cityMailingValidation.error_msg);
        }
        if (!postCodeMailingValidation.valid) {
          setFormError(
            "mailingAddresPostCode",
            postCodeMailingValidation.error_msg
          );
        }
        if (!countryMailingValidation.valid) {
          setFormError(
            "mailingAddresState",
            countryMailingValidation.error_msg
          );
        }
        if (!stateMailingValidation.valid) {
          setFormError(
            "mailingAddresCountry",
            stateMailingValidation.error_msg
          );
        }
        if (
          typeValidation.valid === true &&
          nameValidation.valid === true &&
          no_of_emp.valid === true &&
          ind_master_setting_id.valid === true &&
          abnValidation.valid === true &&
          add1BusinessValidation.valid === true &&
          cityBusinessValidation.valid === true &&
          postCodeBusinessValidation.valid === true &&
          countryBusinessValidation.valid === true &&
          stateBusinessValidation.valid === true &&
          add1MailingValidation.valid === true &&
          cityMailingValidation.valid === true &&
          postCodeMailingValidation.valid === true &&
          countryMailingValidation.valid === true &&
          stateMailingValidation.valid === true
        ) {
          const payload = {
            organisation_id: organizationId,
            org_type:
              businessType === "pty"
                ? "pty_traders"
                : businessType === "sole"
                ? "sole_traders"
                : "other", //Assuming businessType is one of "pty", "sole", or "other"
            trading_name: formData.trading_name,
            abn_nzbn: formData.abn_nzbn,
            // abn_nzbn: abn,
            // acn: formData.acn,
            no_of_emp: formData?.no_of_emp,
            ind_master_setting_id: formData?.ind_master_setting_id,
            provider_org_detail_id: formData?.provider_org_detail_id,
            website_url: formData.website_url,
            logo: logo,
            provider_address_data: JSON.stringify([
              {
                address_type: "business",
                address_one: formData.businessAddres1,
                address_two: formData.businessAddres2,
                suburb: formData.businessAddresCity,
                zipcode: formData.businessAddresPostCode,
                state_id: formData.businessAddresState,
                country_id: formData.businessAddresCountry,
                state_name: businessAddresState.label,
                country_name: businessAddresCountry.label,
                address_id: businessId ? businessId : "",
              },
              {
                address_type: "mailing",
                address_one: formData.mailingAddres1,
                address_two: formData.mailingAddres2,
                suburb: formData.mailingAddresCity,
                zipcode: formData.mailingAddresPostCode,
                state_id: formData.mailingAddresState,
                country_id: formData.mailingAddresCountry,
                state_name: mailingAddresState.label,
                country_name: mailingAddresCountry.label,
                address_id: mailingId ? mailingId : "",
              },
            ]),
          };
          dispatch(UpdateProviderOrgDetails(payload))
            .then((response) => {
              if (response.success) {
                setdialogAddOpen(true);
                props.onDialogClose();
                clearFormErrors("");
                clearFormData("");
              } else if (response.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => {});
        }
      } else if (sameAddress) {
        // clearFormErrors();

        const abnValidation = Validations({
          value: formData.abn_nzbn,
          Validations: { required: true, ValidateABN: true },
        });
        const typeValidation = Validations({
          value: businessType,
          Validations: { required: true },
        });
        const nameValidation = Validations({
          value: formData.trading_name,
          Validations: { required: true },
        });

        if (!typeValidation.valid) {
          setFormError("org_type", typeValidation.error_msg);
        }
        if (!nameValidation.valid) {
          setFormError("trading_name", nameValidation.error_msg);
        }
        if (!abnValidation.valid) {
          setFormError("abn_nzbn", abnValidation.error_msg);
        }

        const add1BusinessValidation = Validations({
          value: formData.businessAddres1,
          Validations: { required: true },
        });
        const cityBusinessValidation = Validations({
          value: formData.businessAddresCity,
          Validations: { required: true },
        });
        const postCodeBusinessValidation = Validations({
          value: formData.businessAddresPostCode,
          Validations: { required: true },
        });
        const countryBusinessValidation = Validations({
          value: formData.businessAddresCountry,
          Validations: { required: true },
        });
        const stateBusinessValidation = Validations({
          value: formData.businessAddresState,
          Validations: { required: true },
        });

        if (!add1BusinessValidation.valid) {
          setFormError("businessAddres1", add1BusinessValidation.error_msg);
        }
        if (!cityBusinessValidation.valid) {
          setFormError("businessAddresCity", cityBusinessValidation.error_msg);
        }
        if (!postCodeBusinessValidation.valid) {
          setFormError(
            "businessAddresPostCode",
            postCodeBusinessValidation.error_msg
          );
        }
        if (!countryBusinessValidation.valid) {
          setFormError(
            "businessAddresState",
            countryBusinessValidation.error_msg
          );
        }
        if (!stateBusinessValidation.valid) {
          setFormError(
            "businessAddresCountry",
            stateBusinessValidation.error_msg
          );
        }

        if (
          typeValidation.valid === true &&
          nameValidation.valid === true &&
          abnValidation.valid === true &&
          add1BusinessValidation.valid === true &&
          cityBusinessValidation.valid === true &&
          postCodeBusinessValidation.valid === true &&
          countryBusinessValidation.valid === true &&
          stateBusinessValidation.valid === true
        ) {
          const payload = {
            organisation_id: organizationId,
            org_type:
              businessType === "pty"
                ? "pty_traders"
                : businessType === "sole"
                ? "sole_traders"
                : "other", //businessType is one of "pty", "sole", or "other"
            trading_name: formData.trading_name,
            abn_nzbn: formData.abn_nzbn,
            provider_org_detail_id: formData?.provider_org_detail_id,
            // acn: formData.acn,
            no_of_emp: formData.no_of_emp,
            ind_master_setting_id: formData.ind_master_setting_id,
            website_url: formData.website_url,
            logo: logo,
            provider_address_data: JSON.stringify([
              {
                address_type: "business",
                address_one: formData.businessAddres1,
                address_two: formData.businessAddres2,
                suburb: formData.businessAddresCity,
                zipcode: formData.businessAddresPostCode,
                state_id: formData.businessAddresState,
                country_id: formData.businessAddresCountry,
                state_name: businessAddresState.label,
                country_name: businessAddresCountry.label,
                address_id: businessId ? businessId : "",
              },
              {
                address_type: "mailing",
                address_one: formData.businessAddres1,
                address_two: formData.businessAddres2,
                suburb: formData.businessAddresCity,
                zipcode: formData.businessAddresPostCode,
                state_id: formData.businessAddresState,
                country_id: formData.businessAddresCountry,
                state_name: businessAddresState.label,
                country_name: businessAddresCountry.label,
                address_id: mailingId ? mailingId : "",
              },
            ]),
          };

          dispatch(UpdateProviderOrgDetails(payload))
            .then((response) => {
              if (response.success) {
                setdialogAddOpen(true);
                props.onDialogClose();
                clearFormErrors("");
                clearFormData("");
              } else if (response.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => {});
        }
      }
    }
  };

  const onAddDialogClose = () => {
    setdialogAddOpen(false);
  };

  useEffect(() => {
    dispatch(GetAllOrgIndustryTypeList()).then((response) => {
      let filterData = response?.data?.map((item) => {
        return { label: item.meta_value_one, value: item.master_setting_id };
      });
      setIndustoryOptions(filterData);
      // industoryOptions,
    });
  }, []);

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
        <div>
          <h5 className="text-base text-gray-900 font-semibold mb-1">
            Personal info
          </h5>
          <p className="text-gray-600">
            Update your photo and personal details here.
          </p>
        </div>
        {/* {settings_permissions && ( */}
        {/*permission for Read and Write */}
        <div className="flex justify-end items-center">
          <BtnLight disabled={permissionDisabled} className="w-auto px-4">
            Cancel
          </BtnLight>
          <SmallButton
            disabled={permissionDisabled}
            className="w-auto px-4 ml-3"
            onClick={() => submitClient()}
          >
            Save
          </SmallButton>
        </div>
        {/* )} */}
      </div>
      {formErrors.existClient && (
        <p className="text-xs text-red-600 mb-4 mt-0 pt-8">
          {formErrors.existClient}
        </p>
      )}
      <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Company logo
        </label>

        <div className="columns-input">
          {/*permission for Read and Write */}
          <div className="flex flex-row">
            <span className="h-16 w-16 bg-gray-100 rounded-full flex justify-center items-center mr-5 avtar_img_round">
              {urlImage ? (
                <img className="w-16 h-16 rounded-full" src={urlImage} alt="" />
              ) : (
                <img src={imagePlus} alt="" />
              )}
            </span>
            <div className="flex items-center justify-center  flex-1 upload-img ">
              <div className="flex items-center justify-center  flex-1 upload-img ">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white"
                >
                  <div
                    className="flex items-center justify-center  flex-1 upload-img ml-8"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={onDropHandler}
                  >
                    <div className="flex flex-col items-center justify-center">
                      <p className="mb-2 text-xs text-gray-600 darks:text-gray-400">
                        <span className="font-semibold text-blue-700">
                          Click to upload
                        </span>
                        <span className="text-xs"> or drag and drop</span>
                      </p>
                      <p className="text-xs text-gray-600 darks:text-gray-400 font-inter">
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </p>
                      {documentError && (
                        <div className="text-red-500 text-sm mt-1">
                          {documentError}
                        </div>
                      )}
                    </div>
                  </div>
                  <input
                    disabled={permissionDisabled}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={(e) => inputChangeHandlerFile(e.target.files[0])}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start  mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Business type*
        </label>
        <div className="flex items-center">
          <Segment
            className="docType-btn"
            value={businessType}
            onChange={(val) => onBusinessType(val)}
          >
            <Segment.Item value="pty">
              <span>Pty Ltd</span>
            </Segment.Item>
            <Segment.Item value="sole">
              <span>Sole Trader</span>
            </Segment.Item>
            <Segment.Item value="other">
              <span>Other</span>
            </Segment.Item>
          </Segment>
        </div>
      </div>
      <div className="flex items-start  mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Trading name*
        </label>
        <div className=" items-center">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="Enter business name here"
            value={formData.trading_name}
            // readOnly
            disabled={permissionDisabled}
            // onChange={(e) => inputchangehandler(e, "trading_name")}
          />
          {formErrors.trading_name && (
            <p className="text-xs text-red-600 mb-4 mt-0">
              {formErrors.trading_name}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">ABN/NZBN*</label>
        <div className=" items-center w-8/12">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            // id="abn_nzbn"
            type="number"
            placeholder="Enter ABN/NZBN here"
            value={formData.abn_nzbn}
            onChange={(e) => inputchangehandler(e, "abn_nzbn")}
            // onBlur={handleABN}
            maxLength="14"
            minLength="11"
            disabled={permissionDisabled}
          />
          {formErrors.abn_nzbn && (
            <p className="text-xs text-red-600 mb-4 mt-0">
              {formErrors.abn_nzbn}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Industry*</label>
        <div className="flex items-center w-8/12 flex-wrap">
          <Select
            className="w-full h-10 select-options"
            placeholder="Industry name here"
            readOnly={permissionDisabled}
            value={
              industoryOptions.filter(
                (item) => item.value == formData.ind_master_setting_id
              )[0]
            }
            options={industoryOptions}
            onChange={(e) => inputchangehandler(e, "ind_master_setting_id")}
          ></Select>
          {formErrors.ind_master_setting_id && (
            <p className="text-xs text-red-600 mb-4 mt-0">
              {formErrors.ind_master_setting_id}
            </p>
          )}
        </div>
      </div>
      {/* noOfEmp, setNoOfEmp */}
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Number of employees*
        </label>
        <div className="flex items-center w-8/12 flex-wrap">
          {/* <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="Enter number of employees here"
            value={formData.no_of_emp}
            onChange={(e) => inputchangehandler(e, "no_of_emp")}
          /> */}
          <Select
            className="w-full h-10 select-options"
            placeholder="Enter number of employees here"
            readOnly={permissionDisabled}
            value={
              noOfEmp.filter((item) => item.value == formData.no_of_emp)[0]
            }
            options={noOfEmp}
            onChange={(e) => inputchangehandler(e, "no_of_emp")}
          ></Select>
          {formErrors.no_of_emp && (
            <p className="text-xs text-red-600 mb-4 mt-0">
              {formErrors.no_of_emp}
            </p>
          )}
        </div>
      </div>

      {/* <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Website</label>
        <div className="flex items-center w-8/12">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-r-0 rounded-tr-none rounded-br-none"
            id="email"
            type="text"
            disabled={permissionDisabled}
            value={formData.website_url}
            onChange={(e) => inputchangehandler(e, "website_url")}
            onFocus={handleFocusWebsite}
          />
        </div>
      </div> */}
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Business Address*
        </label>
        <div className="flex items-center w-8/12 flex-wrap">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
            id="email"
            type="text"
            disabled={permissionDisabled}
            placeholder="Street Address*"
            value={formData.businessAddres1}
            onChange={(e) => inputchangehandler(e, "businessAddres1")}
          />
          {formErrors.businessAddres1 && (
            <p className="text-xs text-red-600 mb-4 mt-0">
              {formErrors.businessAddres1}
            </p>
          )}

          <div className="flex w-full gap-1">
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-1/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  mb-5"
              id="email"
              type="text"
              disabled={permissionDisabled}
              placeholder="Apartment, suite, etc"
              value={formData.businessAddres2}
              onChange={(e) => inputchangehandler(e, "businessAddres2")}
            />
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-1/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-1 mb-5"
              id="email"
              type="text"
              placeholder="Suburb*"
              disabled={permissionDisabled}
              value={formData.businessAddresCity}
              onChange={(e) => inputchangehandler(e, "businessAddresCity")}
            />
            <input
              type="text"
              placeholder="Postcode*"
              disabled={permissionDisabled}
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-1/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-1 mb-5"
              value={formData.businessAddresPostCode}
              onChange={(e) => inputchangehandler(e, "businessAddresPostCode")}
              maxLength={12}
            />
          </div>
          <div className="flex w-full">
            <input className="hidden appearance-none" />

            <input className="hidden appearance-none" />
            {formErrors.businessAddresCity && (
              <p className="text-xs text-red-600 mb-4 mt-0">
                {formErrors.businessAddresCity}
              </p>
            )}

            <input className="hidden appearance-none" />
            {formErrors.businessAddresPostCode && (
              <p className="text-xs text-red-600 mb-4 mt-0">
                {formErrors.businessAddresPostCode}
              </p>
            )}
          </div>
          <div className="flex w-full gap-2">
            <Select
              isSearchable={false}
              placeholder="Country"
              readOnly={permissionDisabled}
              options={countries}
              className="select-option w-full h-10 flex-1"
              value={countries?.filter(function (option) {
                return option.value === formData.businessAddresCountry;
              })}
              onChange={(e) => handleCountryChange(e, "businessAddress")}
            />

            <Select
              isSearchable={false}
              placeholder="State"
              readOnly={permissionDisabled}
              options={statesOne}
              className="select-option w-full h-10 flex-1"
              value={statesOne?.filter(function (option) {
                return option.value === formData.businessAddresState;
              })}
              onChange={(e) => handleStateChange(e, "businessAddress")}
            />
          </div>
          <div className="flex w-full">
            {formErrors.businessAddresCountry && (
              <p className="text-xs text-red-600 mb-4 mt-0 w-1/4">
                {formErrors.businessAddresCountry}
              </p>
            )}
            <input className="hidden appearance-none w-1/4" />
            {formErrors.businessAddresState && (
              <p className="text-xs text-red-600 mb-4 mt-0 w-1/4">
                {formErrors.businessAddresState}
              </p>
            )}
            <input className="hidden appearance-none w-1/4" />
          </div>
        </div>
      </div>
      <div className="my-4">
        <input
          className="checkbox rounded"
          type="checkbox"
          value={sameAddress ? sameAddress : false}
          checked={sameAddress ? sameAddress : false}
          onChange={() => setSameAddress(!sameAddress)}
        />
        <label className="text-gray-700 font-medium py-3">
          Same as Business Address
        </label>{" "}
      </div>
      <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
        <Label className="font-semibold w-2/12 font-sm">Mailing Address</Label>
        <div className="flex items-center w-8/12  flex-wrap">
          <Input
            type="text"
            name="address"
            placeholder="Street Address*"
            className="w-full h-9 mb-2"
            value={
              sameAddress ? formData.businessAddres1 : formData?.mailingAddres1
            }
            inputchangehandler={(e) => inputchangehandler(e, "mailingAddres1")}
            readOnly={sameAddress ? true : false}
          />
          {formErrors.mailingAddres1 && (
            <p className="text-xs text-red-600 mb-4 mt-0">
              {formErrors.mailingAddres1}
            </p>
          )}
          <div className="flex w-full gap-2">
            <div className="flex-1">
              <Input
                type="text"
                name="Apartment"
                placeholder="Apartment, suite, etc (optional)"
                className="mb-2 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-2 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={
                  sameAddress
                    ? formData.businessAddres2
                    : formData?.mailingAddres2
                }
                inputchangehandler={(e) =>
                  inputchangehandler(e, "mailingAddres2")
                }
                readOnly={sameAddress ? true : false}
              />
            </div>
            <div className="flex-1">
              <Input
                type="text"
                name="Suburb"
                placeholder="Suburb*"
                className="mb-2 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={
                  sameAddress
                    ? formData.businessAddresCity
                    : formData?.mailingAddresCity
                }
                inputchangehandler={(e) => inputchangehandler(e, "mailingAddresCity")}
                readOnly={sameAddress ? true : false}
              />
              
              {formErrors.mailingAddresCity && (
                <p className="text-xs text-red-600 mb-4 mt-0">
                  {formErrors.mailingAddresCity}
                </p>
              )}
            </div>
          </div>
          <div className="flex w-full gap-2">
            <div className="flex-1">
              <Select
                isSearchable={false}
                placeholder="Country"
                options={countryOptions}
                className="select-option mb-2 h-10 text-sm border-gray-300 rounded-lg  appearance-none w-full text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={
                  sameAddress
                    ? countries?.filter(function (option) {
                        return option.value === formData.businessAddresCountry;
                      })
                    : countryOptions?.filter(function (option) {
                        return option.value === mailingCountryID;
                      })
                }
                onChange={(e) => handleCountryChange(e, "mailingAddress")}
              />
              {formErrors.mailingAddresCountry && (
                <p className="text-xs text-red-600 mb-4 mt-0">
                  {formErrors.mailingAddresCountry}
                </p>
              )}
            </div>
            <div className="flex-1">
              <Input
                type="text"
                name="Postcode"
                placeholder="Postcode*"
                className="mb-2 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={
                  sameAddress
                    ? formData.businessAddresPostCode
                    : formData?.mailingAddresPostCode
                }
                inputchangehandler={(e) =>
                  inputchangehandler(e, "mailingAddresPostCode")
                }
                readOnly={sameAddress ? true : false}
                maxLength={12}
              />
              {formErrors.mailingAddresPostCode && (
                <p className="text-xs text-red-600 mb-4 mt-0">
                  {formErrors.mailingAddresPostCode}
                </p>
              )}
            </div>
          </div>

          <div className="flex w-full h-9">
            <Select
              isSearchable={false}
              placeholder="State*"
              options={StateOptions2}
              className="select-option flex-1"
              value={
                sameAddress
                  ? statesOne?.filter(function (option) {
                      return option.value === formData.businessAddresState;
                    })
                  : StateOptions2?.filter(function (option) {
                      return option.value === mailingStateID;
                    })
              }
              onChange={(e) => handleStateChange(e, "mailingAddress")}
            />
            {formErrors.mailingAddresState && (
              <p className="text-xs text-red-600 mb-4 mt-0">
                {formErrors.mailingAddresState}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-start mb-5">
        <Label className="font-semibold w-2/12">Website</Label>
        <div className="flex items-center w-8/12">
          <Input
            type="text"
            name="Postcode"
            placeholder="www.sitename.com"
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-r-0 rounded-tr-none rounded-br-none"
            value={formData?.website_url}
            inputchangehandler={(e) => inputchangehandler(e, "website_url")}
          />
          <Link
            to="#"
            className="text-gray-700 font-semibold border border-gray-100 bg-white w-24  flex h-10 justify-center items-center rounded-lg gap-1 rounded-tl-none rounded-bl-none hover:text-gray-900 focus:text-gray-600"
            onClick={() => {
              if (formData?.website_url.trim() !== "") {
                window.open(`https://${formData?.website_url}`, "_blank");
              }
            }}
          >
            <svg
              width="16"
              height="15"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Open
          </Link>
        </div>
      </div>

      {formErrors.existClient && (
        <p className="text-xs text-red-600 mb-4 mt-0 pt-8">
          {formErrors.existClient}
        </p>
      )}

      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Company profile has been saved successfully
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onAddDialogClose()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default CompanyProfile;
