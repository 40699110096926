import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Switcher from "../../../../../../../common/element/Switcher";
import ScrollBar from "../../../../../../../common/element/ScrollBar";
import SmallButton from "../../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import Select from "../../../../../../../common/element/Select";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  GetAllIndividualListForInvite,
  GetAllSpecificOrgInvites,
  InviteOrgUser,
} from "../../../../../../../actions/admin-provider/AllUserApis";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import Validations from "../../../../../../../common/404/Validations";
import * as userActions from "../../../../../../../actions/index";
import { debounce } from "../../../../../../../common/snippet/Debouncing";

const AddUser = (props) => {
  const [open, setOpen] = useState();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  // const [enteruserEmail, setEnterUserEmail] = useState("");
  const [showUserName, setUserName] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  // const [individualId, setIndividualId] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [search, setSearch] = useState();
  const [readOnlyAll, setReadOnlyAll] = useState(false);
  // const [firstName, setFirstName] = useState();
  // const [lastName, setLastName] = useState();
  const [allPermissionsTrue, setAllPermissionsTrue] = useState(false);
  const [contactTypeId, setContactTypeId] = useState("");
  const [callEmailAPI, setEmailAPI] = useState(true);

  const [allowAll, setAllowAll] = useState(false);

  //   const [emailError, setEmailError] = useState("");
  //   const [firstNameError, setFirstNameError] = useState();
  //   const [lastNameError, setLastNameError] = useState();
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    enteruserEmail: "",
    individual_id: "",
    user_id: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const [permission, setPermissions] = useState({
    // client_view:false,
    // client_write:false,
    documents_view: false,
    documents_write: false,
    client_view: false,
    client_write: false,
    workers_views: false,
    workers_write: false,
    // compliance_view:false,
    // compliance_write:false,
    contacts_view: false,
    contacts_write: false,
    // provider_view: false,
    // provider_write:false,
    // documents_view:false,
    // documents_write: false
    // induction_view:false,
    // induction_write:false,
    invoices_view: false,
    invoices_write: false,
    reporting_view: false,
    reporting_write: false,
    settings_view: false,
    settings_write: false,
    // sites_view: false,
    // sites_write: false,
    users_view: false,
    users_write: false,
    // workers_view: false,
    // workers_write: false,
    notes_view: false,
    notes_write: false,
    support_view: false,
    support_write: false,
    messages_view: false,
    messages_write: false,
  });
  const requiredKeys = [
    "documents_view",
    "documents_write",
    "client_view",
    "client_write",
    "workers_views",
    "workers_write",
    // compliance_view:false,
    // compliance_write:false,
    "contacts_view",
    "contacts_write",
    // provider_view: false,
    // provider_write:false,
    // documents_view:false,
    // documents_write: false
    // induction_view:false,
    // induction_write:false,
    "invoices_view",
    "invoices_write",
    "reporting_view",
    "reporting_write",
    "settings_view",
    "settings_write",
    // sites_view: false,
    // sites_write: false,
    "users_view",
    "users_write",
    // workers_view: false,
    // workers_write: false,
    "notes_view",
    "notes_write",
    "support_view",
    "support_write",
    "messages_view",
    "messages_write",
  ];
  const AllContactType = useSelector((state) => state?.AllContactTypes);
  const { SpecificProviderData } = useSelector((state) => state);

  const function_assignment_id = SpecificProviderData?.provider_fa_id;
  let trading_name = "provider trading corpo";

  useEffect(() => {
    if (AllContactType) {
      const contactTypeId = AllContactType.find(
        (item) => item.contact_type === "provider contact"
      )?.contact_type_id;

      setContactTypeId(contactTypeId);
    }
  }, []);

  // Function to handle opening/closing accordions
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const areAllPermissionsTrue = (permData) => {
    // Check if permData is an object and has keys
    if (
      typeof permData !== "object" ||
      permData === null ||
      Object.keys(permData).length === 0
    ) {
      return false;
    }
    // Filter and reduce to get the relevant permissions
    const filtered = Object.keys(permData)
      .filter((key) => requiredKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = permData[key];
        return obj;
      }, {});

    // Check if all values in filtered are true
    return Object.values(filtered).every((value) => value === true);
  };
  // Function to toggle view setting
  const onSwitcherToggle = (e, identifier) => {
    setPermissions({ ...permission, [identifier]: !e });
    if (identifier === "documents_view_write") {
      setPermissions({
        ...permission,
        documents_view: !e,
        documents_write: !e,
      });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          documents_view: !e,
          documents_write: !e,
        })
      );
    }
    if (identifier === "client_view_write") {
      setPermissions({ ...permission, client_view: !e, client_write: !e });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          client_view: !e,
          client_write: !e,
        })
      );
    }
    if (identifier === "workers_view_write") {
      setPermissions({ ...permission, workers_views: !e, workers_write: !e });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          workers_views: !e,
          workers_write: !e,
        })
      );
    }
    if (identifier === "reporting_view_write") {
      setPermissions({
        ...permission,
        reporting_view: !e,
        reporting_write: !e,
      });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          reporting_view: !e,
          reporting_write: !e,
        })
      );
    }
    if (identifier === "settings_view_write") {
      setPermissions({ ...permission, settings_view: !e, settings_write: !e });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          settings_view: !e,
          settings_write: !e,
        })
      );
    }
    // if(identifier === "compliance_view_write"){
    //   setPermissions({ ...permission, compliance_view:!e, compliance_write:!e,})
    // }
    if (identifier === "users_view_write") {
      setPermissions({ ...permission, users_view: !e, users_write: !e });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          users_view: !e,
          users_write: !e,
        })
      );
    }
    if (identifier === "contacts_view_write") {
      setPermissions({ ...permission, contacts_view: !e, contacts_write: !e });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          contacts_view: !e,
          contacts_write: !e,
        })
      );
    }
    if (identifier === "invoices_view_write") {
      setPermissions({ ...permission, invoices_view: !e, invoices_write: !e });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          invoices_view: !e,
          invoices_write: !e,
        })
      );
    }
    if (identifier === "messages_view_write") {
      setPermissions({ ...permission, messages_view: !e, messages_write: !e });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          messages_view: !e,
          messages_write: !e,
        })
      );
    }
    if (identifier === "notes_view_write") {
      setPermissions({ ...permission, notes_view: !e, notes_write: !e });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          notes_view: !e,
          notes_write: !e,
        })
      );
    }
    if (identifier === "support_view_write") {
      setPermissions({ ...permission, support_view: !e, support_write: !e });
      setAllPermissionsTrue(
        areAllPermissionsTrue({
          ...permission,
          support_view: !e,
          support_write: !e,
        })
      );
    }

    // if(identifier === "induction_view_write"){
    //   setPermissions({ ...permission, induction_view:!e, induction_write:!e,})
    // }
    // if(identifier === "contractor_view_write"){
    //   setPermissions({ ...permission, provider_view:!e, provider_write:!e,})
    // }
    // if(identifier === "sites_view_write"){
    //   setPermissions({ ...permission, sites_view:!e, sites_write:!e,})
    // }
    if (identifier === "all") {
      setAllowAll((e) => !e);
      setReadOnlyAll(false);
      setPermissions({
        ...permission,
        // compliance_view:e,
        // compliance_write:e,
        documents_view: !e,
        documents_write: !e,
        client_view: !e,
        client_write: !e,
        workers_views: !e,
        workers_write: !e,
        contacts_view: !e,
        contacts_write: !e,
        // provider_view:e,
        // provider_write:e,
        // induction_view:e,
        // induction_write:e,
        invoices_view: !e,
        invoices_write: !e,
        reporting_view: !e,
        reporting_write: !e,
        settings_view: !e,
        settings_write: !e,
        // sites_view:e,
        // sites_write:e,
        users_view: !e,
        users_write: !e,
        support_view: !e,
        support_write: !e,
        notes_view: !e,
        notes_write: !e,
        messages_view: !e,
        messages_write: !e,
      });
    }
    if (identifier === "realAll") {
      if (allowAll == true) {
        setAllPermissionsTrue((prevState) => !prevState);
      }

      setPermissions({
        ...permission,
        client_view: !readOnlyAll,
        client_write: false,
        workers_views: !readOnlyAll,
        workers_write: false,
        documents_view: !readOnlyAll,
        documents_write: false,
        compliance_view: !readOnlyAll,
        compliance_write: false,
        contacts_view: !readOnlyAll,
        contacts_write: false,
        provider_view: !readOnlyAll,
        provider_write: false,
        induction_view: !readOnlyAll,
        induction_write: false,
        invoices_view: !readOnlyAll,
        invoices_write: false,
        reporting_view: !readOnlyAll,
        reporting_write: false,
        settings_view: !readOnlyAll,
        settings_write: false,
        sites_view: !readOnlyAll,
        sites_write: false,
        users_view: !readOnlyAll,
        users_write: false,
        messages_view: !readOnlyAll,
        messages_write: false,
        dashboard_view: !readOnlyAll,
        dashboard_write: false,
        notes_view: !readOnlyAll,
        notes_write: false,
        support_view: !readOnlyAll,
        support_write: false,
      });
      setReadOnlyAll(!readOnlyAll); // Toggle readOnlyAll state
      setAllowAll(false);
    }
  };

  // const userNameChangeHandler = (event) => {
  //     setEnterUserEmail(event.target.value);
  //     if (enteruserEmail.trim().length >= 1) {
  //         setUserName(true);
  //         return;
  //   }
  //   else{
  //     setUserName(false);
  //     return;
  //   }
  // }

  // clear form data
  const clearFormData = () => {
    setFormData({
      email: "",
      first_name: "",
      last_name: "",
      enteruserEmail: "",
      individual_id: "",
      user_id: "",
    });
  };
  //clear form errors
  const clearFormErrors = () => {
    setFormErrors({
      email: "",
      first_name: "",
      setLastName: "",
    });
  };

  // Handle the select option change
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    setFormData((prevFormData) => ({
      ...prevFormData,
      enteruserEmail: selectedOption?.label,
    }));
    if (selectedOption?.individualId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        first_name: selectedOption?.firstName,
        last_name: selectedOption?.lastName,
        user_id: selectedOption?.user_id,
        individual_id: selectedOption?.individualId,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        first_name: "",
        last_name: "",
        individual_id: "",
      }));
    }
  };

  // Create a custom option for the Select component
  const customOption = { value: search, label: search };
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : [...searchOptions, customOption];

  // Handle search input change for email
  const handleSearchChange = debounce((newInputValue) => {
    setSearch(newInputValue.trim());
    if (search?.trim().length >= 2) {
      setUserName(true);
      // GetAllIndividualListForInvite(search);
      // GetAllIndividualListForInvite(newInputValue);
      return;
    }
  }, 500);
  useEffect(() => {
    if (search && search.trim().length >= 2) {
      // setUserName(true);
      dispatch(GetAllIndividualListForInvite({ search: search })).then(
        (response) => {
          if (response.success) {
            const arrayData = response.data;
            let userData = [];
            for (let key in arrayData) {
              userData.push({
                label: arrayData[key]?.email,
                value: arrayData[key]?.email,
                individualId: arrayData[key]?.individual_id,
                firstName: arrayData[key]?.first_name,
                lastName: arrayData[key]?.last_name,
                user_id: arrayData[key]?.user_id,
              });
            }
            // below condition still need to clarify
            // if user_id === null then it is not a user & if user_id !== null then it is user

            setSearchOptions(userData);
            setFormErrors({
              ...formErrors,
              email: "",
            });
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        }
      );
    }
  }, [search]);

  // Handle input changes
  const inputChangeHandler = (e, field) => {
    let value = e.target.value;
    setFormData({ ...formData, [field]: value });
  };

  const GetIndividualByEmail = async () => {
    return new Promise(async (resolve) => {
      if (formData.enteruserEmail) {
        // props.onGetIndividualByEmail(enteruserEmail).then((response) => {
        const response = await props.onGetIndividualByEmail(
          formData.enteruserEmail
        );
        if (response.success === true) {
          const arrayData = response.data;
          if (arrayData && arrayData?.is_conserve_team === true) {
            setEmailAPI(true);
            setFormErrors({
              ...formErrors,
              email: "This email can not be selected",
            });
          } else {
            setEmailAPI(false);
          }
          // check if user_id exist or not if exist setUserId here also in below condition
          
          // if (arrayData && arrayData?.is_conserve_team === false) {
          //   let name =
          //     arrayData?.arrayData?.first_name +
          //     " " +
          //     arrayData?.arrayData?.last_name;
          //   setIndividualId(arrayData?.arrayData?.individual_id);
          // }
          // }
          resolve(); // Resolve the promise once the function completes
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      } else {
        resolve(); // Resolve the promise if there is no email
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (callEmailAPI === false) {
        submitUserAPI();
      }
    }, 500);
  }, [callEmailAPI]);

  const submitUser = async (e) => {
    await GetIndividualByEmail();
  };

  // set form errors
  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };
  const submitUserAPI = () => {
    if (formErrors.email === "" && callEmailAPI === false) {
      //Validate
      const emailValidation = Validations({
        value: formData.enteruserEmail,
        Validations: { required: true, email: true },
      });
      const firstNameValidation = Validations({
        value: formData.first_name,
        Validations: { required: true },
      });
      const lastNameValidation = Validations({
        value: formData.last_name,
        Validations: { required: true },
      });

      if (!emailValidation.valid) {
        setFormError("email", emailValidation.error_msg);
      }
      if (!firstNameValidation.valid) {
        setFormError("first_name", firstNameValidation.error_msg);
      }
      if (!lastNameValidation.valid) {
        setFormError("last_name", lastNameValidation.error_msg);
      }

      if (
        emailValidation.valid === true &&
        firstNameValidation.valid === true &&
        lastNameValidation.valid === true
      ) {
        const payload = {
          individual_id: formData.individual_id
            ? formData.individual_id
            : "",
          function_assignment_id: function_assignment_id,
          trading_name: trading_name,
          email: formData.enteruserEmail,
          first_name: formData.first_name,
          last_name: formData.last_name,
          contact_type_id: contactTypeId,
          user_id: formData.user_id ? formData.user_id : "",
          user_perm_data: JSON.stringify({
            // view_setting: true,
            documents_view: permission.documents_write
              ? true
              : permission.documents_view,
            documents_write: permission.documents_write,
            client_view: permission.client_write
              ? true
              : permission.client_view,
            client_write: permission.client_write,
            workers_views: permission.workers_write
              ? true
              : permission.workers_views,
            workers_write: permission.workers_write,
            // compliance_view:permission.compliance_write ? true : permission.compliance_view,
            // compliance_write:permission.compliance_write,
            contacts_view: permission.contacts_write
              ? true
              : permission.contacts_view,
            contacts_write: permission.contacts_write,
            // provider_view: permission.contacts_write ? true : permission.provider_view,
            // provider_write:permission.provider_write,
            // induction_view:permission.induction_write ? true : permission.induction_view,
            // induction_write:permission.induction_write,
            invoices_view: permission.invoices_write
              ? true
              : permission.induction_view,
            invoices_write: permission.invoices_write,
            reporting_view: permission.reporting_write
              ? true
              : permission.reporting_view,
            reporting_write: permission.reporting_write,
            settings_view: permission.settings_write
              ? true
              : permission.settings_view,
            settings_write: permission.settings_write,
            // sites_view: permission.sites_write ? true : permission.sites_view,
            // sites_write: permission.sites_write,
            users_view: permission.users_write ? true : permission.users_view,
            users_write: permission.users_write,

            notes_view: permission.notes_write ? true : permission.notes_view,
            notes_write: permission.notes_write,

            messages_view: permission.messages_write
              ? true
              : permission.messages_view,
            messages_write: permission.messages_write,

            support_view: permission.support_write
              ? true
              : permission.support_view,
            support_write: permission.support_write,
          }),
          invited_user_type: "provider_user",
        };

        dispatch(InviteOrgUser(payload))
          .then((response) => {
            if (response.success) {
              props.addUserClose();
              clearFormData();
              clearFormErrors();
              dispatch(
                GetAllSpecificOrgInvites({
                  function_assignment_id: function_assignment_id,
                  invited_user_type: "provider_user",
                })
              ).then((response) => {
                if (response.success) {
                  const arrayData = response.data;
                  props.updateInvitedData(arrayData);
                  // props.onInvitedUser(arrayData);
                } else if (response.status === 401) {
                  handleUnauthorizedAccess();
                }
              });
            } else if (response.status === 409) {
              setFormError("email", response.message);
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          .catch((error) => {});
      }
    }
  };
  console.log("settings_view::", permission);
  return (
    <React.Fragment>
      <div className="h-500">
        <ScrollBar>
          <div className="px-6 pb-3">
            <div className="flex flex-col">
              <label className="block text-gray-700  mb-2 text-xs font-medium">
                Add a user
              </label>
              <Select
                type="text"
                placeholder="Enter Contact's email address"
                className="w-full h-10 select-options"
                isClearable={true}
                isSearchable={true}
                options={updatedOptions ? updatedOptions : selectedOption}
                value={selectedOption || customOption}
                onChange={handleSelectChange}
                handleSearchChange={handleSearchChange}
              />
              {formErrors.email && (
                <p className="text-xs text-red-600 mb-4 mt-0">
                  {formErrors.email}
                </p>
              )}
            </div>
            {showUserName && (
              <div className="flex mt-5">
                <div className="flex-1 mr-1">
                  <label className="block text-gray-700  mb-2 text-xs font-medium">
                    First name*
                  </label>
                  <input
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="firstName"
                    type="text"
                    placeholder="Enter Contact's first name"
                    value={formData.first_name}
                    onChange={(e) => inputChangeHandler(e, "first_name")}
                    readOnly={formData.individual_id ? true : false}
                  ></input>
                  {formErrors.first_name && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.first_name}
                    </p>
                  )}
                </div>
                <div className="flex-1 ml-1">
                  <label className="block text-gray-700  mb-2 text-xs font-medium">
                    Last name*
                  </label>
                  <input
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="lastName"
                    type="text"
                    placeholder="Enter Contact's last name"
                    value={formData.last_name}
                    onChange={(e) => inputChangeHandler(e, "last_name")}
                    readOnly={formData.individual_id ? true : false}
                  ></input>
                  {formErrors.last_name && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.last_name}
                    </p>
                  )}
                </div>
              </div>
            )}
            <hr className="my-5 border-gray-200" />
            <div className="flex flex-col">
              <span className="text-base text-gray-900 font-semibold">
                User access
              </span>
              <p className="text-gray-600 mt-1">
                {" "}
                Grant the user full app access or customise their access to
                specific areas.
              </p>
            </div>
            <div className="flex justify-between gap-4 mb-5 mt-5">
              <div className="flex items-center mt-7 mb-5 px-4">
                {/* <Switcher onChange={(e) => onSwitcherToggle(e, "all")} />
                <span className="text-xs text-gray-700 font-medium ml-2.5">
                  Allow access to all
                </span>
                 */}
                <Switcher
                  onChange={(e) => onSwitcherToggle(e, "all")}
                  checked={allowAll && allPermissionsTrue}
                />
                <span className="text-xs text-gray-700 font-medium ml-2.5">
                  {allowAll
                    ? "Full decline to all sections"
                    : "Full access to all sections"}
                </span>
              </div>
              <div className="flex items-center w-1/2">
                <Switcher
                  onChange={(e) => onSwitcherToggle(e, "realAll")}
                  checked={readOnlyAll}
                />
                <span className="text-xs text-gray-700 font-medium ml-2.5">
                  {readOnlyAll
                    ? "Read-only decline to all sections"
                    : "Read-only access to all sections"}
                </span>
              </div>
            </div>

            <Accordion open={open === 9}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(9)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) =>
                      onSwitcherToggle(e, "documents_view_write")
                    }
                    checked={
                      permission &&
                      permission.documents_view &&
                      permission.documents_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Documnet
                    </span>
                    <p className="text-gray-600"></p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "documents_view")}
                      checked={permission.documents_view}
                      value={permission.documents_view}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "documents_write")}
                      checked={permission.documents_write}
                      value={permission.documents_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 10}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(10)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "client_view_write")}
                    checked={
                      permission &&
                      permission.client_view &&
                      permission.client_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Client
                    </span>
                    <p className="text-gray-600"></p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "client_view")}
                      checked={permission.client_view}
                      value={permission.client_view}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "client_write")}
                      checked={permission.client_write}
                      value={permission.client_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 11}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(11)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "workers_view_write")}
                    checked={
                      permission &&
                      permission.workers_views &&
                      permission.workers_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Worker
                    </span>
                    <p className="text-gray-600"></p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "workers_views")}
                      checked={permission.workers_views}
                      value={permission.workers_views}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "workers_write")}
                      checked={permission.workers_write}
                      value={permission.workers_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 1}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(1)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "settings_view_write")}
                    checked={
                      permission &&
                      permission.settings_view &&
                      permission.settings_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Company Settings
                    </span>
                    {/* <p className="text-gray-600">Supporting text lorem ipsum il alamoni pilmolo</p> */}
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "settings_view")}
                      checked={permission.settings_view}
                      value={permission.settings_view}
                    />
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "settings_write")}
                      checked={permission.settings_write}
                      value={permission.settings_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                            <Switcher onChange={onSwitcherToggle} /> <span className="text-gray-600 ml-4 font-semibold">Accordion header</span>
                            </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 5}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(5)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "invoices_view_write")}
                    checked={
                      permission &&
                      permission.invoices_view &&
                      permission.invoices_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Invoices
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "invoices_view")}
                      checked={permission.invoices_view}
                      value={permission.invoices_view}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "invoices_write")}
                      checked={permission.invoices_write}
                      value={permission.invoices_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 12}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(12)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) =>
                      onSwitcherToggle(e, "reporting_view_write")
                    }
                    checked={
                      permission &&
                      permission.reporting_view &&
                      permission.reporting_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Reporting
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "reporting_view")}
                      checked={permission.reporting_view}
                      value={permission.reporting_view}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "reporting_write")}
                      checked={permission.reporting_write}
                      value={permission.reporting_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 3}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(3)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "users_view_write")}
                    checked={
                      permission &&
                      permission.users_view &&
                      permission.users_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Users
                    </span>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "users_view")}
                      checked={permission.users_view}
                      value={permission.users_view}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "users_write")}
                      checked={permission.users_write}
                      value={permission.users_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                            <Switcher onChange={onSwitcherToggle} /> <span className="text-gray-600 ml-4 font-semibold">Accordion header</span>
                            </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 4}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(4)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "contacts_view_write")}
                    checked={
                      permission &&
                      permission.contacts_view &&
                      permission.contacts_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Contacts
                    </span>
                    {/* <p className="text-gray-600">Supporting text lorem ipsum il alamoni pilmolo</p> */}
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "contacts_view")}
                      checked={permission.contacts_view}
                      value={permission.contacts_view}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "contacts_write")}
                      checked={permission.contacts_write}
                      value={permission.contacts_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                            <Switcher onChange={onSwitcherToggle} /> <span className="text-gray-600 ml-4 font-semibold">Accordion header</span>
                            </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 2}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(2)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "messages_view_write")}
                    checked={
                      permission &&
                      permission.messages_view &&
                      permission.messages_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Messages
                    </span>
                    {/* <p className="text-gray-600">Supporting text lorem ipsum il alamoni pilmolo</p> */}
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "messages_view")}
                      checked={permission.messages_view}
                      value={permission.messages_view}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "messages_write")}
                      checked={permission.messages_write}
                      value={permission.messages_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                            <Switcher onChange={onSwitcherToggle} /> <span className="text-gray-600 ml-4 font-semibold">Accordion header</span>
                            </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 6}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(6)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "notes_view_write")}
                    checked={
                      permission &&
                      permission.notes_view &&
                      permission.notes_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Notes
                    </span>
                    {/* <p className="text-gray-600">Supporting text lorem ipsum il alamoni pilmolo</p> */}
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "notes_view")}
                      checked={permission.notes_view}
                      value={permission.notes_view}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "notes_write")}
                      checked={permission.notes_write}
                      value={permission.notes_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                            <Switcher onChange={onSwitcherToggle} /> <span className="text-gray-600 ml-4 font-semibold">Accordion header</span>
                            </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 7}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(7)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "support_view_write")}
                    checked={
                      permission &&
                      permission.support_view &&
                      permission.support_write
                        ? true
                        : false
                    }
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Support
                    </span>
                    {/* <p className="text-gray-600">Supporting text lorem ipsum il alamoni pilmolo</p> */}
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "support_view")}
                      checked={permission.support_view}
                      value={permission.support_view}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "support_write")}
                      checked={permission.support_write}
                      value={permission.support_write}
                    />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                            <Switcher onChange={onSwitcherToggle} /> <span className="text-gray-600 ml-4 font-semibold">Accordion header</span>
                            </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            {/* <Accordion open={open === 2}>
              <AccordionHeader className="reset-accordian-btn" onClick={() => handleOpen(2)}>
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "compliance_view_write")}
                    checked={permission && permission.compliance_view && permission.compliance_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">Compliance Checklist</span>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher
                      onChange={(e) => onSwitcherToggle(e, "compliance_view")}
                      checked={permission.compliance_view}
                      value={permission.compliance_view} />
                    <span className="text-gray-600 ml-4 font-semibold">Read</span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "compliance_write")}
                      checked={permission.compliance_write}
                      value={permission.compliance_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
         
                </ul>
              </AccordionBody>
            </Accordion> */}
            {/* <Accordion open={open === 6}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(6)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "induction_view_write")}
                    checked={permission && permission.induction_view && permission.induction_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Induction
                    </span>
                    <p className="text-gray-600">
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "induction_view")}
                      checked={permission.induction_view}
                      value={permission.induction_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "induction_write")}
                      checked={permission.induction_write}
                      value={permission.induction_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  
                </ul>
              </AccordionBody>
            </Accordion> */}
            {/* <Accordion open={open === 7}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(7)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "contractor_view_write")}
                    checked={permission && permission.provider_view && permission.provider_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Provider
                    </span>
                    <p className="text-gray-600">
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "provider_view")}
                      checked={permission.provider_view}
                      value={permission.provider_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "provider_write")}
                      checked={permission.provider_write}
                      value={permission.provider_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                 
                </ul>
              </AccordionBody>
            </Accordion> */}
            {/* <Accordion open={open === 8}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(8)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "sites_view_write")}
                    checked={permission && permission.sites_view && permission.sites_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Sites
                    </span>
                    <p className="text-gray-600">
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "sites_view")}
                      checked={permission.sites_view}
                      value={permission.sites_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "sites_write")}
                      checked={permission.sites_write}
                      value={permission.sites_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                
                </ul>
              </AccordionBody>
            </Accordion> */}
          </div>
        </ScrollBar>
      </div>
      <div className="flex dailog-footer mt-0">
        <BtnLight className="mr-1.5" onClick={() => props.addUserClose()}>
          Cancel
        </BtnLight>
        <SmallButton className="ml-1.5" onClick={submitUser}>
          Invite user
        </SmallButton>
      </div>
    </React.Fragment>
  );
};
// export default AddUser
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  onInviteOrgUser: userActions.InviteOrgUser,
  onGetIndividualByEmail: userActions.GetIndividualByEmail,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
