import React, { useEffect, useState } from "react";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../common/element/Select/Select";
import ClientInput from "../../../../../common/element/Input/clientInput";
import { useDispatch } from "react-redux";

import { UpdateProviderClientContact } from "../../../../../actions/admin-provider/UpdateContractorClientContact";

// import { GetAllContactsOfProvider } from "../../../../../actions/admin-provider/GetAllContactsOfProvider";
import { GetAllContactsOfProvider } from "../../../../../actions/admin-provider/GetAllContactsOfContractor";

import { notify } from "react-notify-toast";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";

const EditContactForm = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [OrgId,SetOrgId]=useState("")
  const [addContactData, SetaddContactData] = useState({
    individual_id: "",
    organisation_id: "",
    job_title: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });

  useEffect(() => {
    if (props.indivisualData) {
      if (props.indivisualData.hasOwnProperty("provider_client_contact_id")) {
        SetOrgId(props.indivisualData.provider_org_id)
        SetaddContactData({
          provider_client_contact_id:
          props.indivisualData.provider_client_contact_id,
          job_title: props.indivisualData.contact_title,
          email: props.indivisualData.contact_email,
          first_name: props.indivisualData.contact_first_name,
          last_name: props.indivisualData.contact_last_name,
          phone: props.indivisualData.contact_phone,
        });
      } else {
        SetaddContactData((prevState) => ({
          ...prevState,
          ...props.indivisualData,
        }));
      }
    }
  }, [props.indivisualData]);

  // Update Data Function
  const onUpdateData = () => {
    if (props.indivisualData.hasOwnProperty("provider_client_contact_id")) {
      let filterdata = {
        provider_client_contact_id: addContactData.provider_client_contact_id,
        contact_title: addContactData.job_title,
        contact_first_name: addContactData.first_name,
        contact_last_name: addContactData.last_name,
        contact_phone: addContactData.phone,
      };
      dispatch(UpdateProviderClientContact(filterdata, "client"))
        .then((response) => {
          if (response.success) {
            props.CloseEditForm()
            dispatch(
              GetAllContactsOfProvider(
                {
                  organisation_id: OrgId,
                  page: 1,
                  type: "clientContact",
                  limit: 10,
                  sort: "created_date",
                  order: "desc",
                  search: "",
                },
                ["client"]
              )
            );
          } else if (response.status === 401) {
            handleUnauthorizedAccess()
          }
        })
        .catch((error) => {
          notify.show(error, "error");
        });
    } else {
      dispatch(UpdateProviderClientContact(addContactData))
        .then((response) => {
          if (response.success) {
            props.CloseEditForm()
            if(props.type =="internal"){
              dispatch(
                GetAllContactsOfProvider(
                  {
                    organisation_id: addContactData.organisation_id,
                    page: 1,
                    limit: 10,
                    type:"internal",
                    sort: "created_date",
                    order: "desc",
                    search: "",
                  },
                  ["all"]
                )
              );  
            }
            else{
              dispatch(
                GetAllContactsOfProvider(
                  {
                    organisation_id: addContactData.organisation_id,
                    page: 1,
                    limit: 10,
                    sort: "created_date",
                    order: "desc",
                    search: "",
                  },
                  ["all"]
                )
              );
            }
            

          } else if (response.status === 401) {
            handleUnauthorizedAccess()
          }
        })
        .catch((error) => {
          notify.show(error, "error");
        });
    }
  };
  // Form Update input
  const formHandle = (e) => {
    SetaddContactData((prevalue) => {
      if (props.indivisualData.hasOwnProperty("provider_client_contact_id")) {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      } else {
        if (e.target.name == "job_title") {
          return {
            ...prevalue,
            [e.target.name]: e.target.value,
          };
        } else {
          return {
            ...prevalue,
          };
        }
      }
    });
  };
  return (
    <React.Fragment>
      <form>
        <div className="mt-6 add-client-form px-6">
          <div className="flex justify-between flex-col">
            <div className="flex">
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Email address*
                </label>
                <ClientInput
                  value={addContactData.email}
                  type="email"
                  name="email"
                  inputchangehandler={formHandle}
                  placeholder="Enter Contact's email address"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  First name*
                </label>
                <ClientInput
                  type="text"
                  name="first_name"
                  inputchangehandler={formHandle}
                  value={addContactData.first_name}
                  placeholder="Enter Contact's first name"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Last name*
                </label>
                <ClientInput
                  value={addContactData.last_name}
                  type="text"
                  name="last_name"
                  inputchangehandler={formHandle}
                  placeholder="Enter Contact's last name"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Job title
                </label>
                <ClientInput
                  type="text"
                  value={addContactData.job_title}
                  name="job_title"
                  inputchangehandler={formHandle}
                  placeholder="Enter Contact's job role"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Phone number
                </label>
                <ClientInput
                  type="number"
                  name="phone"
                  inputchangehandler={formHandle}
                  value={addContactData.phone}
                  placeholder="Enter Contact's phone number"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight onClick={props?.onDialogClose}>Cancel</BtnLight>
          <BtnPrimary className="ml-1.5" onClick={() => onUpdateData()}>
            Update contact
          </BtnPrimary>
        </div>
      </form>
    </React.Fragment>
  );
};

export default EditContactForm;
