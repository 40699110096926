import React, { useCallback, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Card from "../../../common/element/Card";
import Heading from "../../../common/element/Heading";
import BtnPrimary from "../../../common/element/Buttons/btnPrimary";
import { Link, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import ThreeDotLoader from "../../../common/element/ButtonLoader/ThreeDotLoader";
import Notifications, { notify } from "react-notify-toast";
import { setAllFunction, setAllRole } from "../../../actions/index";
import Validations from "../../../common/404/Validations";
import * as userActions from "../../../actions/index";
import moment from "moment/moment";
import logoImage from "../../../assets/logo.svg";
import Loader from "../../../common/loader";
// import tooltipImg from "../../../assets/icons-svg/help-circle.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AllContactTypes } from "../../../redux/classes/createSlice";
import { UpdateCheckExistUser } from "../../../redux/classes/provider-portal/checkExistUser";
// const trust = JSON.parse(localStorage.getItem("TrustDeviceDate"))
// const trustDate = trust
const currentDate = new Date();
let GetAllFunctionLoginData;
export const Loginredirect = ({ redirectUrl }) => {
  const { checkExistUser } = useSelector((state) => state);
  const getUserPath = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [GetAllFunctionData, setGetAllFunctionData] = useState([]);
  const { allFunctions } = useSelector((state) => state);

  const GetAllFunctions = useCallback(async () => {
    dispatch(userActions.GetAllFunctions()).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        GetAllFunctionLoginData = arrayData;
        dispatch(setAllFunction(arrayData));
        GetAllRoleByFunctionId(GetAllFunctionLoginData);
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  const GetAllContactTypes = useCallback(async () => {
    dispatch(userActions.GetAllContactTypes()).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        dispatch(AllContactTypes(arrayData));
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  const GetAllRoleByFunctionId = useCallback(
    async (GetAllFunctionLoginData) => {
      if (GetAllFunctionLoginData) {
        let functionData = GetAllFunctionLoginData.filter(
          (item) => item?.function_name === "conserve-team"
        );
        let function_id = functionData[0]?.function_id;
        if (function_id) {
          dispatch(userActions.GetAllRoleByFunctionId(function_id)).then(
            (response) => {
              if (response?.success === true) {
                const arrayData = response?.data;
                dispatch(setAllRole(arrayData));
              } else {
              }
            }
          );
        }
      }

      // eslint-disable-next-line
    },
    [dispatch]
  );

  useEffect(() => {
    GetAllFunctions();
    GetAllRoleByFunctionId();
    GetAllContactTypes();
  }, []);
  const fetchAndSetAllFunctionsAndRoles = async () => {
    try {
      const response = await dispatch(userActions.GetAllFunctions());
      if (response?.success) {
        const functionsData = response?.data;
        setGetAllFunctionData(functionsData);
        dispatch(setAllFunction(functionsData));

        const functionData = functionsData?.find(
          (item) => item?.function_name === "conserve-team"
        );
        const functionId = functionData?.function_id;

        if (functionId) {
          const roleResponse = await dispatch(
            userActions.GetAllRoleByFunctionId(functionId)
          );
          if (roleResponse.success) {
            const rolesData = roleResponse.data;
            dispatch(setAllRole(rolesData));
          } else {
            console.error("Failed to get roles by function ID");
          }
        }
      } else {
        console.error("Failed to get all functions");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    fetchAndSetAllFunctionsAndRoles();
  }, [dispatch]);

  useEffect(() => {
    if (!redirectUrl) {
      getUserPath.current = "/2fa-verify";
    }
  }, [redirectUrl]);

  useEffect(() => {
    if (getUserPath.current) {
      if (checkExistUser.checkUser) {
        dispatch(
          UpdateCheckExistUser({
            checkUser: true,
            path: checkExistUser.path,
            login: true,
            checkAcceptUser: true,
          })
        );
        navigate(`${checkExistUser.path}`);
        
      } else {
        navigate(getUserPath.current);
      }
    }
  }, [navigate, getUserPath.current]);

  useEffect(() => {
    const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
    if (redirectUrl) {
      const docUrl = localStorage.getItem("DocUrl");
      if (docUrl) {
        getUserPath.current = docUrl;
        localStorage.removeItem("DocUrl");
      } else if (loginUserData?.userName?.is_conserve_team) {
        getUserPath.current = "/admin/users";
      } else if (loginUserData && loginUserData.functionId) {
        if (GetAllFunctionData.length > 0) {
          const userFunction = GetAllFunctionData.find(
            (item) => item?.function_id === loginUserData.functionId
          );
          if (userFunction) {
            const rolePathMap = {
              client: "/client/dashboard",
              provider: "/provider/dashboard",
              // worker: "/worker/dashboard"
            };
            const userRole = userFunction.function_name;
            const path = rolePathMap[userRole];
            if (path) {
              getUserPath.current = path;
            }
          }
        }
      }
      // only for worker
      else if (loginUserData?.userName?.user_data?.is_worker === true) {
        getUserPath.current = "/worker/dashboard";
      }
    }
  }, [redirectUrl, GetAllFunctionData, allFunctions, navigate, dispatch]);
};

const trustDevice = JSON.parse(localStorage.getItem("TrustDeviceDate"));

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenpassword: true,
      login_form: {
        email: {
          type: "email",
          label: "Email",
          placeholder: "Enter your email address",
          valid: true,
          error_msg: "",
          value: this?.props?.reject ? this?.props?.email : "",
          Validations: { required: true, email: true },
        },
        password: {
          type: "password",
          label: "password",
          placeholder: "Enter your password",
          valid: true,
          error_msg: "",
          value: "",
          Validations: { required: true, min_lenght: 8, password: true },
        },
        trustTwoFactorAuth: {
          type: "hidden",
          label: "remember",
          placeholder: "Remember for 30 days",
          valid: true,
          error_msg: "",
          value: false,
          // isChecked: false,
          Validations: { required: true },
        },
        remember: {
          type: "checkbox",
          label: "remember",
          placeholder: "Remember for 30 days",
          valid: true,
          error_msg: "",
          value: "",
          isChecked: false,
          Validations: { required: false },
        },
      },
      isLoading: false,
      isLoadingDots: false,
      error: "",
    };
  }

  togglePassword = () => {
    this.setState({ hiddenpassword: !this.state.hiddenpassword });
  };
  componentDidMount() {
    const update_login_form = { ...this.state.login_form };

    const days = JSON.parse(localStorage.getItem("remember30Days"));
    const rememberdays = days;
    let deviceremember = moment(currentDate)
      .subtract(30, "days")
      .format("YYYY-MM-DD");
    let checkDate = moment(deviceremember).isSameOrBefore(rememberdays, "day");

    if (checkDate === true) {
      const rememberUser = localStorage.getItem("rememberUser");
      const rememberEmail = localStorage.getItem("rememberEmail");
      const rememberPassword = localStorage.getItem("rememberPassword");

      update_login_form.remember.isChecked = rememberUser === "true";
      update_login_form.email.value = rememberEmail || "";
      update_login_form.password.value = rememberPassword || "";
      this.setState({ login_form: update_login_form });
    } else {
      localStorage.removeItem("rememberUser");
      localStorage.removeItem("remember30Days");
      localStorage.removeItem("rememberEmail");
      localStorage.removeItem("rememberPassword");
    }

    const trustEmail = trustDevice?.email;
    const trustDate = trustDevice?.currentData;
    if (trustDate && trustEmail === this.state.login_form.email.value) {
      let deviceTrust = moment(currentDate)
        .subtract(15, "days")
        .format("YYYY-MM-DD");

      let checkDate = moment(deviceTrust).isSameOrBefore(trustDate, "day");
      update_login_form.trustTwoFactorAuth.value = checkDate;
    } else {
      update_login_form.trustTwoFactorAuth.value = false;
    }
  }

  handleTrustDevice = () => {
    const trustDevice = JSON.parse(localStorage.getItem("TrustDeviceDate"));
    const update_login_form = { ...this.state.login_form };
    const trustEmail = trustDevice?.email;
    const trustDate = trustDevice?.currentData;
    if (trustDate && trustEmail === this.state.login_form.email.value) {
      let deviceTrust = moment(currentDate)
        .subtract(15, "days")
        .format("YYYY-MM-DD");
      let checkDate = moment(deviceTrust).isSameOrBefore(trustDate, "day");

      update_login_form.trustTwoFactorAuth.value = checkDate;
    } else {
      update_login_form.trustTwoFactorAuth.value = false;
    }
  };

  inputChangeHandler = (e, identifier) => {
    const update_login_form = { ...this.state.login_form };
    const form_element = { ...update_login_form[identifier] };

    if (identifier === "remember") {
      form_element.isChecked = e.target.checked;
      form_element.value = e.target.value;
    } else {
      form_element.value = e.target.value;
    }
    // check Validation
    let validate_element = Validations(form_element);
    form_element.valid = validate_element.valid;
    // form_element.error_msg = validate_element.error_msg;

    update_login_form[identifier] = form_element;
    let is_form_valid = true;
    for (let identifier in update_login_form) {
      is_form_valid = update_login_form[identifier].valid && is_form_valid;
    }

    this.setState({
      login_form: update_login_form,
      is_form_valid: is_form_valid,
    });
  };

  loginSubmitHandler = (event) => {
    event.preventDefault();

    let is_form_valid = true;

    const update_login_form = { ...this.state.login_form };

    for (let key in update_login_form) {
      let form_element = update_login_form[key];
      let validate_element = Validations(form_element);

      is_form_valid = validate_element.valid === false ? false : is_form_valid;
      form_element.valid = validate_element.valid;
      form_element.error_msg = validate_element.error_msg;

      update_login_form[key] = form_element;
    }
    this.setState({ login_form: update_login_form });

    if (is_form_valid) {
      const form_data = {};
      for (let key in this.state.login_form) {
        if (key === "remember") {
          if (this.state.login_form[key].isChecked === false) {
            break;
          }
        }

        if (key === "trustTwoFactorAuth") {
          if (this.state.login_form.trustTwoFactorAuth.value === true) {
            this.setState({ isLoading: true });
          }
        }
        form_data[key] = this.state.login_form[key].value;
      }

      this.setState({ isLoadingDots: true });
      this.props
        .onSignIn(form_data)
        .then((response) => {
          if (response?.success === true) {
            const email = this.state.login_form.email.value;
            const password = this.state.login_form.password.value;
            localStorage.setItem("login", JSON.stringify(email));
            //2-fa-verification
            let twoFA = response?.data?.userData?.user_data?.two_factor_auth;
            twoFA = twoFA == false ? twoFA : true;
            localStorage.setItem("two_factor_auth", twoFA);
            // Check if "Remember" checkbox is checked
            const remember = this.state.login_form.remember.isChecked;
            if (remember) {
              // Set the "rememberUser" flag in localStorage
              const current = new Date();
              const currentData = moment(current).format("YYYY-MM-DD");
              localStorage.setItem(
                "remember30Days",
                JSON.stringify(currentData)
              );
              localStorage.setItem("rememberUser", "true");
              localStorage.setItem("rememberEmail", email);
              localStorage.setItem("rememberPassword", password);
            } else {
              // Remove the "rememberUser" flag from localStorage
              localStorage.removeItem("rememberUser");
              localStorage.removeItem("remember30Days");
              localStorage.removeItem("rememberEmail");
              localStorage.removeItem("rememberPassword");
            }

            if (response?.data?.userData?.is_conserve_team == true) {
              if (
                this.state.login_form.trustTwoFactorAuth.value === true ||
                (this.state.login_form.trustTwoFactorAuth.value === false &&
                  twoFA == false)
              ) {
                notify?.show(response?.message, "success");

                if (response?.data?.userData.is_conserve_team) {
                  localStorage.setItem(
                    "LoginUserData",
                    JSON.stringify({
                      userName: response?.data?.userData,
                      data: response?.data?.data,
                    })
                  );
                  if (response?.data?.data) {
                    localStorage.setItem(
                      "permissionData",
                      JSON.stringify(response?.data?.data?.role)
                    );
                  }
                } else {
                  if (
                    response?.data?.userData.user_data.is_worker &&
                    response?.data?.data == ""
                  ) {
                    localStorage.setItem(
                      "LoginUserData",
                      JSON.stringify({ userName: response?.data?.userData })
                    );
                    localStorage.setItem(
                      "checkAuth",
                      JSON.stringify({ checkAuth: "worker" })
                    );

                    // if (localStorage.getItem("DocUrl")) {
                    //   navigate(localStorage.getItem("DocUrl"))
                    //   localStorage.getItem("DocUrl", "");
                    // } else {
                    //   navigate("/worker/dashboard");
                    // }
                  } else {
                    localStorage.setItem(
                      "LoginUserData",
                      JSON.stringify({
                        userName: response?.data?.userData,
                        functionId:
                          response?.data?.data?.user_perm_fa?.function_id,
                        function_assignment_id:
                          response?.data?.data?.function_assignment_id,
                        trading_name: response?.data?.data?.user_perm_fa
                          ?.org_data
                          ? response?.data?.data?.user_perm_fa?.org_data
                              ?.trading_name
                          : response?.data?.data?.user_perm_fa?.trading_name,
                        organisation_id:
                          response?.data?.data?.user_perm_fa?.org_data
                            ?.organisation_id,
                      })
                    );
                  }
                  localStorage.setItem(
                    "permission_data",
                    JSON.stringify(response?.data?.data)
                  );
                  const loginUserData = JSON.parse(
                    localStorage.getItem("LoginUserData")
                  );
                }
              } else {
                notify?.show(
                  `${response?.data?.split(" ")[0]} OTP emailed successfully!`,
                  "success"
                );
              }
            } else {
              if (
                this.state.login_form.trustTwoFactorAuth.value === true ||
                (this.state.login_form.trustTwoFactorAuth.value === false &&
                  twoFA == false)
              ) {
                notify?.show(response?.message, "success");

                if (
                  response?.data?.userData.user_data.is_worker &&
                  response?.data?.data == ""
                ) {
                  localStorage.setItem(
                    "LoginUserData",
                    JSON.stringify({ userName: response?.data?.userData })
                  );
                  localStorage.setItem(
                    "checkAuth",
                    JSON.stringify({ checkAuth: "worker" })
                  );
                  // if (localStorage.getItem("DocUrl")) {
                  //   navigate(localStorage.getItem("DocUrl"))
                  //   localStorage.getItem("DocUrl", "");
                  // } else {
                  //   navigate("/worker/dashboard");
                  // }
                } else {
                  localStorage.setItem(
                    "LoginUserData",
                    JSON.stringify({
                      userName: response?.data?.userData,
                      functionId:
                        response?.data?.data?.user_perm_fa?.function_id,
                      function_assignment_id:
                        response?.data?.data?.function_assignment_id,
                      trading_name: response?.data?.data?.user_perm_fa?.org_data
                        ? response?.data?.data?.user_perm_fa?.org_data
                            ?.trading_name
                        : response?.data?.data?.user_perm_fa?.trading_name,
                      organisation_id:
                        response?.data?.data?.user_perm_fa?.org_data
                          ?.organisation_id,
                    })
                  );

                  localStorage.setItem(
                    "permission_data",
                    JSON.stringify(response?.data?.data)
                  );
                  const loginUserData = JSON.parse(
                    localStorage.getItem("LoginUserData")
                  );
                }
              } else {
                notify?.show(
                  `${response?.data?.split(" ")[0]} OTP emailed successfully!`,
                  "success"
                );
              }
            }
            // window.location.href = "/2FA";
            if (response?.data?.userData?.is_conserve_team == true) {
              if (
                this.state.login_form.trustTwoFactorAuth.value === true ||
                (this.state.login_form.trustTwoFactorAuth.value === false &&
                  twoFA == false)
              ) {
                this.setState({
                  isAuthenticated: true,
                  redirect_url: true,
                });
              } else {
                this.setState({
                  isAuthenticated: true,
                  redirect_url: false,
                });
              }
            } else {
              if (
                this.state.login_form.trustTwoFactorAuth.value === true ||
                (this.state.login_form.trustTwoFactorAuth.value === false &&
                  twoFA == false)
              ) {
                this.setState({
                  isAuthenticated: true,
                  redirect_url: true,
                });
              } else {
                this.setState({
                  isAuthenticated: true,
                  redirect_url: false,
                });
              }
            }

            //Loading False
            this.setState({ isLoading: false, isLoadingDots: false });
          } else {
            notify?.show(response?.response?.data?.message, "error");
            // Loading False
            this.setState({ isLoading: false, isLoadingDots: false });
          }
        })
        .catch((error) => {
          // Handle the error here
          console.error("Sign-in error:", error);
          notify?.show(
            "An error occurred during sign-in. Please try again later.",
            "error"
          );
          // Loading False
          this.setState({ isLoading: false, isLoadingDots: false });
        });
    }
  };

  render() {
    const { login_form } = this.state;
    const { isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ? (
          <Loader />
        ) : (
          <div
            style={{ backgroundColor: `#eff8ff` }}
            className="flex justify-center min-h-screen"
          >
            {this.state.isAuthenticated ? (
              <Loginredirect redirectUrl={this.state.redirect_url} />
            ) : null}
            <div className="min-h-screen justify-center flex  py-12 px-6">
              <div className="flex-col items-center justify-center flex">
                <div className="flex items-center mb-6">
                  <img className="brand-img" src={logoImage} alt="conserve" />
                </div>
                <Heading className="text-center mb-3">
                  Log in to your account
                </Heading>
                <p className="text-base text-gray-600 text-center mb-6 font-medium">
                  Welcome back! Please enter your details.
                </p>
                <Card className="w-500 rounded-xl   block-shadow w-100 mt-2 px-10 py-8">
                  <div className="w-full">
                    <form onSubmit={this.loginSubmitHandler}>
                      <div className="mb-5">
                        <label
                          className="block text-gray-700  mb-2 text-xs font-medium"
                          htmlFor="Email"
                        >
                          Email
                        </label>
                        <div className="input-wrapper">
                          <input
                            className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type={login_form.type}
                            placeholder={login_form.email.placeholder}
                            disabled={this?.props?.reject}
                            value={
                              this?.props?.reject
                                ? this?.props?.email
                                : login_form.email.value
                            }
                            onChange={(e) =>
                              this.inputChangeHandler(e, "email")
                            }
                            onBlur={this.handleTrustDevice()}
                          />
                          <div className="input-suffix-start right-set">
                            <span className="cursor-pointer">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_8475_89398)">
                                  <path
                                    d="M6.06016 5.99967C6.2169 5.55412 6.52626 5.17841 6.93347 4.9391C7.34067 4.69978 7.81943 4.6123 8.28495 4.69215C8.75047 4.772 9.17271 5.01402 9.47688 5.37536C9.78106 5.7367 9.94753 6.19402 9.94683 6.66634C9.94683 7.99967 7.94683 8.66634 7.94683 8.66634M8.00016 11.333H8.00683M14.6668 7.99967C14.6668 11.6816 11.6821 14.6663 8.00016 14.6663C4.31826 14.6663 1.3335 11.6816 1.3335 7.99967C1.3335 4.31778 4.31826 1.33301 8.00016 1.33301C11.6821 1.33301 14.6668 4.31778 14.6668 7.99967Z"
                                    stroke="#98A2B3"
                                    stroke-width="1.33333"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_8475_89398">
                                    <rect width="16" height="16" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </div>
                        </div>
                        {!login_form.email.valid ? (
                          <div className="error field-error text-red-600">
                            {login_form.email.error_msg}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="mb-6">
                        <label
                          className="block text-gray-700  mb-2 text-xs font-medium"
                          htmlFor="password"
                        >
                          {" "}
                          Password{" "}
                        </label>
                        <div className="input-wrapper">
                          <input
                            className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type={
                              this.state.hiddenpassword ? "password" : "text"
                            }
                            placeholder="••••••••"
                            value={login_form.password.value}
                            onChange={(e) =>
                              this.inputChangeHandler(e, "password")
                            }
                            onBlur={this.handleTrustDevice()}
                          />
                          <div className="input-suffix-start right-set">
                            <span
                              className="gray-svg cursor-pointer"
                              onClick={this.togglePassword}
                            >
                              {this.state.hiddenpassword ? (
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                  height="21"
                                  width="20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                                    stroke="#98A2B3"
                                    strokeWidth="1.667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                    stroke="#98A2B3"
                                    strokeWidth="1.667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </span>
                          </div>
                        </div>
                        {!login_form.password.valid ? (
                          <div className="error field-error text-red-600">
                            {login_form.password.error_msg}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="flex items-center mb-6 justify-between">
                        <label className="block">
                          <input
                            className="w-4 h-4 mr-2 border border-gray-300 rounded bg-gray-50 focus:ring-0 focus:ring-blue-300 darks:bg-gray-700 darks:border-gray-600 darks:focus:ring-blue-600 darks:ring-offset-gray-800"
                            type={login_form.remember.type}
                            placeholder={login_form.remember.placeholder}
                            value={login_form.remember.value}
                            checked={
                              login_form.remember.isChecked
                                ? login_form.remember.isChecked
                                : ""
                            }
                            onChange={(e) =>
                              this.inputChangeHandler(e, "remember")
                            }
                          />
                          <span className="text-xs text-gray-700 font-medium">
                            Remember for 30 days
                          </span>
                        </label>
                        {this.state.isLoadingDots == false ? (
                          <Link
                            to="/forgot-password"
                            className="inline-block font-semibold align-baseline text-xs text-blue-700 hover:text-blue-900 focus:text-blue-700 "
                            href="#"
                          >
                            Forgot Password
                          </Link>
                        ) : (
                          <Link
                            className={`${
                              this.state.isLoadingDots == true &&
                              "cursor-not-allowed opacity-50 inline-block font-semibold align-baseline text-xs text-blue-700 hover:text-blue-900 focus:text-blue-700"
                            }`}
                          >
                            Forgot Password
                          </Link>
                        )}
                      </div>

                      <div className="flex items-center justify-between w-100">
                        <BtnPrimary
                          disabled={this.state.isLoadingDots}
                          type="submit"
                        >
                          {this.state.isLoadingDots ? (
                            <ThreeDotLoader />
                          ) : (
                            "Sign in"
                          )}
                        </BtnPrimary>
                      </div>
                    </form>
                  </div>
                </Card>
                <div className=" mt-6 px-10 w-full">
                  <Card className="p-2 light-shadow rounded-lg text-center w-full">
                    {this.state.isLoadingDots == false ? (
                      <Link
                        to="/sign-up"
                        className="text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600"
                      >
                        Register as a Provider
                      </Link>
                    ) : (
                      <Link
                        className={`${
                          this.state.isLoadingDots == true &&
                          "cursor-not-allowed opacity-50 text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600"
                        }`}
                      >
                        Register as a Provider
                      </Link>
                    )}
                  </Card>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: userActions.SignIn.loader,
    // error: state.SignIn.error,
  };
}
const mapDispatchToProps = {
  onSignIn: userActions.SignIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
