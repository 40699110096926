import React, { useEffect, useState, useCallback } from "react";
import Heading from "../../../../../common/element/Heading";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../common/element/Select";
import ViewAllList from "./ViewAllList";
import Dialog from "../../../../../common/element/Dialog";
import Input from "../../../../../common/element/Input";
import Label from "../../../../../common/element/Label";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../../../common/element/Dropdown";
import * as userActions from "../../../../../actions/index";
import {
  GetAllProviderList,
  // GetAllClientList,
  InviteSpecificProvider,
} from "../../../../../actions/admin-provider/AllInviteContractorApi";
import { GetAllClientList } from "../../../../../actions/admin-provider/GetAllClientList";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import Validations from "../../../../../common/404/Validations";
import Upload from "../../../../../common/element/Upload";
import RichTextEditor from "../../../../../common/snippet/RichTextEditor";

import { GetAllComplianceOfClientList } from "../../../../../actions/admin-provider/GetAllComplianceOfClientList";
import { GetAllProviderTypesList } from "../../../../../actions/admin-provider/GetAllProviderTypesList";
import { GetAllProvidersPrimaryList } from "../../../../../actions/index";
import { email } from "../../../../../actions/Auth";
import { GetAllClientContacts } from "../../../../../actions/client-portal/AllContactsApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { debounce } from "../../../../../common/snippet/Debouncing";
import searchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import { Pagination } from "../../../../../common/pagination";
import ReactPaginate from "react-paginate";
import { GetAllProviders } from "../../../../../actions/admin-provider/worker/GetAllProviders";
import Button from "../../../../../common/element/Buttons";
import { ViewClientCsv } from "../../../../../actions/admin-provider/clientCsvView";
import { inviteProviderCSVView } from "../../../../../actions/admin-provider/inviteProviderCSVView";
import { CSVLink } from "react-csv";
import Scrollbar from "../../../../../common/element/ScrollBar";
import Table from "../../../../../common/element/Table";
import THead from "../../../../../common/element/Table/THead";
import Tr from "../../../../../common/element/Table/Tr";
import Th from "../../../../../common/element/Table/Th";
import TBody from "../../../../../common/element/Table/TBody";
import Td from "../../../../../common/element/Table/Td";
import Checkbox from "../../../../../common/element/Checkbox";
import ScrollBar from "../../../../../common/element/ScrollBar/ScrollBar";
import { AddSpecificProviderData } from "../../../../../redux/classes/AdminContractor/SpecificProvider";
const ContractorViewAll = (props) => {
  const { SpecificProviderData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Provider",
          url: "/admin/provider/view-all-provider",
          home: "/admin/provider/dashboard",
        },
      ])
    );
  }, []);

  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [dialogChoose, setChoose] = useState(false);
  const [dialogInviteSent, setDialogInviteSent] = useState(false);
  const [dialogAlreadyOpen, setCloseAlreadyExist] = useState(false);
  const [searchProviderOptions, setSearchOptions] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [selectedClientType, setSelectedClientType] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [searchOptions, setSelectOptions] = useState([]);
  const [pageOne, setPageOne] = useState(false);
  const [fileData, setFileData] = useState(null);

  const [allClient, setAllClient] = useState("");
  const [contact_type_id, setcontact_type_id] = useState("");
  const [function_id, setfunction_id] = useState("");
  const [abnEditOrNot, setAbnEditOrNot] = useState(false);
  // states for csv
  const [workerSelCompChecklist, setWorkerSelCompChecklist] = useState([]);
  const [workerAllCheckListData, setWorkerAllCheckListData] = useState([]);
  const [finalCsvData, setfinalCsvData] = useState([]);
  const [workerCheckListError, setWorkerChecklistError] = useState("");
  const [documentError, setdocumentError] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [checkListError, setChecklistError] = useState("");
  const [allCheckListData, setAllCheckListData] = useState([]);
  const [dialogOpenCSV, setCSV] = useState(false);
  const [updateCsvData, setUpdateCsvData] = useState(false);
  const [selCompChecklist, setSelCompChecklist] = useState([]);
  const [dialogNextCsv, setDialogNextCsv] = useState(false);
  const [dialogClientChoose, SetdialogClientChoose] = useState(false);
  const [CsvFileData, SetCsvFileData] = useState({
    provider_type_id: "",
    invite_attach: [],
    invite_message: "",
  });

  const [formData, setFromData] = useState({
    email: "",
    inputEmail: "",
    abn_nzbn: "",
    message: "",
    search: "",
    name: "",
    individual_id: "",
    last_name: "",
    first_name: "",
  });

  const [formError, setFromError] = useState({
    email: "",
    abn_nzbn: "",
    name: "",
  });
  const [requiredField, setRequireField] = useState({
    clientNameError: "",
    providerNameError: "",
    first_name: "",
    last_name: "",
    conserveEmailReq: "",
    correctFormat: "",
  });

  const [clientData, setClientData] = useState({
    name: "",
    organisation_id: "",
    function_assignment_id: SpecificProviderData?.provider_fa_id,
  });

  const [contractorFAId, setContractorFAId] = useState("");
  const [attachment, setAttachment] = useState({ files: [] });
  const [message, setMessage] = useState("");
  const [conserveEmail, setConserveEmail] = useState("");
  const Allfunction = useSelector((state) => state?.allFunctions?.allFunction);
  const AllContactType = useSelector((state) => state?.AllContactTypes);
  const clearFormData = () => {
    setFromData((prevVal) => ({
      ...prevVal,
      email: "",
      inputEmail: "",
      abn_nzbn: "",
      message: "",
      search: "",
      name: "",
      first_name: "",
      last_name: "",
      individual_id: "",
      organisation_id: "", // Assuming this should be cleared too
      function_assignment_id: SpecificProviderData?.provider_fa_id,
    }));

    setSelectedOption("");
    setSelectedClientType("");
    setSelectOptions([]);
    setSelectedDocumentType("");
    setAttachment({ files: [] });
    setMessage("");
    setcontact_type_id("");
    setRequireField((prevVal) => ({
      ...prevVal,
      first_name: "",
      last_name: "",
      conserveEmailReq: "",
      correctFormat: "",
    }));
  };

  const isSearchClear = () => {
    setFromError((prevError) => ({ ...prevError, email: "" }));
    setFromData((prevState) => ({
      ...prevState,
      first_name: "",
      last_name: "",
      email: "",
      individual_id: "",
    }));
    setRequireField((prevError) => ({
      ...prevError,
      correctFormat: "",
    }));
  };
  const clearFormError = () => {
    setFromData((prevVal) => ({
      ...prevVal,
      email: "",
      abn_nzbn: "",
      name: "",
    }));
  };

  function isEmail(value) {
    // Regular expression for checking email format
    if (value !== undefined && value !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    }
  }

  function isElevenDigitNumber(value) {
    // Remove spaces and check if it's 11 digits
    if (value !== undefined && value !== "") {
      const strippedValue = value.replace(/\s/g, "");
      const numberRegex = /^\d{11}$/;
      return numberRegex.test(strippedValue);
    }
  }
  const handleABN = () => {
    let newValue = formData?.abn_nzbn?.replace(/\D/g, ""); // Remove non-digit characters
    const length = newValue?.length;
    if (length <= 11) {
      newValue = `${newValue.slice(0, 2)} ${newValue.slice(
        2,
        5
      )} ${newValue.slice(5, 8)} ${newValue.slice(8, 11)}`;
    }
    setFromData((prevVal) => ({ ...prevVal, abn_nzbn: newValue }));
  };
  // // Get ProviderList
  const fetchProviderList = useCallback(() => {
    dispatch(GetAllProviderTypesList()).then((response) => {
      const filterData = response?.data?.map((item) => ({
        value: item.provider_type_id,
        label: item.provider_type_name,
      }));
      setSearchOptions(filterData);
    });
  }, [dispatch, setSearchOptions]);

  useEffect(() => {
    fetchProviderList();
  }, []);

  const selectDocType = (selectedOption) => {
    setSelectedDocumentType(selectedOption);
    setRequireField((prevError) => ({
      ...prevError,
      providerNameError: "",
    }));
  };
  const selectionClient = (selectedOption) => {
    setSelectedClientType(selectedOption);
    setRequireField((prevError) => ({
      ...prevError,
      clientNameError: "",
    }));
  };
  const openChoose = () => {
    setChoose(true);
    setSelectedOption("");
    setSelectedClientType("");
    setSelectOptions([]);
    setSelectedDocumentType("");
    clearFormError();
    setRequireField((prevError) => ({
      ...prevError,
      providerNameError: "",
      clientNameError: "",
      conserveEmailReq: "",
    }));
    setFromError((prevFormErrors) => ({
      ...prevFormErrors,
      name: "",
      email: "",
      abn_nzbn: "",
    }));
  };
  const onChooseClose = (e) => {
    setChoose(false);
  };
  const onDialogAlreadyClose = () => {
    setCloseAlreadyExist(false);
  };
  const OnCloseinviteProvider = () => {
    setDialogInviteSent(false);
  };
  const openDialog = () => {
    const inputValue = selectedOption?.value;
    let emailValidation;
    let abnValidation;
    let correctFormatVal;
    if (isEmail(selectedOption?.label)) {
      emailValidation = Validations({
        value: inputValue,
        Validations: { email: true, required: true },
      });
      if (!emailValidation.valid) {
        setFormError("email", emailValidation.error_msg);
      }
    } else if (isElevenDigitNumber(selectedOption?.label)) {
      abnValidation = Validations({
        value: inputValue,
        Validations: { required: true, ValidateABN: true },
      });
      if (!abnValidation.valid) {
        setRequireField("abn_nzbn", abnValidation.error_msg);
      }
    } else {
      correctFormatVal = Validations({
        value: inputValue,
        Validations: { email: true, required: true },
      });
      if (!correctFormatVal.valid) {
        setRequireField((prevError) => ({
          ...prevError,
          correctFormat:
            inputValue == "" || inputValue == undefined
              ? "This field is required!"
              : "Please enter the value in the correct format",
        }));
      }
    }
    const clientNameValidation = Validations({
      value: selectedClientType,
      Validations: { required: true },
    });
    if (!clientNameValidation.valid) {
      setRequireField((prevErrors) => ({
        ...prevErrors,
        clientNameError: clientNameValidation.error_msg,
      }));
    }

    const providerNameValidation = Validations({
      value: selectedDocumentType,
      Validations: { required: true },
    });
    if (!providerNameValidation.valid) {
      setRequireField((prevErrors) => ({
        ...prevErrors,
        providerNameError: providerNameValidation.error_msg,
      }));
    }
    // setFormError("name", '');

    if (
      (emailValidation?.valid === true || abnValidation?.valid === true) &&
      requiredField?.conserveEmailReq == "" &&
      clientNameValidation?.valid &&
      providerNameValidation?.valid &&
      requiredField?.correctFormat === ""
    ) {
      setIsOpen(true);
      setChoose(false);
      if (formData.abn_nzbn !== "") {
        setAbnEditOrNot(true);
      }
    }
    if (emailValidation?.valid) {
      setFromData((prevVal) => ({
        ...prevVal,
        email: inputValue,
        inputEmail: true,
      }));
      setFormError("abn_nzbn", "");
      setPageOne(true);
    } else if (abnValidation?.valid) {
      setFromData((prevVal) => ({
        ...prevVal,
        abn_nzbn: inputValue,
        email: "",
      }));
      setFormError("email", "");
      setSelectedOption("");
      setPageOne(false);
    }
  };
  const onDialogClose = (e) => {
    setWorkerChecklistError("");
    setChecklistError("");
    setIsOpen(false);
    clearFormData();
    clearFormError();
    setAbnEditOrNot(false);
    setWorkerSelCompChecklist([]);
    setSelCompChecklist([]);
  };

  const openAddDialog = () => {
    setAddOpen(true);
    setIsOpen(false);
  };
  const onAddDialogClose = (e) => {
    setAddOpen(false);
  };

  useEffect(() => {
    let functionId;
    if (Allfunction) {
      // functionID if function name is provider
      const functionID = Allfunction.find(
        (item) => item.function_name === "client"
      )?.function_id;
      functionId = functionID;
    }

    dispatch(GetAllClientList({ function_id: functionId }))
      .then((response) => {
        if (response.success) {
          const arrayData = response.data;
          let client_data = [];
          for (let key in arrayData) {
            client_data.push({
              label: arrayData[key].trading_name,
              value: arrayData[key].organisation_id,
              organisation_id: arrayData[key].organisation_id,
              function_assignment_id: arrayData[key].function_assignment_id,
            });
          }
          const listData = [];
          if (client_data) {
            client_data.map((item) => {
              if (item.function_assignment_id) {
                listData.push(item);
              }
            });
          }
          setAllClient(listData);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {});
    // }
  }, [Allfunction]);

  const handleClientChange = (selectedOption) => {
    setClientData((prevFormData) => ({
      ...prevFormData,
      name: selectedOption.label,
      function_assignment_id: selectedOption.function_assignment_id,
      organisation_id: selectedOption.organisation_id,
    }));
  };

  useEffect(() => {
    if (AllContactType && Allfunction) {
      // contactTypeId if contact is provider primary
      const contactTypeId = AllContactType.find(
        (item) => item.contact_type === "provider primary"
      )?.contact_type_id;

      // functionID if function name is provider
      const functionID = Allfunction.find(
        (item) => item.function_name === "provider"
      )?.function_id;
      setfunction_id(functionID);
      setcontact_type_id(contactTypeId);
    }

    if (AllContactType && Allfunction) {
      if (formData.search?.length > 3) {
        dispatch(
          GetAllProvidersPrimaryList(
            function_id,
            contact_type_id,
            selectedClientType?.function_assignment_id,
            formData.search
          )
        ).then((response) => {
          if (response.success === true) {
            const arrayData = response.data;
            const contactorData = arrayData.map((data) => ({
              ContractorOrgName: data.trading_name,
              ContractorFAId: data.function_assignment_id,
              abn: data.abn_nzbn,
              functionId: data.function_id,
              individualId: data.individual_id,
              email: data.email,
              relationId: data.f_a_relation_id,
              ContractorFirstName: data.first_name,
              ContractorLastName: data.last_name,
              provider_type_name: data?.provider_type_name,
            }));

            // const listData = contactorData.filter((item) => !item.relationId);
            if (formData?.abn_nzbn === "") {
              setFromData((prvState) => ({
                ...prvState,
                abn_nzbn:
                  contactorData?.[0]?.abn != undefined
                    ? contactorData?.[0]?.abn
                    : "",
              }));
            }
            if (
              contactorData?.[0]?.ContractorFirstName ||
              contactorData?.[0]?.ContractorLastName
            ) {
              setRequireField((prevError) => ({
                ...prevError,
                first_name: contactorData?.[0]?.ContractorFirstName
                  ? ""
                  : prevError.first_name,
                last_name: contactorData?.[0]?.ContractorLastName
                  ? ""
                  : prevError.last_name,
              }));
            }

            setFromData((prvState) => ({
              ...prvState,
              individual_id: contactorData[0]?.individualId
                ? contactorData[0]?.individualId
                : "",
            }));
            setSelectOptions(contactorData);

            const checkByEmail = contactorData.find(
              (item) => item.email === formData.search
            );

            if (checkByEmail?.relationId) {
              setFormError((prevError) => ({
                ...prevError,
                email:
                  "This email cannot be selected because it already exists as a provider for this client",
              }));
              // This is not working in first step because relation UID is null
              // so this sistutaion handle in next step when user submit or "Invite Provider"
            } else {
              setFormError((prevError) => ({
                ...prevError,
                email: "",
              }));
            }
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        });
      }
    }
  }, [Allfunction, AllContactType, formData.search]);

  // Custom option for the input value for email/abn for 1st page & email for 2nd page
  const customOption = { value: formData.search, label: formData.search };
  // Check if the inputValue exists in options, if not, add it
  const updatedOptions = searchOptions?.find(
    (option) => option.value === formData.search
  )
    ? searchOptions
    : [...searchOptions, customOption];

  // function for Select tag for email/abn for 1st page & email for 2nd page
  const handleSearchChange = (newInputValue) => {
    // setSearch(newInputValue);
    setFromData((prevVal) => ({
      ...prevVal,
      search: newInputValue,
    }));
    setFromError((prevError) => ({
      ...prevError,
      email: "",
    }));
    setRequireField((prevError) => ({
      ...prevError,
      correctFormat: "",
    }));
  };

  // Handle the select option change for email/abn for 1st page & email for 2nd page
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (isEmail(selectedOption?.label)) {
      setFromData((prevVal) => ({ ...prevVal, email: selectedOption?.label }));
    } else if (isElevenDigitNumber(selectedOption?.label)) {
      setFromData((prevVal) => ({
        ...prevVal,
        abn_nzbn: selectedOption?.label,
      }));
    } else {
      // show message
      //Please Enter the Value in correct format
    }
    setRequireField((prevError) => ({
      ...prevError,
      conserveEmailReq: "",
    }));

    setFromError((prevError) => ({
      ...prevError,
      abn_nzbn: "",
    }));
    GetIndividualByEmail(selectedOption?.label); // Pass selected email to GetIndividualByEmail
  };

  const GetIndividualByEmail = async (email) => {
    if (email) {
      try {
        const response = await props.onGetIndividualByEmail(email);
        if (response.success === true) {
          const arrayData = response?.data;

          if (arrayData && arrayData?.is_conserve_team === false) {
            setFromData((prevVal) => ({
              ...prevVal,
              email: arrayData?.email,
              individual_id: arrayData?.individual_id,
              first_name: arrayData?.first_name,
              last_name: arrayData?.last_name,
            }));

            setContractorFAId(arrayData?.function_assignment_id);
            if (arrayData?.first_name || arrayData?.last_name) {
              setRequireField((prevError) => ({
                ...prevError,
                first_name: arrayData?.first_name ? "" : prevError.first_name,
                last_name: arrayData?.last_name ? "" : prevError.last_name,
              }));
            }
            if (arrayData?.abn && formData.abn_nzbn === "") {
              setFromData((prevVal) => ({
                ...prevVal,
                abn_nzbn: arrayData?.abn ? arrayData?.abn : "",
              }));
            }
          } else if (arrayData && arrayData?.is_conserve_team === true) {
            setRequireField((prevError) => ({
              ...prevError,
              conserveEmailReq: "This email can not be selected",
            }));
          }
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      } catch (error) {
        console.error(
          "Error occurred while fetching individual by email:",
          error
        );
        // Handle error if necessary
      }
    }
  };

  // to handle input change
  const inputchangehandler = (e, field) => {
    setFromData((prevVal) => ({
      ...prevVal,
      [field]: e,
    }));
    if (field == "first_name" || field == "last_name") {
      setRequireField((prevError) => ({
        ...prevError,
        [field]: "",
      }));
    }
    if (field === "abn_nzbn") {
      setFormError("abn_nzbn", "");
    }
  };

  const handleFileChange = (files) => {
    setAttachment({ files });
  };

  const handleFileRemove = (files) => {
    setAttachment({ files });
  };

  const setFormError = (field, message) => {
    setFromError((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment.files.forEach((item) => {
      if (item.size) {
        totalSize += item?.size;
      }
    });
    return totalSize;
  };

  const InviteContractor = (e) => {
    let emailValidation;
    let correctFormatVal;
    if (formData?.email?.trim() === "" || !selectedOption?.label) {
      emailValidation = Validations({
        value: formData?.email,
        Validations: { email: true, required: true },
      });
      setFormError(
        "email",
        formData?.email?.trim() === ""
          ? "This field is required!"
          : "Please enter the value in the correct format"
      );
    }
    if (isEmail(selectedOption?.label)) {
      emailValidation = Validations({
        value: formData?.email,
        Validations: { email: true, required: true },
      });
      if (!emailValidation?.valid) {
        setFormError("email", emailValidation?.error_msg);
      }
    }
    correctFormatVal = Validations({
      value: selectedOption?.label,
      Validations: { email: true, required: true },
    });
    if (!correctFormatVal?.valid) {
      setRequireField((prevError) => ({
        ...prevError,
        correctFormat:
          selectedOption?.label == "" || selectedOption?.label == undefined
            ? "This field is required!"
            : "Please enter the value in the correct format",
      }));
    }

    if (formError.email === "") {
      const nameValidation = Validations({
        value: formData.name,
        Validations: { required: true },
      });
      const abnValidation = Validations({
        value: formData.abn_nzbn,
        Validations: { required: true, ValidateABN: true },
      });
      const firstNameValidation = Validations({
        value: searchOptions[0]?.ContractorFirstName
          ? searchOptions[0]?.ContractorFirstName
          : formData?.first_name,
        Validations: { required: true },
      });
      if (!firstNameValidation.valid) {
        setRequireField((prevErrors) => ({
          ...prevErrors,
          first_name: firstNameValidation.error_msg,
        }));
      }
      const lastNameValidation = Validations({
        value: searchOptions[0]?.ContractorLastName
          ? searchOptions[0]?.ContractorLastName
          : formData?.last_name,
        Validations: { required: true },
      });
      if (!lastNameValidation.valid) {
        setRequireField((prevErrors) => ({
          ...prevErrors,
          last_name: lastNameValidation.error_msg,
        }));
      }
      if (!nameValidation.valid) {
        setFormError("name", nameValidation.error_msg);
      }
      if (!abnValidation.valid) {
        if (abnValidation.error_msg != "This field is required!") {
          setFormError("abn_nzbn", abnValidation.error_msg);
        }
      }

      const totalSize = calculateTotalAttachmentSize();
      if (totalSize > 25 * 1024 * 1024) {
        return;
      }
      const CheckListValidation = Validations({
        value: selCompChecklist?.length == 0 ? "" : selCompChecklist,
        Validations: { required: true },
      });
      if (!CheckListValidation?.valid) {
        setChecklistError(CheckListValidation?.error_msg);
      }
      const WorkerCheckListValidation = Validations({
        value:
          workerSelCompChecklist?.length == 0 ? "" : workerSelCompChecklist,
        Validations: { required: true },
      });
      if (!WorkerCheckListValidation?.valid) {
        setWorkerChecklistError(WorkerCheckListValidation?.error_msg);
      }

      if (
        requiredField?.correctFormat === "" &&
        requiredField?.conserveEmailReq == "" &&
        emailValidation?.valid === true &&
        abnValidation?.error_msg != "Please enter valid ABN/NZBN" &&
        firstNameValidation?.valid &&
        lastNameValidation?.valid &&
        CheckListValidation?.valid &&
        WorkerCheckListValidation?.valid
      ) {
        const workerChecllistIds = workerSelCompChecklist?.map(
          (item) => item?.value
        );
        const payload = {
          individual_id: formData.individual_id
            ? formData.individual_id
            : "",
          provider_fa_id: contractorFAId ? contractorFAId : "",
          // provider_org_name: formData.name,
          abn_nzbn: formData.abn_nzbn,
          email: formData.email,
          invite_message: message,
          client_fa_id: selectedClientType?.function_assignment_id,
          client_org_id: selectedClientType.value,
          trading_name: selectedClientType?.label,
          invite_attach: attachment,
          first_name: searchOptions[0]?.ContractorFirstName
            ? searchOptions[0]?.ContractorFirstName
            : formData?.first_name,
          last_name: searchOptions[0]?.ContractorLastName
            ? searchOptions[0]?.ContractorLastName
            : formData?.last_name,
          provider_type_id: searchOptions[0]?.provider_type_name
            ? UpdateData[0]?.value
            : selectedDocumentType?.value,
          client_type_id: selectedClientType?.value,
          checklistIds: JSON.stringify(
            selCompChecklist?.map((item) => item?.value)
          ),
          workerChecklistIds: JSON.stringify(
            workerChecllistIds.length ? workerChecllistIds : []
          ),
        };

        dispatch(InviteSpecificProvider(payload))
          .then((response) => {
            if (response.success) {
              // setdialogAddOpen(true);
              onDialogClose();
              setDialogInviteSent(true);
              setIsOpen(false);
              props.userSettingHide();
            }
            if (response.status == 409) {
              setCloseAlreadyExist(true);
              onDialogClose();
              setIsOpen(false);
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          .catch((error) => {});
      }
    }
  };
  //  selectedDocumentType
  // searchProviderOptions

  // 2154c2db-4ca5-4319-be60-0be6e9c0ec00
  let UpdateData = searchProviderOptions?.filter(
    (item) => item?.label == searchOptions[0]?.provider_type_name
  );

  // Filter Code
  const [countData, setCountData] = useState(0);
  const [apiCalls, setApiCalls] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [providersData, setProviderData] = useState("");
  const [filterData, setFilterData] = useState({
    sort: "created_date",
    order: "desc",
    page: 1,
    limit: 10,
    search: "",
    function_id: "",
  });

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Provider",
          url: "provider/view-all-provider",
          home: "provider/dashboard",
        },
      ])
    );
  }, []);

  const handlePageChange = ({ selected }) => {
    setFilterData({
      ...filterData,
      page: selected + 1,
    });
    // GetAllClientContacts(selected + 1, filterlimit);
  };
  const handleSeachChange = (e) => {
    setFilterData({
      ...filterData,
      search: e.target.value.trim(),
      page: 1,
    });
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const fetchProviders = useCallback(() => {
    let functionId;
    if (Allfunction) {
      // functionID if function name is provider
      const functionID = Allfunction.find(
        (item) => item.function_name === "provider"
      )?.function_id;
      functionId = functionID;
    }
    let payload = { ...filterData, function_id: functionId };

    if (functionId) {
      dispatch(GetAllProviders(payload)).then((response) => {
        if (response.success) {
          const arrayData = response.data.rows;
          setCountData(response.data.count);
          setProviderData(arrayData);
          setTotalPages(Math.ceil(response.data.count / filterData.limit));
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      });
    }
  }, [dispatch, filterData]);

  useEffect(() => {
    fetchProviders();
  }, [filterData]);

  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      setFilterData((prevState) => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
      }));
    }
  };
  const handleSort = (column) => {
    if (column === filterData.sort) {
      // Toggle the sorting order if the same column is clicked
      setFilterData({
        ...filterData,
        order: filterData.order === "asc" ? "desc" : "asc",
      });
    } else {
      setFilterData({
        ...filterData,
        sort: column,
        order: "asc", // Set the default order when changing the sorting column
        page: 1, // Reset page when changing the sorting column
      });
    }
  };

  const GetFunction = useSelector((state) => state);
  useEffect(() => {
    if (GetFunction.inviteProviderCsvData?.data?.data?.success) {
      if (updateCsvData) {
        dialogCloseCSV();
        onChooseClientClose();
        setAddOpen(true);
        // dispatch(reSetData());
        setCsvData([]);
      }
    } else {
      if (
        !GetFunction?.inviteProviderCsvData?.loading &&
        GetFunction?.inviteProviderCsvData.data?.data !== undefined
      ) {
        setCsvData(GetFunction.inviteProviderCsvData.data?.data);
        let filterdata = GetFunction?.inviteProviderCsvData?.data?.data?.filter(
          (items) => items?.is_validated == true
        );
        // Now, create a new filtered array that excludes the 'action' and 'invite_id' properties
        if (filterdata) {
          const filteredData = filterdata.map((item) => {
            // Create a new object with only the desired properties
            const { action, is_validated, invite_id, ...filteredItem } = item;
            return filteredItem;
          });

          setfinalCsvData(filteredData);
        } else {
          setfinalCsvData([]);
        }
      }
    }
  }, [GetFunction]);

  // CSV Code
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_id;
  let current_user;
  if (loginData) {
    role_assignment_id = loginData?.data?.role_assignment_id;
    current_user = `${loginData?.userName?.first_name || ""} ${
      loginData?.userName?.last_name || ""
    }`;
  }
  const { ClientSpecificProfileData } = useSelector((state) => state);
  const openCSV = () => {
    setCSV(true);
  };

  const dialogCloseCSV = (e) => {
    setCSV(false);
    // dispatch(reSetData());
    setCsvData([]);
  };

  const onCloseNextCsv = () => {
    setSelCompChecklist([]);
    setDialogNextCsv(false);
    setWorkerChecklistError("");
    setWorkerSelCompChecklist([]);
    setfinalCsvData([]);
    setAttachment({ files: [] });
    SetCsvFileData({
      provider_type_id: "",
      invite_attach: [],
      invite_message: "",
    });
  };
  const submitInviteCsv = () => {
    const DocSelectValidation = Validations({
      value: CsvFileData.provider_type_id,
      Validations: { required: true },
    });

    const comCheckListId = Validations({
      value: selCompChecklist?.length == 0 ? "" : true,
      Validations: { required: true },
    });
    const WorkerCheckListValidation = Validations({
      value: workerSelCompChecklist?.length == 0 ? "" : workerSelCompChecklist,
      Validations: { required: true },
    });
    if (!WorkerCheckListValidation?.valid) {
      setWorkerChecklistError(WorkerCheckListValidation?.error_msg);
    }
    setFormError("selectDoc", DocSelectValidation.error_msg);
    setFormError("comCheckListId", comCheckListId.error_msg);
    const workerChecllistIds = workerSelCompChecklist?.map(
      (item) => item?.value
    );

    if (
      DocSelectValidation.valid &&
      documentError == "" &&
      comCheckListId.valid &&
      WorkerCheckListValidation.valid
    ) {
      const updatedCsvFileData = {
        ...CsvFileData,
        invite_attach: attachment,
      };
      dispatch(
        ViewClientCsv({
          // trading_name: props?.clientData?.trading_name,
          provider_invite_data: finalCsvData,
          ...updatedCsvFileData,
          checklistIds: JSON.stringify(
            selCompChecklist?.map((item) => item?.value)
          ),
          client_org_id: selectedClientType.value,
          current_user: current_user,
          trading_name: SpecificProviderData?.trading_name,
          workerChecklistIds: JSON.stringify(
            workerChecllistIds.length ? workerChecllistIds : []
          ),
        })
      )
        .then((response) => {
          if (response?.success) {
            setCSV(false);
            onChooseClientClose();
            setCsvData([]);
            setAddOpen(true);
            onCloseNextCsv();
            setWorkerChecklistError("");
            setWorkerSelCompChecklist([]);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {});
      setUpdateCsvData(true);
    }
  };
    
  // Upload CSV File
  const handleFileChangeCSV = (e) => {
    setCsvData([]);
    const file = e.target.files[0];
    SetdialogClientChoose(true);
    setFileData(file);
  };

  const onChooseClientClose = () => {
    setSelectedClientType("");
    setRequireField((prevError) => ({
      ...prevError,
      clientNameError: "",
    }));
    SetdialogClientChoose(false);
  };

  const openClientDialog = () => {
    let payload = {
      provider_invite_csv: fileData,
      client_fa_id: selectedClientType?.function_assignment_id,
      client_org_id: selectedClientType.value,
      contact_type_id: contact_type_id,
      function_id: function_id,
    };
    if (selectedClientType?.function_assignment_id) {
      dispatch(inviteProviderCSVView(payload));
      openCSV();
      setUpdateCsvData(false);
    } else {
      const clientNameValidation = Validations({
        value: "",
        Validations: { required: true },
      });
      setRequireField((prevErrors) => ({
        ...prevErrors,
        clientNameError: clientNameValidation.error_msg,
      }));
    }
  };

  // CSV Default Data Array
  const CSVData = [
    ["first_name", "last_name", "email", "provider_abn_nzbn"],
    ["brandon", "Hodges", "brandon.hodges33@conserv.com", "00-000-000-000"],
    ["victor", "Carr", "victor.carr33@conserv.com", "00-000-000-000"],
    ["Lillian", "Hodges", "lillian.pullman@conserv.com", "00-000-000-000"],
    ["Edward", "Hodges", "edward.mills@conserv.com", "00-000-000-000"],
    ["Megan", "Hodges", "megan.mcdonald4555@conserv.com", "00-000-000-000"],
  ];
  const handlecheckbox = (event, index) => {
    const isChecked = event;
    const item = csvData[index];
    if (isChecked) {
      // Add the checked item to the array
      setfinalCsvData((prevCheckedItems) => [...prevCheckedItems, item]);
    } else {
      // Remove the unchecked item from the array
      setfinalCsvData((prevCheckedItems) =>
        prevCheckedItems.filter(
          (checkedItem) => checkedItem.email !== item?.email
        )
      );
    }
  };
  // Import CSV Function
  const Toggle = (
    <Button className="flex gap-1 justify-center items-center text-xs w-auto px-3 h-9 bg-blue-50 border-gray-300 rounded-lg border font-semibold hover:bg-gray-50 hover:border-gray-300 focus:bg-white focus:border-gray-300">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.67 10.417v-4.75c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.093-1.093c-.534-.272-1.235-.272-2.635-.272H7.336c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093c-.272.535-.272 1.235-.272 2.635v8.666c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h3.083m1.25-9.166h-5M8.336 12.5H6.669m6.667-6.667H6.669m5.834 10 2.5 2.5m0 0 2.5-2.5m-2.5 2.5v-5"
          stroke="#344054"
          strokeWidth="1.667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Import CSV
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m6.394 9 6 6 6-6"
          stroke="#000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );

  // Submit CSV Data
  const submitCsvDataNext = () => {
    // open new pannel
    dialogCloseCSV();
    setDialogNextCsv(true);
  };
  const OnGetAllComplianceOfClientList = useCallback(
    async (client_org_id) => {
      if (client_org_id) {
        try {
          const response = await dispatch(
            GetAllComplianceOfClientList({
              client_org_id: client_org_id,
            })
          );
          if (response?.success) {
            const arrayData = response?.data;

            // Transform the data to map over required fields
            const transformedData = arrayData.map((item) => {
              return {
                label: item?.checklist_name,
                value: item?.checklist_id,
                recipient_type: item?.recipient_type,
              };
            });

            // Filter the data by recipient_type
            const providerData = transformedData.filter(
              (item) => item.recipient_type === "provider"
            );
            const workerData = transformedData.filter(
              (item) => item.recipient_type === "worker"
            );

            // Set the filtered data to their respective states
            setAllCheckListData(providerData); // Provider data
            setWorkerAllCheckListData(workerData); // Worker data
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          } else {
            console.error("Failed to fetch data:", response);
          }
        } catch (error) {
          console.error("Error during fetch:", error);
        }
      }
    },
    [id, dispatch]
  );

  useEffect(() => {
    if (selectedClientType?.value) {
      OnGetAllComplianceOfClientList(selectedClientType?.value);
    }
  }, [id, OnGetAllComplianceOfClientList, selectedClientType?.value]);

  const selectCompChecklist = (selectedOption) => {
    setSelCompChecklist(selectedOption);
    setChecklistError("");
  };

  const selectWorkerCompChecklist = (selectedOption) => {
    setWorkerSelCompChecklist(selectedOption);
    setWorkerChecklistError("");
  };

  const HandleAtachData = (event, name) => {
    SetCsvFileData((prevalue) => {
      return {
        ...prevalue,
        [name]: event,
      };
    });
  };

  const updateData = (item) => {
    dispatch(AddSpecificProviderData(item));
  };
  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <Heading>All Providers</Heading>
        <div className="flex gap-3">
          <div className="flex gap-2">
            <div className="w-80 input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img src={searchIcon} alt="" className="h-5" />
              </div>
              <input
                type="text"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                onChange={(e) => inputchangeSearch(e)}
              />
            </div>
          </div>
          <div className="flex justify-end gap-2 reset-dropdown px-2">
            <Dropdown renderTitle={Toggle} isOpen={true}>
              <Dropdown.Item eventKey="a">
                <CSVLink
                  className="client_portal_csv"
                  data={CSVData.map((row) =>
                    row.filter((column) => column !== "action")
                  )}
                  filename={"Sample file for provider invite.csv"}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 10V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V10M11.3333 6.66667L8 10M8 10L4.66667 6.66667M8 10V2"
                      stroke="#344054"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Download template</span>
                </CSVLink>
              </Dropdown.Item>
              <Dropdown.Item eventKey="b">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33317 1.51318V4.26688C9.33317 4.64025 9.33317 4.82693 9.40583 4.96954C9.46975 5.09498 9.57173 5.19697 9.69718 5.26088C9.83978 5.33354 10.0265 5.33354 10.3998 5.33354H13.1535M5.99984 10.0002L7.99984 12.0002M7.99984 12.0002L9.99984 10.0002M7.99984 12.0002L7.99984 8.00016M9.33317 1.3335H5.8665C4.7464 1.3335 4.18635 1.3335 3.75852 1.55148C3.3822 1.74323 3.07624 2.04919 2.88449 2.42552C2.6665 2.85334 2.6665 3.41339 2.6665 4.5335V11.4668C2.6665 12.5869 2.6665 13.147 2.88449 13.5748C3.07624 13.9511 3.3822 14.2571 3.75852 14.4488C4.18635 14.6668 4.7464 14.6668 5.8665 14.6668H10.1332C11.2533 14.6668 11.8133 14.6668 12.2412 14.4488C12.6175 14.2571 12.9234 13.9511 13.1152 13.5748C13.3332 13.147 13.3332 12.5869 13.3332 11.4668V5.3335L9.33317 1.3335Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {/* Import file */}
                <span>Import file</span>
                <input
                  className="upload_csv"
                  type="file"
                  accept=".csv"
                  onChange={handleFileChangeCSV}
                />
              </Dropdown.Item>
            </Dropdown>
            <div className="flex">
              <BtnPrimary
                variant="solid"
                className="w-auto h-9 flex items-center justify-center text-xs px-3.5"
                type="button"
                onClick={() => openChoose()}
              >
                Add provider
                <span class="ml-3">
                  {/* <img src={PlusIcon} alt="" class="h-5" /> */}
                </span>
              </BtnPrimary>
            </div>
          </div>
          {/* <BtnPrimary
            onClick={() => openChoose()}
            className="flex text-12 justify-center items-center gap-2 px-3 w-auto"
          >
            Add Provider{" "}
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.712 4.167v11.666M4.878 10h11.667"
                stroke="#fff"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnPrimary> */}
        </div>
      </div>
      <ViewAllList
        fetchProviders={fetchProviders}
        providersData={providersData}
        setProviderData={setProviderData}
        handleSort={handleSort}
        filterData={filterData}
        updateData={updateData}
      />
      <div
        className="flex justify-between mt-3"
        style={{
          alignItems: "Right",
          listStyle: "none",
          padding: 0,
        }}
      >
        <Pagination
          totalCount={countData}
          pageNumber={filterData.page}
          displayPage={providersData?.length}
          UpdatePageLimit={updatePageLimit}
          allfilters={filterData}
        />
        {countData > 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
            renderOnZeroPageCount={null}
            forcePage={filterData.page - 1}
          />
        )}
      </div>
      {/* Client Select Csv */}
      <Dialog
        isOpen={dialogClientChoose}
        onClose={onChooseClientClose}
        onRequestClose={onChooseClientClose}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.81 14.8891C18.1307 14.8891 18.391 14.6288 18.391 14.3081V13.5335C18.391 12.7852 17.7852 12.1779 17.0354 12.1779H15.486C14.7362 12.1779 14.1304 12.7852 14.1304 13.5335V14.3081C14.1304 14.6288 14.3907 14.8891 14.7114 14.8891H17.81ZM17.229 13.8728H15.2924V13.5335C15.2924 13.4266 15.3792 13.3398 15.486 13.3398H17.0354C17.1422 13.3398 17.229 13.4266 17.229 13.5335V13.8728Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M10.7797 14.0663C8.90292 14.0979 5.33463 14.7345 3.56012 18.5572C3.34816 19.0141 3.38455 19.7369 4.73003 19.7759C6.0755 19.8149 8.60575 19.7921 10.1657 19.7759"
                stroke="#0E4E9A"
                stroke-width="1.95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="11.5698"
                cy="7.90674"
                r="3.75986"
                stroke="#0E4E9A"
                stroke-width="1.95"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add a Provider
            </h5>
          </div>
        </div>
        <div className="px-6">
          <div className="mb-5">
            <label className="font-medium text-gray-700 mb-1 block">
              Client*
            </label>
            <div className="w-full hide-seacrh-icon">
              <Select
                className="select-options"
                placeholder="Select client"
                options={allClient}
                value={selectedClientType}
                onChange={(selectedOption) => selectionClient(selectedOption)}
              ></Select>
              {requiredField.clientNameError && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {requiredField.clientNameError}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight
            className="mr-1.5"
            variant="plain"
            onClick={onChooseClientClose}
          >
            Cancel
          </BtnLight>
          <BtnPrimary onClick={() => openClientDialog()} className="ml-1.5">
            Next
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogChoose}
        onClose={onChooseClose}
        onRequestClose={onChooseClose}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.81 14.8891C18.1307 14.8891 18.391 14.6288 18.391 14.3081V13.5335C18.391 12.7852 17.7852 12.1779 17.0354 12.1779H15.486C14.7362 12.1779 14.1304 12.7852 14.1304 13.5335V14.3081C14.1304 14.6288 14.3907 14.8891 14.7114 14.8891H17.81ZM17.229 13.8728H15.2924V13.5335C15.2924 13.4266 15.3792 13.3398 15.486 13.3398H17.0354C17.1422 13.3398 17.229 13.4266 17.229 13.5335V13.8728Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M10.7797 14.0663C8.90292 14.0979 5.33463 14.7345 3.56012 18.5572C3.34816 19.0141 3.38455 19.7369 4.73003 19.7759C6.0755 19.8149 8.60575 19.7921 10.1657 19.7759"
                stroke="#0E4E9A"
                stroke-width="1.95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="11.5698"
                cy="7.90674"
                r="3.75986"
                stroke="#0E4E9A"
                stroke-width="1.95"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add a Provider
            </h5>
            <p>Find an existing Provider in the system or invite a new one.</p>
          </div>
        </div>
        <div className="px-6">
          <div className="mb-5">
            <label className="font-medium text-gray-700 mb-1 block">
              Client*
            </label>
            <div className="w-full hide-seacrh-icon">
              <Select
                className="select-options"
                placeholder="Select client"
                options={allClient}
                value={selectedClientType}
                onChange={(selectedOption) => selectionClient(selectedOption)}
              ></Select>
              {requiredField.clientNameError && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {requiredField.clientNameError}
                </p>
              )}
            </div>
          </div>
          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4 mb-1 block">
              Provider type*
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select document"
                options={searchProviderOptions}
                value={
                  searchOptions[0]?.provider_type_name
                    ? UpdateData[0]
                    : selectedDocumentType
                }
                onChange={(selectedOption) => selectDocType(selectedOption)}
              ></Select>
              {requiredField.providerNameError && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {requiredField.providerNameError}
                </p>
              )}
            </div>
          </div>
          <div className="mb-5">
            <label className="font-medium text-gray-700 mb-1 block">
              {/* Provider name, ABN, or email* */}
              Email address or ABN/NZBN*
            </label>
            <div className="w-full hide-seacrh-icon hide-down-arrow">
              <Select
                className="w-full h-10 select-options"
                placeholder="Search for a Provider"
                isClearable={true}
                isSearchable={true}
                options={updatedOptions ? updatedOptions : selectedOption}
                value={selectedOption || customOption}
                onChange={handleSelectChange}
                handleSearchChange={handleSearchChange}
              ></Select>
              {requiredField.correctFormat && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {requiredField.correctFormat}
                </p>
              )}
              {requiredField.conserveEmailReq && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {requiredField.conserveEmailReq}
                </p>
              )}

              {formError.email && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {formError.email}
                </p>
              )}

              {!formError.email && formError.name && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  Please enter the value in the correct format.
                </p>
              )}

              {!formError.email && !formError.name && formError.abn_nzbn && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {formError.abn_nzbn}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onChooseClose}>
            Cancel
          </BtnLight>
          <BtnPrimary onClick={() => openDialog()} className="ml-1.5">
            Add Provider
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.81 14.8891C18.1307 14.8891 18.391 14.6288 18.391 14.3081V13.5335C18.391 12.7852 17.7852 12.1779 17.0354 12.1779H15.486C14.7362 12.1779 14.1304 12.7852 14.1304 13.5335V14.3081C14.1304 14.6288 14.3907 14.8891 14.7114 14.8891H17.81ZM17.229 13.8728H15.2924V13.5335C15.2924 13.4266 15.3792 13.3398 15.486 13.3398H17.0354C17.1422 13.3398 17.229 13.4266 17.229 13.5335V13.8728Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M10.7797 14.0663C8.90292 14.0979 5.33463 14.7345 3.56012 18.5572C3.34816 19.0141 3.38455 19.7369 4.73003 19.7759C6.0755 19.8149 8.60575 19.7921 10.1657 19.7759"
                stroke="#0E4E9A"
                stroke-width="1.95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="11.5698"
                cy="7.90674"
                r="3.75986"
                stroke="#0E4E9A"
                stroke-width="1.95"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add a Provider
            </h5>
            <p>Find an existing Provider in the system or invite a new one.</p>
          </div>
        </div>
        <div className="px-6">
          <div className="mb-5">
            <label className="font-medium text-gray-700 mb-1 block">
              Client
            </label>
            <div className="w-full hide-seacrh-icon">
              <Select
                className="select-options"
                placeholder="Select client"
                options={allClient ? allClient : ""}
                value={selectedClientType ? selectedClientType : ""}
                readOnly
              ></Select>
            </div>
          </div>
          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Provider type:
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select document"
                options={searchProviderOptions}
                readOnly
                value={
                  searchOptions[0]?.provider_type_name
                    ? UpdateData[0]
                    : selectedDocumentType
                }
                // onChange={(selectedOption) =>
                //   selectDocType(selectedOption)
                // }
              ></Select>
              {!selectedDocumentType && formError.selectDoc && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {formError.selectDoc}
                </p>
              )}
            </div>
          </div>
          <div className="flex mb-5 flex-1 gap-4">
            <div className="w-3/6">
              <label className="font-medium text-gray-700 mb-1 block">
                Email address*
              </label>
              <div className="w-full hide-seacrh-icon">
                {/* {formData.email || pageOne ? ( */}
                {contractorFAId || pageOne ? (
                  <Input
                    className="h-9 w-full"
                    placeholder=""
                    value={formData.email}
                    readOnly
                    // inputchangehandler={(e) => inputchangehandler(e, "email")}
                    // readOnly={contractorFAId || pageOne ? true : false}
                    // onBlur={handleBlurForEmail}
                  />
                ) : (
                  <Select
                    className="w-full h-10 select-options"
                    placeholder="Search for a Provider"
                    isClearable={true}
                    isSearchable={true}
                    options={updatedOptions ? updatedOptions : selectedOption}
                    value={selectedOption || customOption}
                    onChange={(selectedValue) => {
                      if (!selectedValue) {
                        isSearchClear();
                      }
                      handleSelectChange(selectedValue);
                    }}
                    handleSearchChange={handleSearchChange}
                    // onBlur={handleBlurForEmail}
                  />
                )}
                {requiredField.correctFormat && (
                  <p className="text-xs text-red-600 mb-0 mt-1">
                    {requiredField.correctFormat}
                  </p>
                )}
                {requiredField.conserveEmailReq && (
                  <p className="text-xs text-red-600 mb-0 mt-1">
                    {requiredField.conserveEmailReq}
                  </p>
                )}
                {!requiredField.correctFormat && formError.email && (
                  <p className="text-xs text-red-600 mb-0 mt-1">
                    {formError.email}
                  </p>
                )}
              </div>
            </div>
            <div className="w-3/6">
              <Label className="text-xs font-medium block mb-1">ABN/NZBN</Label>
              <Input
                // type='number'
                className="h-9 w-full"
                placeholder=""
                value={formData.abn_nzbn}
                inputchangehandler={(e) => inputchangehandler(e, "abn_nzbn")}
                // readOnly={contractorFAId ? true : false}
                readOnly={abnEditOrNot ? true : false}
                onBlur={handleABN}
                maxLength="14"
                minLength="11"
              />
              {formError.abn_nzbn && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {formError.abn_nzbn}
                </p>
              )}
            </div>
          </div>

          <div className="flex mb-5 flex-1 gap-4">
            <div className="w-3/6">
              <Label className="text-xs font-medium block mb-1">
                First name*
              </Label>
              <Input
                type="text"
                className="h-9 w-full"
                placeholder="Enter Provider’s first name"
                readOnly={formData.individual_id ? true : false}
                // readOnly={searchOptions[0]?.ContractorFirstName ? true : false}
                value={
                  searchOptions[0]?.ContractorFirstName
                    ? searchOptions[0]?.ContractorFirstName
                    : formData?.first_name
                }
                inputchangehandler={(e) => inputchangehandler(e, "first_name")}
                // readOnly={individualId ? true : false}
              />
              {requiredField.first_name && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {requiredField.first_name}
                </p>
              )}
            </div>
            <div className="w-3/6">
              <Label className="text-xs font-medium block mb-1">
                Last name*
              </Label>
              <Input
                type="text"
                className="h-9 w-full"
                placeholder="Enter Provider’s last name"
                value={
                  searchOptions[0]?.ContractorLastName
                    ? searchOptions[0]?.ContractorLastName
                    : formData?.last_name
                }
                inputchangehandler={(e) => inputchangehandler(e, "last_name")}
                readOnly={formData.individual_id ? true : false}
                // readOnly={searchOptions[0]?.ContractorLastName ? true : false}
              />
              {requiredField.last_name && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {requiredField.last_name}
                </p>
              )}
            </div>
          </div>
          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Assign Provider Compliance Checklist(s)
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select Compliance Checklist"
                isMulti={true}
                options={allCheckListData}
                value={selCompChecklist}
                onChange={(selectedOption) =>
                  selectCompChecklist(selectedOption)
                }
              ></Select>
              {selCompChecklist?.length === 0 && checkListError && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {checkListError}
                </p>
              )}

              {/* <Select
                      value={selectedOption}
                      onChange={(e) => HandleDocData(e, "document_type_id")}
                      options={Array.isArray(selectDocumentData) ? selectDocumentData : []}
                    /> */}
            </div>
          </div>

          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Assign Worker Compliance Checklist(s)
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select Compliance Checklist"
                isMulti={true}
                options={workerAllCheckListData}
                value={workerSelCompChecklist}
                onChange={(selectedOption) =>
                  selectWorkerCompChecklist(selectedOption)
                }
              ></Select>
              {workerSelCompChecklist?.length === 0 && workerCheckListError && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {workerCheckListError}
                </p>
              )}
            </div>
          </div>
          <div className="mb-5">
            <Label className="text-xs font-medium block mb-1">
              Attachment (optional)
            </Label>
            <Upload
              draggable
              className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
              onChange={handleFileChange}
              onFileRemove={handleFileRemove}
              multiple={true}
              maxFileSize={25 * 1024 * 1024}
            >
              <span className="text-blue-700 font-semibold">
                Click to upload
              </span>{" "}
              <span className="font-medium text-gray-600">
                or drag and drop
              </span>
              <p className="text-gray-600">(max.25mb)</p>
            </Upload>
          </div>

          <div className="mb-5 message-box-height">
            <Label className="text-xs font-medium block mb-1">
              Message (optional)
            </Label>
            <RichTextEditor
              value={message}
              onChange={(value) => setMessage(value)}
            />
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onDialogClose}>
            Cancel
          </BtnLight>
          <BtnPrimary
            // onClick={() => openAddDialog()}
            onClick={() => InviteContractor()}
            className="ml-1.5"
          >
            Invite Provider
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="22"
              height="23"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#000"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onAddDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>

      <Dialog
        isOpen={dialogAlreadyOpen}
        onClose={onDialogAlreadyClose}
        onRequestClose={onDialogAlreadyClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="48" height="48" rx="24" fill="#FEE4E2" />
              <path
                d="M21.5 21L27.5 27M27.5 21L21.5 27M20.3 33H28.7C30.3802 33 31.2202 33 31.862 32.673C32.4265 32.3854 32.8854 31.9265 33.173 31.362C33.5 30.7202 33.5 29.8802 33.5 28.2V19.8C33.5 18.1198 33.5 17.2798 33.173 16.638C32.8854 16.0735 32.4265 15.6146 31.862 15.327C31.2202 15 30.3802 15 28.7 15H20.3C18.6198 15 17.7798 15 17.138 15.327C16.5735 15.6146 16.1146 16.0735 15.827 16.638C15.5 17.2798 15.5 18.1198 15.5 19.8V28.2C15.5 29.8802 15.5 30.7202 15.827 31.362C16.1146 31.9265 16.5735 32.3854 17.138 32.673C17.7798 33 18.6198 33 20.3 33Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            This Provider is already invited!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onDialogAlreadyClose}>Close</BtnPrimary>
        </div>
      </Dialog>

      <Dialog
        isOpen={dialogInviteSent}
        bodyOpenClassName="overflow-hidden"
        onClose={OnCloseinviteProvider}
        onRequestClose={OnCloseinviteProvider}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="48"
              height="49"
              viewBox="0 0 48 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="48" height="48" rx="24" fill="#D1FADF" />
              <path
                d="M22.4995 26.0002L32.9995 15.5002M22.6271 26.3282L25.2552 33.0862C25.4867 33.6816 25.6025 33.9793 25.7693 34.0662C25.9139 34.1415 26.0862 34.1416 26.2308 34.0664C26.3977 33.9797 26.5139 33.6822 26.7461 33.0871L33.3364 16.1994C33.5461 15.6622 33.6509 15.3936 33.5935 15.222C33.5437 15.0729 33.4268 14.956 33.2777 14.9062C33.1061 14.8488 32.8375 14.9536 32.3003 15.1633L15.4126 21.7536C14.8175 21.9858 14.52 22.102 14.4333 22.2689C14.3581 22.4135 14.3582 22.5858 14.4335 22.7304C14.5204 22.8972 14.8181 23.013 15.4135 23.2445L22.1715 25.8726C22.2923 25.9196 22.3527 25.9431 22.4036 25.9794C22.4487 26.0115 22.4881 26.051 22.5203 26.0961C22.5566 26.147 22.5801 26.2074 22.6271 26.3282Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invites sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 ">
          <BtnPrimary onClick={OnCloseinviteProvider}>Close</BtnPrimary>
        </div>
      </Dialog>
      {/* CSV Dilog Box */}
      <Dialog
        isOpen={dialogOpenCSV}
        onClose={dialogCloseCSV}
        onRequestClose={dialogCloseCSV}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_12450_78578)">
                <path
                  d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.81 14.889C18.1307 14.889 18.391 14.6287 18.391 14.308V13.5333C18.391 12.785 17.7852 12.1777 17.0354 12.1777H15.486C14.7362 12.1777 14.1304 12.785 14.1304 13.5333V14.308C14.1304 14.6287 14.3907 14.889 14.7114 14.889H17.81ZM17.229 13.8727H15.2924V13.5333C15.2924 13.4265 15.3792 13.3397 15.486 13.3397H17.0354C17.1422 13.3397 17.229 13.4265 17.229 13.5333V13.8727Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M10.78 14.0664C8.90316 14.098 5.33487 14.7346 3.56036 18.5573C3.34841 19.0142 3.38479 19.737 4.73027 19.776C6.07575 19.815 8.606 19.7923 10.166 19.776"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="11.5698"
                  cy="7.90674"
                  r="3.75986"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_12450_78578">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Providers
            </h5>
            <p>
              Please verify that the uploaded CSV information of Providers is
              accurate.
            </p>
          </div>
        </div>
        <div className="h-96 pr-2">
          <Scrollbar>
            <div className="px-4">
              <div className="overflow-hidden border rounded-lg border-gray-200">
                <Table>
                  <THead>
                    <Tr>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          First Name
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Last Name
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Email address
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          ABN/NZBN
                        </span>
                      </Th>
                      <Th className="w-1/3"></Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {csvData?.map((item, index) => {
                      return (
                        <React.Fragment>
                          <Tr key={index}>
                            <Td>{item?.first_name}</Td>
                            <Td>{item?.last_name}</Td>
                            <Td>{item?.email}</Td>
                            <Td>{item?.provider_abn_nzbn}</Td>
                            <Td>
                              {item?.is_validated ? (
                                <Checkbox
                                  checked={true}
                                  onChange={(event) => {
                                    handlecheckbox(event, index);
                                  }}
                                />
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m18 6.5-12 12m0-12 12 12"
                                    stroke="red"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })}
                  </TBody>
                </Table>
              </div>
            </div>
          </Scrollbar>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => dialogCloseCSV()} className="mr-1.5">
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={!finalCsvData.length}
            onClick={() => submitCsvDataNext()}
          >
            Next
          </BtnPrimary>
        </div>
      </Dialog>
      {/* Invite Provider import csv */}
      <Dialog
        isOpen={dialogNextCsv}
        onClose={onCloseNextCsv}
        onRequestClose={onCloseNextCsv}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_12450_78578)">
                <path
                  d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.81 14.889C18.1307 14.889 18.391 14.6287 18.391 14.308V13.5333C18.391 12.785 17.7852 12.1777 17.0354 12.1777H15.486C14.7362 12.1777 14.1304 12.785 14.1304 13.5333V14.308C14.1304 14.6287 14.3907 14.889 14.7114 14.889H17.81ZM17.229 13.8727H15.2924V13.5333C15.2924 13.4265 15.3792 13.3397 15.486 13.3397H17.0354C17.1422 13.3397 17.229 13.4265 17.229 13.5333V13.8727Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M10.78 14.0664C8.90316 14.098 5.33487 14.7346 3.56036 18.5573C3.34841 19.0142 3.38479 19.737 4.73027 19.776C6.07575 19.815 8.606 19.7923 10.166 19.776"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="11.5698"
                  cy="7.90674"
                  r="3.75986"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_12450_78578">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Providers
            </h5>
            <p>
              Ensure Providers are categorised correctly and send an optional
              invitation message.
            </p>
          </div>
        </div>
        <div className="pl-4">
          <div className="h-500">
            <ScrollBar>
              <div className="pr-6 pl-2">
                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Provider type*
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select document"
                      options={searchProviderOptions}
                      // value={selectedDocumentType}
                      onChange={(e) =>
                        HandleAtachData(e.value, "provider_type_id")
                      }
                    ></Select>
                    {!CsvFileData.provider_type_id && formError.selectDoc && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.selectDoc}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Assign Compliance Checklist*
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select document"
                      isMulti={true}
                      options={allCheckListData}
                      value={selCompChecklist}
                      onChange={(selectedOption) => {
                        selectCompChecklist(selectedOption);
                        // setFormErrors((prevError) => ({
                        //   ...prevError,
                        //   comCheckListId: "",
                        // }));
                      }}
                    ></Select>
                    {!CsvFileData.provider_type_id && formError.selectDoc && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.comCheckListId}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Assign Worker Compliance Checklist(s)
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select Compliance Checklist"
                      isMulti={true}
                      options={workerAllCheckListData}
                      value={workerSelCompChecklist}
                      onChange={(selectedOption) =>
                        selectWorkerCompChecklist(selectedOption)
                      }
                    ></Select>
                    {workerSelCompChecklist?.length === 0 &&
                      workerCheckListError && (
                        <p className="text-xs text-red-600 mb-2 mt-0">
                          {workerCheckListError}
                        </p>
                      )}
                  </div>
                </div>

                <div className="mb-5">
                  <Label className="text-xs font-medium block mb-1">
                    Attachment (optional)
                  </Label>
                  <Upload
                    draggable
                    className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                    // onChange={(e) => HandleAtachData(e, "invite_attach")}
                    onChange={handleFileChange}
                    onFileRemove={handleFileRemove}
                    multiple={true}
                    maxFileSize={25 * 1024 * 1024}
                  >
                    <span className="text-blue-700 font-semibold">
                      Click to upload
                    </span>{" "}
                    <span className="font-medium text-gray-600">
                      or drag and drop
                    </span>
                    <p className="text-gray-600">
                      (Max 25mb. Only pdf, heic, png, and jpg files will be
                      accepted)
                    </p>
                  </Upload>
                  {documentError && (
                    <p className="text-xs text-red-600 mt-1">{documentError}</p>
                  )}
                </div>
                <div className="mb-5 message-box-height">
                  <Label className="text-xs font-medium block mb-1">
                    Message (optional)
                  </Label>
                  <RichTextEditor
                    value={CsvFileData.invite_message}
                    // onChange={(value) => setMessage(value)}
                    onChange={(e) => HandleAtachData(e, "invite_message")}
                  />
                </div>
              </div>
            </ScrollBar>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onCloseNextCsv}>
            Cancel
          </BtnLight>
          <BtnPrimary onClick={() => submitInviteCsv()} className="ml-1.5">
            Invite Provider
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  onGetIndividualByEmail: userActions.GetIndividualByEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractorViewAll);
