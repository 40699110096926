
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Upload from "../../../../../common/element/Upload";
import Dialog from "../../../../../common/element/Dialog";
import Validations from "../../../../../common/404/Validations";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { CreateWorkerNote } from "../../../../../actions/admin-worker/CreateWorkerNote";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";
const AddNote = (props) => {
  const dispatch = useDispatch()
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [attachment, setAttachment] = useState({ files: [] });
  const [dialogAddOpen, setDialogAddOpen] = useState(false);
  const [noteData, setNotData] = useState({
    note_heading: '',
    note: '',
    // note_attach: ''
  })
  const [noteError, setNoteError] = useState({
    note_heading: '',
    note: '',
    note_attach: ''
  })

  const onAddDialogClose = () => {
    setDialogAddOpen(false);
    props.onDialogClose()
    props?.GetSpecificOrgNotes()
  };

  const clearFormErrors = () => {
    setNoteError({
      note_heading: '',
      note: '',
      note_attach: ''
    });
  }
  const clearFormData = () => {
    setNotData({
      note_heading: '',
      note: '',
    });
  }
  // Handle input changes
  const inputChangeHandler = (e, field) => {
    let value = e.target.value;
    setNotData({ ...noteData, [field]: value });
    if (field == "note_heading") {
      setNoteError(((prevErr) => ({
        ...prevErr,
        note_heading: ""
      })))
    } else {
      setNoteError(((prevErr) => ({
        ...prevErr,
        note: ""
      })))
    }
  };

  // Handle file change in the attachment input
  const handleFileChange = (files) => {
    setAttachment({ files });
    setNoteError(((prevErr) => ({
      ...prevErr,
      note_attach: ""
    })))
  };
  // Handle the file remove logic here
  const handleFileRemove = (files) => {
    setAttachment({ files });
    setNoteError(((prevErr) => ({
      ...prevErr,
      note_attach: ""
    })))
  };

  // Calculate the total size of attachments
  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment.files.forEach((item) => {
      if (item.size) {
        totalSize += item?.size;
      }
    });
    return totalSize;
  };


  // Set a form error
  const setFormError = (field, message) => {
    setNoteError((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  // Handle form submission
  const submithandler = (e) => {
    e.preventDefault();

    const headValidation = Validations({
      value: noteData?.note_heading,
      Validations: { required: true },
    });
    const contentValidation = Validations({
      value: noteData?.note,
      Validations: { required: true },
    });
    if (!headValidation.valid) {
      setFormError("note_heading", headValidation.error_msg);
    }
    if (!contentValidation.valid) {
      setFormError("note", contentValidation.error_msg);
    }
    const totalSize = calculateTotalAttachmentSize();
    if (totalSize > 25 * 1024 * 1024) {
      setFormError("note_attach", "Total attachment size cannot exceed 25MB");
      return;
    }
    if (headValidation?.valid === true && contentValidation) {
      const payload = {
        ...noteData,
        note_attach: attachment,
        individual_id: "6e720c0d-b951-40a0-9ac9-68d3dc521d29",
      };
      props?.setButtonDisabled(true)
      dispatch(CreateWorkerNote(payload)).then((response) => {
        if (response.success === true) {
          props?.setButtonDisabled(false)
          setDialogAddOpen(true);
          clearFormErrors();
          clearFormData();
        } else if (response.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      }).catch((error) => {
        console.log("error", error);
      }).finally(() => {
        props?.setButtonDisabled(false)
      })
    }
  };

  return (
    <React.Fragment>
      <form>
        <div className="mt-6 add-client-form px-6 ">
          <div className="flex justify-between mb-5">
            <div className="lavel-wd">
              <label className="text-gray-700 font-medium">Note heading*</label>
            </div>
            <div className="columns-input">
              <input
                type="text"
                placeholder=""
                className=" input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={noteData?.note_heading}
                onChange={(e) => inputChangeHandler(e, "note_heading")}
              />
              {noteError?.note_heading && (
                <div className="text-red-500 text-sm ">{noteError?.note_heading}</div>
              )}
            </div>
          </div>

          <div className="flex justify-between mb-5">
            <div className="lavel-wd">
              <label className="text-gray-700 font-medium">Note*</label>
            </div>
            <div className="columns-input">
              <textarea
                placeholder="Enter a note..."
                className="min-h06  resize-none input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={noteData?.note}
                onChange={(e) => inputChangeHandler(e, "note")}
              ></textarea>
              {noteError?.note && (
                <div className="text-red-500 text-sm ">{noteError?.note}</div>
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="lavel-wd">
              <label className="text-gray-700 font-medium">
                Attachment (optional)
              </label>
            </div>
            <div className="columns-input">
              <div className="hideIcons wrap-doc">
                <Upload
                  draggable
                  className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                  onChange={handleFileChange}
                  onFileRemove={handleFileRemove}
                  multiple={true} // Allow multiple file uploads
                  maxFileSize={25 * 1024 * 1024} // Max file size in bytes (25MB)
                >
                  <span className="text-blue-700 font-semibold">
                    Click to upload
                  </span>{" "}
                  <p className="text-gray-600">(max.25mb)</p>
                </Upload>
                {noteError?.note_attach && (
                  <div className="text-red-500 text-sm ">{noteError?.note_attach}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight disabled={props?.buttonDisabled} onClick={props.onDialogClose}>Cancel</BtnLight>
          <BtnPrimary disabled={props?.buttonDisabled} type="submit" className="ml-1.5" onClick={submithandler}>
            {props?.buttonDisabled ? <ThreeDotLoader /> : 'Create note'}
          </BtnPrimary>
        </div>
      </form>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Notes added successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onAddDialogClose()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};


export default AddNote;
