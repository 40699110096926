import React, { useState, useEffect } from "react";
import Table from "../../../../../../common/element/Table";
import Button from "../../../../../../common/element/Buttons";
import Tag from "../../../../../../common/element/Tag";
import Dialog from "../../../../../../common/element/Dialog";
import ThreeDotLoader from "../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import DuplicateSite from "../Dialog/DuplicateSite";
import { useNavigate, Link } from "react-router-dom";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import { DeleteSite } from "../../../../../../actions/client-portal/AllSiteApi";
// import {GetClientUserPermByIdForClient} from '../../../../../../actions/client-portal/AllUsersApi';
import { useDispatch } from "react-redux";
import { encryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
const { Tr, Th, Td, THead, TBody } = Table;
const SitesList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [deleteSite, setSite] = useState(false);
  const [specificSiteData, setSiteData] = useState();
  const [siteId, setSiteId] = useState("");
  const [openDataExistDialog, setDataExistDailog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  let function_assignment_id;
  let user_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const permissionData = JSON.parse(localStorage.getItem("permission_data"));

  if (storedData !== null) {
    // user_id =  storedData?.userName?.user_id;
    // function_assignment_id = storedData.function_assignment_id;
  }

  // api call for permissions
  // useEffect(() =>{
  //   const apiData = {
  //     user_id: user_id,
  //     function_assignment_id: function_assignment_id
  //   }
  //   dispatch(GetClientUserPermByIdForClient(apiData)).then(
  //     (response) => {
  //        if (response.success === true) {
  //           // const arrayData = response.data;
  //           localStorage.setItem("permission_data", JSON.stringify(response.data))
  //         }
  //        }
  //   );
  // },[])
  
  const handleRowClick = (siteid) => {
    if (siteid) {
      const siteId = encryptId(siteid);
      // Navigate to the specific provider page without refresh
      navigate(`/client/sites/specific-site/${encodeURIComponent(siteId)}`);
    }
  };
  const onCloseExistDailog = (e) => {
    setDataExistDailog(false);
    onSiteDialogClose();
  };

  const openDialog = (siteData) => {
    setSiteData(siteData?.siteId);
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    !isSaving ? setIsOpen(false) : setIsOpen(true);
    // setIsOpen(false);
  };

  const openSiteDialog = (item) => {
    setSiteId(item?.siteId);
    setSite(true);
  };

  const onSiteDialogClose = (e) => {
    if (!buttonDisabled) {
      setSite(false);
    }
  };
  const handleDeleteSite = async () => {
    setButtonDisabled(true);
    try {
      const response = await dispatch(DeleteSite(siteId));
      if (response?.success) {
        onSiteDialogClose();
        setButtonDisabled(false);
        setSiteId("");
        if (
          response?.message !== "Delete site inductions first to remove site!"
        ) {
          props?.GetAllSiteListData();
        }
        // apply
      }
      // Change this later
      if (response?.message == "Delete site inductions first to remove site!") {
        setDataExistDailog(true);
        setButtonDisabled(false);
        setSiteId("");
      } else {
        console.error("Failed to delete site:", response.message);
      }
    } catch (error) {
      console.error("Error deleting site:", error);
    } finally {
      setButtonDisabled(false);
      setSiteId("");
    }
  };
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-1/5">
                <span
                  className="flex items-center"
                  onClick={() => props?.handleSort("site_name")}
                >
                  Site name
                  <svg
                    className={`transform ${
                      props?.filterState.filtersort === "site_name" &&
                      props?.filterState.filterorder === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-2/5">Address</Th>
              <Th className="w-72">State</Th>
              <Th className="w-48">Status</Th>
              <Th className="w-48"></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.allSiteData &&
              props?.allSiteData.map((item) => (
                <Tr>
                  <Td
                    onClick={() => handleRowClick(item?.siteId)}
                    className="cursor-pointer px-4 py-1.5 font-medium text-gray-900"
                  >
                    {/* <Link to={`/client/sites/specific-site/${item?.siteId}`}> */}
                    {item?.siteName ? item?.siteName : "-"}
                    {/* </Link> */}
                  </Td>
                  <Td
                    onClick={() => handleRowClick(item?.siteId)}
                    className="cursor-pointer px-4 py-1.5 font-medium text-gray-900"
                  >
                    {item?.siteAddress ? item?.siteAddress : "-"}
                  </Td>
                  <Td
                    onClick={() => handleRowClick(item?.siteId)}
                    className="cursor-pointer px-4 py-1.5 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {item?.siteState ? item?.siteState : "-"}
                  </Td>
                  <Td
                    onClick={() => handleRowClick(item?.siteId)}
                    className="cursor-pointer px-4"
                  >
                    <span
                      className={`badge py-0.5 px-2 rounded-lg ${
                        item?.siteStatus
                          ? "bg-success-50 text-success-700"
                          : "bg-red-50 text-red-700"
                      }`}
                    >
                      {item?.siteStatus ? "Active" : "Inactive"}
                    </span>
                  </Td>

                  <Td>
                    <div className="flex gap-4 justify-end">
                      <Button
                        disabled={props?.sitePermission}
                        onClick={() => openDialog(item)}
                      >
                        {" "}
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.167 13.218c-.777 0-1.165 0-1.471-.127a1.666 1.666 0 0 1-.902-.902c-.127-.306-.127-.694-.127-1.47V5.05c0-.933 0-1.4.181-1.756.16-.314.415-.569.729-.729.356-.181.823-.181 1.756-.181H10c.777 0 1.165 0 1.471.127.409.169.733.493.902.902.127.306.127.694.127 1.47m-2.333 14.167h5.5c.933 0 1.4 0 1.756-.181.314-.16.569-.415.729-.729.181-.356.181-.823.181-1.756v-5.5c0-.934 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182h-5.5c-.934 0-1.4 0-1.757.182-.314.16-.569.415-.728.728-.182.357-.182.823-.182 1.757v5.5c0 .933 0 1.4.182 1.756.16.314.414.569.728.729.357.181.823.181 1.757.181z"
                            stroke="#475467"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                      <Button
                        disabled={props?.sitePermission}
                        onClick={() => openSiteDialog(item)}
                      >
                        {" "}
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                            stroke="#475467"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                  </Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M8.12602 14.5007C8.57006 16.2259 10.1362 17.5007 12 17.5007C13.8638 17.5007 15.4299 16.2259 15.874 14.5007M11.0177 3.26473L4.23539 8.53986C3.78202 8.89248 3.55534 9.06879 3.39203 9.28959C3.24737 9.48518 3.1396 9.70552 3.07403 9.93978C3 10.2043 3 10.4914 3 11.0658V18.3007C3 19.4208 3 19.9809 3.21799 20.4087C3.40973 20.785 3.71569 21.091 4.09202 21.2827C4.51984 21.5007 5.07989 21.5007 6.2 21.5007H17.8C18.9201 21.5007 19.4802 21.5007 19.908 21.2827C20.2843 21.091 20.5903 20.785 20.782 20.4087C21 19.9809 21 19.4208 21 18.3007V11.0658C21 10.4914 21 10.2043 20.926 9.93978C20.8604 9.70552 20.7526 9.48518 20.608 9.28959C20.4447 9.06879 20.218 8.89248 19.7646 8.53986L12.9823 3.26473C12.631 2.99148 12.4553 2.85485 12.2613 2.80233C12.0902 2.75599 11.9098 2.75599 11.7387 2.80233C11.5447 2.85485 11.369 2.99148 11.0177 3.26473Z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Duplicate a Site
            </h5>
            <p className="text-gray-600 ">
              Creating duplicate site under the Client's profile to manage
              Inductions, Inductees, Visitors Log and more.{" "}
            </p>
          </div>
        </div>
        <DuplicateSite
          siteId={specificSiteData}
          onDialogClose={onDialogClose}
          updateDataFromApi={props?.updateDataFromApi}
          filterState={props?.filterState}
          setApiCalls={props.setApiCalls}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      </Dialog>

      <Dialog
        isOpen={deleteSite}
        onClose={onSiteDialogClose}
        onRequestClose={onSiteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete this Site?
          </p>
          <p className="text-gray-600 text-center">
            Deleting a site will remove all associated data. Please confirm that
            you want to proceed with this action as it cannot be undone.
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight
            disabled={buttonDisabled}
            className="mr-1.5"
            onClick={onSiteDialogClose}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={buttonDisabled}
            className="ml-1.5"
            onClick={handleDeleteSite}
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Delete"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={openDataExistDialog}
        onClose={onCloseExistDailog}
        onRequestClose={onCloseExistDailog}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="48" height="48" rx="24" fill="#FEE4E2" />
              <path
                d="M21.5 21L27.5 27M27.5 21L21.5 27M20.3 33H28.7C30.3802 33 31.2202 33 31.862 32.673C32.4265 32.3854 32.8854 31.9265 33.173 31.362C33.5 30.7202 33.5 29.8802 33.5 28.2V19.8C33.5 18.1198 33.5 17.2798 33.173 16.638C32.8854 16.0735 32.4265 15.6146 31.862 15.327C31.2202 15 30.3802 15 28.7 15H20.3C18.6198 15 17.7798 15 17.138 15.327C16.5735 15.6146 16.1146 16.0735 15.827 16.638C15.5 17.2798 15.5 18.1198 15.5 19.8V28.2C15.5 29.8802 15.5 30.7202 15.827 31.362C16.1146 31.9265 16.5735 32.3854 17.138 32.673C17.7798 33 18.6198 33 20.3 33Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Delete site inductions first to remove site!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onCloseExistDailog}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default SitesList;
