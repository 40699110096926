import React, { useState, useEffect } from "react";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../common/element/Buttons/btnPrimary";
import Checkbox from "../../../../../common/element/Checkbox";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UpdateUserPassword } from "../../../../../actions/provider-portal/UpdateUserPassword";
import Dialog from "../../../../../common/element/Dialog";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import Validations from "../../../../../common/404/Validations";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import { TwoFactorAuth } from "../../../../../actions/Admin-Client/AllSiteApi";
import Switcher from "../../../../../common/element/Switcher";
const Security = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [checkboxList, setCheckboxList] = useState([]);
  const [dialogAddOpen, setdialogAddOpen] = useState(false);
  const [password, setPassword] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [twoFA, setTwoFA] = useState(false)
  const [dialogTwoFA, setDialogTwoFA] = useState(false);
  const [formError, setFormError] = useState("")

  const [passwords, setPasswords] = useState({
    old_password: '',
    new_password: '',
    repeat_Password: ''
  })
  const permissionDisabled = props.permission_data.settings_write == true ? false : true;
  const [passwordError, setPasswordError] = useState("");
  let function_assignment_id;
  let user_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
  }

  const storedData2FA = JSON.parse(localStorage.getItem("two_factor_auth"));
  useEffect(() => {
    if (storedData2FA) {
      setTwoFA(storedData2FA)
    }
  }, [])

  const handlePasswords = (event, field) => {
    setPasswords((prevalue) => ({
      ...prevalue,
      [field]: event.target.value
    }));
    setPasswordError("")
  };

  useEffect(() => {
    // Check if the new password contains numbers
    const hasNumbers = /\d/.test(passwords.new_password);
    const isCapital = /^(?=.*[A-Z])/.test(passwords.new_password);
    const isSmall = /^(?=.*[a-z])/.test(passwords.new_password);
    // Add "Selection D" to the checkboxList only once if hasNumbers is true
    if (hasNumbers) {
      setCheckboxList((prevList) => {
        if (!prevList.includes("Selection D")) {
          return [...prevList, "Selection D"];
        }
        return prevList;
      });
    }
    else { setCheckboxList((prevList) => prevList.filter((item) => item !== "Selection D")) }
    if (isCapital) {
      setCheckboxList((prevList) => {
        if (!prevList.includes("Selection C")) {
          return [...prevList, "Selection C"];
        }
        return prevList;
      });
    }
    else { setCheckboxList((prevList) => prevList.filter((item) => item !== "Selection C")) }
    if (isSmall) {
      setCheckboxList((prevList) => {
        if (!prevList.includes("Selection B")) {
          return [...prevList, "Selection B"];
        }
        return prevList;
      });
    }
    else { setCheckboxList((prevList) => prevList.filter((item) => item !== "Selection B")) }
    if (passwords.new_password.length >= 8) {
      setCheckboxList((prevList) => {
        if (!prevList.includes("Selection A")) {
          return [...prevList, "Selection A"];
        }
        return prevList;
      });
    }
    else { setCheckboxList((prevList) => prevList.filter((item) => item !== "Selection A")) }
  }, [passwords.new_password]);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwords.new_password !== passwords.repeat_Password) {
      setPasswordError("Passwords do not match");
      return;
    }
    const isStrong = passwords.new_password.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
    );
    if (!isStrong) {
      setPasswordError(
        "Your password must be at least 8 characters long, contain at least one special character and have a mixture of lowercase, uppercase and numbers."
      );
      return;
    }

    const payload = {
      new_password: passwords.new_password,
      old_password: passwords.old_password,
    };

    dispatch(UpdateUserPassword(payload))
      .then((response) => {
        if (response.success) {
          setdialogAddOpen(true);
        } else if (response.status === 401) {
          handleUnauthorizedAccess()
        }
      })
      .catch((error) => {
      });
  };
  const onAddDialogClose = () => {
    setdialogAddOpen(false);
  };

  const onSwitcherToggle = (e) => {
    setTwoFA(!twoFA);
    setDialogTwoFA(true);
  };
  const onAddDialogTwoFA = () => {
    if(!buttonDisabled){
      setDialogTwoFA(false)
    }
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setFormError("");

  };

  const handleVerify = async () => {

    const twofaValidation = Validations({
      value: password,
      Validations: { required: true },
    });
    if (!twofaValidation.valid) {
      setFormError(twofaValidation.error_msg);
    }
    if (twofaValidation.valid === true) {
      const payload = {
        password: password,
        two_factor_auth: twoFA,
      }; // Adjust according to your API needs
      setButtonDisabled(true);
      try{
        const result = await dispatch(TwoFactorAuth(payload));
        if (result?.success) {
          onAddDialogTwoFA();
          setButtonDisabled(false);
          localStorage.setItem("two_factor_auth", twoFA)
        }
        else if (result == "Request failed with status code 404") {
          setFormError("Username or password is incorrect");
        } else {
          console.error(result);
        }
      }catch (error) {
        setButtonDisabled(false);
      }
     
    }
  }
  return (
    <React.Fragment>
      <div className="flex justify-between mb-6 border-b border-gray-200 pb-5 pt-5">
        <div>
          <h5 className="text-base text-gray-900 font-semibold mb-1">
            Security
          </h5>
          <p className="text-gray-600">
            Manage and personalize your security settings for enhanced account
            protection and data privacy.
          </p>
        </div>
        <div className="flex justify-end items-center">
          {/*permission for Read and Write */}
          <BtnLight className="w-auto px-4" disabled={permissionDisabled}>Cancel</BtnLight>
          <SmallButton className="w-auto px-4 ml-3" disabled={permissionDisabled} onClick={handleSubmit}>
            Save
          </SmallButton>
        </div>
      </div>
      <div className="flex justify-between  items-start">
        {/*permission for Read and Write */}
        <div className="w-2/3">
          <div className="mb-6 flex fleax-wrap">
            <label className="font-semibold w-1/4">Current password</label>
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-9/12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="••••••••••••••••••"
              type="password"
              value={passwords.old_password}
              disabled={permissionDisabled}
              onChange={(event) => handlePasswords(event, 'old_password')}
            />
          </div>
          <div className="mb-6 flex fleax-wrap">
            <label className="font-semibold w-1/4">New password</label>
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-9/12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="••••••••••••••••••"
              type="password"
              disabled={permissionDisabled}
              value={passwords.new_password}
              onChange={(event) => handlePasswords(event, 'new_password')}
            />
          </div>
          {passwordError && (<div className="text-red-500 text-sm mb-6 mt-0 ml-40 ">{passwordError}</div>)}
          <div className="mb-6 flex fleax-wrap">
            <label className="font-semibold w-1/4">Confirm password</label>
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-9/12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="••••••••••••••••••"
              type="password"
              disabled={permissionDisabled}
              value={passwords.repeat_Password}
              onChange={(event) => handlePasswords(event, 'repeat_Password')}
            />
          </div>
        </div>
        <div className="w-1/3 bg-blue-50 rounded p-3 mx-3">
          <p className="font-semibold text-xs text-gray-700 mb-3">
            Your password must contain:
          </p>
          {/*permission for Read and Write */}
          <Checkbox.Group vertical value={checkboxList}>
            <Checkbox disabled={permissionDisabled} value="Selection A">at least 8 Characters</Checkbox>
            <Checkbox disabled={permissionDisabled} value="Selection B">at least 8 Characters</Checkbox>
            <Checkbox disabled={permissionDisabled} value="Selection C">
              upper case letters (i.e A-Z)
            </Checkbox>
            <Checkbox disabled={permissionDisabled} value="Selection D">numbers (i.e 0-9)</Checkbox>
          </Checkbox.Group>
        </div>
      </div>
      <div className="flex">
        <Accordion open={1}>
          <Accordion.Body>
            <div className="flex items-center w-full">
              <div className="w-3/6	">
                <label className="text-base text-gray-900 font-semibold mb-1">2-step verification</label>
                <p className="text-gray-600">Add an additional layer of security to your account during login.</p>
              </div>
              <div className="w-12">
                <Switcher
                  className={"twoFaStep"}
                  onChange={onSwitcherToggle}
                  checked={twoFA}
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion>
      </div>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Your password has been updated
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onAddDialogClose()}>Close</BtnLight>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogTwoFA}
        onClose={onAddDialogTwoFA}
        onRequestClose={onAddDialogTwoFA}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#D2EBFF" />
              <rect x="4" y="4" width="48" height="48" rx="24" stroke="#E5F3FE" stroke-width="8" />
              <path d="M27.302 37.6145C27.5234 37.7436 27.6341 37.8082 27.7903 37.8417C27.9116 37.8677 28.0884 37.8677 28.2097 37.8417C28.3659 37.8082 28.4766 37.7436 28.698 37.6145C30.646 36.478 36 32.908 36 27.9996V24.1996C36 23.1257 36 22.5887 35.8345 22.2076C35.6662 21.8202 35.4986 21.6141 35.1536 21.3705C34.8141 21.1308 34.1486 20.9924 32.8177 20.7156C31.3508 20.4105 30.2243 19.8596 29.1944 19.0629C28.7005 18.6808 28.4536 18.4898 28.2603 18.4377C28.0564 18.3827 27.9436 18.3827 27.7397 18.4377C27.5464 18.4898 27.2995 18.6808 26.8056 19.0629C25.7757 19.8596 24.6492 20.4105 23.1823 20.7156C21.8514 20.9924 21.1859 21.1308 20.8464 21.3705C20.5014 21.6141 20.3338 21.8202 20.1655 22.2076C20 22.5887 20 23.1257 20 24.1996V27.9996C20 32.908 25.354 36.478 27.302 37.6145Z" stroke="#5786CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Before we can enable two-factor authentication, please verify your account.
          </p>
          <div className="flex flex-col mt-4">
            <label className="text-gray-700 font-medium mb-1">Enter your password</label>
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="••••••••••••••••••"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
            {formError && (
              <p className="text-xs text-red-600 mb-4 mt-0">
                {formError}
              </p>
            )}
          </div>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-3">
          <BtnLight disabled={buttonDisabled} onClick={onAddDialogTwoFA}>Close</BtnLight>
          <BtnPrimary disabled={buttonDisabled} onClick={handleVerify}>Verify</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default Security;
