import React, { useEffect, useState, useCallback } from 'react'
import Table from '../../../../../../common/element/Table';
import Dialog from '../../../../../../common/element/Dialog';
import BtnLight from '../../../../../../common/element/Buttons/btnLight';
import Label from '../../../../../../common/element/Label';
import Segment from '../../../../../../common/element/Segment';
import Select from '../../../../../../common/element/Select';
import Tabs from '../../../../../../common/element/Tabs';
import DatePicker from '../../../../../../common/element/DatePicker';
import Scrollbar from '../../../../../../common/element/ScrollBar';
import BtnPrimary from '../../../../../../common/element/Buttons/btnPrimary';
import * as userActions from "../../../../../../actions/index";
import Validation from '../../../../../../common/404/Validations';
import ClientInput from '../../../../../../common/element/Input/clientInput';
import { GetSpecificProviderTypeLogs, UpdateProviderType } from '../../../../../../actions/Admin';
import useUnauthorizedHandler from '../../../../../../common/snippet/useUnauthorizedHandler';
import { useDispatch } from "react-redux";
import moment from 'moment';
import ThreeDotLoader from '../../../../../../common/element/ButtonLoader/ThreeDotLoader';
const { TabNav, TabList, TabContent } = Tabs
const { Tr, Th, Td, THead, TBody } = Table

const AccountList = (props) => {
   const dispatch = useDispatch()

   const handleUnauthorizedAccess = useUnauthorizedHandler();
   const loginData = JSON.parse(localStorage?.getItem("LoginUserData"));
   let role_assignment_id;
   let role_name;

   if (loginData) {
      role_assignment_id = loginData?.data?.role_assignment_id;
      role_name = loginData?.data?.role?.role_name;
   }

   //State for open and clsoe pannel
   const [buttonDisabled, setButtonDisabled] = useState(false)
   const [dialogIsOpen, setIsOpen] = useState(false)
   const [dialogDeleteOpen, setDeleteOpen] = useState(false)
   //State For Filter Specific Data
   const [specificData, setSpecificData] = useState();
   const [editProviderId, setEditProviderId] = useState("")
   const [sortParams, setSortParams] = useState({ provider_type_id: editProviderId, sort: 'created_date', order: 'asc' });
   const [allData, setAllData] = useState({
      provider_type_name: "",
      client_pay_default: false,
      can_invite_workers: false,
      licence_fee: "",
      assignment_fee: "",
      worker_fee: "",
      validation_criteria_limit: "",
      change_effective_date: "",
   })

   //State for validation
   const [errors, setErrors] = useState({
      nameError: "",
      defaultPayError: "",
      inviteWorkerError: "",
      licenseFeeError: "",
      assignmentFeeError: "",
      workerFeeError: "",
      criteriaLimitError: "",
      effectDateError: ""
   });
   //Set option in validation criteria limit
   const [sectionOption, setSectionOption] = useState([
      { value: 'Unlimited', label: 'Unlimited' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
      { value: '6', label: '6' },
      { value: '7', label: '7' },
      { value: '8', label: '8' },
      { value: '9', label: '9' },
      { value: '10', label: '10' },
   ]);

   // Filter data based on editProviderId
   useEffect(() => {
      if (props?.allProviderData !== undefined) {
         const filteredData = props?.allProviderData?.find(item => item?.provider_type_id === editProviderId);
         if (filteredData) {
            setAllData({
               ...filteredData,
               change_effective_date: ""
            });
         }
      }
   }, [editProviderId, props?.allProviderData]);

   //Dismiss Error Message function
   const errorDissmiss = () => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         nameError: "",
         defaultPayError: "",
         inviteWorkerError: "",
         licenseFeeError: "",
         assignmentFeeError: "",
         workerFeeError: "",
         criteriaLimitError: "",
         effectDateError: ""
      }))
   }

   // Close and Open Pannel
   const openDialog = () => {
      errorDissmiss()
      setIsOpen(true)
   }
   const onDialogClose = () => {
      setIsOpen(false)
      errorDissmiss()
   }
   const openDeleteDialog = () => {
      setDeleteOpen(true)
      onDialogClose()
   }
   const onDeleteDialogClose = () => {
      if (!buttonDisabled) {
         setDeleteOpen(false)
      }
   }

   //set provider data in allData state
   const handleInputChange = (e) => {
      const { name, value } = e?.target;
      // Define a mapping between input field names and error state keys
      const errorStateKeys = {
         provider_type_name: "nameError",
         licence_fee: "licenseFeeError",
         assignment_fee: "assignmentFeeError",
         worker_fee: "workerFeeError",
         change_effective_date: "effectDateError"
      };

      // Update the form data
      setAllData({
         ...allData,
         [name]: value,
      });

      // Clear the corresponding error if the updated field is found in the mapping
      if (errorStateKeys[name]) {
         setErrors((prevErrors) => ({
            ...prevErrors,
            [errorStateKeys[name]]: "",
         }));
      }
   };


   //To select Does Client Pay By Default
   const onSingleSelectionSegmentCheck = useCallback(
      (val) => {
         setAllData((prevallData) => ({
            ...prevallData,
            client_pay_default: val[0] == "yes" ? true : false,
         }));
         setErrors((prevErrors) => ({
            ...prevErrors,
            defaultPayError: "",
         }));
      },
      [setAllData, setErrors]
   );

   //To select Invite Worker
   const onSingleSelectionSegmentInviteWorker = useCallback(
      (val) => {
         setAllData((prevAllData) => ({
            ...prevAllData,
            can_invite_workers: val[0] == "yes" ? true : false,
         }));
         setErrors((prevErrors) => ({
            ...prevErrors,
            inviteWorkerError: "",
         }));
      },
      [setAllData, setErrors]
   );

   // Update Cretriaera Limit
   const onChangeCriteriaLimit = (sectionOption) => {
      setAllData({
         ...allData,
         validation_criteria_limit: sectionOption.value,
      });
      setErrors((prevErrors) => ({
         ...prevErrors,
         criteriaLimitError: "",
      }));
   };
   useEffect(() => {
      // Check if can_invite_workers is true and worker_fee does not exist
      if (allData.can_invite_workers === true && !allData.worker_fee) {
         setAllData(prevFormData => ({
            ...prevFormData,
            worker_fee: "", // Add worker_fee with empty value to allData
         }));
      }
      // Check if worker_fee is null or empty and can_invite_workers is false
      else if ((!allData?.worker_fee || allData?.worker_fee === "") && allData?.can_invite_workers === false) {
         const { worker_fee, ...updatedFormData } = allData;
         setAllData(updatedFormData); // Remove worker_fee from allData
      }
   }, [allData?.worker_fee, allData?.can_invite_workers]);


   //Update specific User Data 
   const updateProviderType = async (e) => {
      e.preventDefault();
      // Check Permission 
      if (role_name === "super admin") {
         // Validation
         const nameValidation = Validation({ value: allData?.provider_type_name, Validations: { required: true } });
         const typeDefaultWorker = Validation({ value: allData?.client_pay_default, Validations: { required: true } });
         const typeWorkerInvite = Validation({ value: allData?.can_invite_workers, Validations: { required: true } });
         const licenseFeeValidation = Validation({ value: allData?.licence_fee, Validations: { required: true } });
         const assignmentFeeValidation = Validation({ value: allData?.assignment_fee, Validations: { required: true } });
         let workerFeeValidation;
         if (allData.can_invite_workers) {
            workerFeeValidation = Validation({ value: allData?.worker_fee, Validations: { required: true } });
         }
         else {
            workerFeeValidation = Validation({ value: allData?.worker_fee, Validations: { required: false } });
         }
         const criteriaLimitValidation = Validation({ value: allData?.validation_criteria_limit, Validations: { required: true } });
         // const updateDateValidation = Validation({ value: allData.change_effective_date, Validations: { required: true } });
         const updateDateValidation = Validation({ value: allData?.change_effective_date, Validations: { required: true } });

         if (!nameValidation.valid) { setErrors((prevErrors) => ({ ...prevErrors, nameError: nameValidation.error_msg, })); }
         if (!typeDefaultWorker.valid) { setErrors((prevErrors) => ({ ...prevErrors, defaultPayError: typeDefaultWorker.error_msg, })); }
         if (!typeWorkerInvite.valid) { setErrors((prevErrors) => ({ ...prevErrors, inviteWorkerError: typeWorkerInvite.error_msg, })); }
         if (!licenseFeeValidation.valid) { setErrors((prevErrors) => ({ ...prevErrors, licenseFeeError: licenseFeeValidation.error_msg, })); }
         if (!assignmentFeeValidation.valid) { setErrors((prevErrors) => ({ ...prevErrors, assignmentFeeError: assignmentFeeValidation.error_msg, })); }

         if (allData.worker_fee == "" && allData.can_invite_workers != false) {
            if (!workerFeeValidation.valid) { setErrors((prevErrors) => ({ ...prevErrors, workerFeeError: workerFeeValidation.error_msg, })); }
         }
         if (!criteriaLimitValidation.valid) { setErrors((prevErrors) => ({ ...prevErrors, criteriaLimitError: criteriaLimitValidation.error_msg, })); }
         if (!updateDateValidation.valid) { setErrors((prevErrors) => ({ ...prevErrors, effectDateError: updateDateValidation.error_msg, })); }

         if (nameValidation.valid && typeDefaultWorker.valid && typeWorkerInvite.valid && licenseFeeValidation.valid
            && assignmentFeeValidation.valid && criteriaLimitValidation.valid && workerFeeValidation.valid
            && updateDateValidation.valid) {

            setDeleteOpen(true)
         }
      }
   }

   //UpdateProviderType  API Call
   const updateProviderData = async () => {

      try {
         const response = await dispatch(UpdateProviderType(allData));
         if (response?.success) {
            openDeleteDialog();
            onDeleteDialogClose()
            setButtonDisabled(false)
         } else if (response?.status === 401) {
            handleUnauthorizedAccess();
            setButtonDisabled(false)
         } else {
            setButtonDisabled(false)
         }
      } catch (error) {
         console.error("An error occurred:", error);
         setButtonDisabled(false)
      }
   };
   const onUpdateRequest = async () => {
      setButtonDisabled(true)
      try {
         await updateProviderData();
         // Only setDeleteOpen(false) if updateProviderData is successful

         onDeleteDialogClose()
         setButtonDisabled(false)
      } catch (error) {
         // Handle error if needed
         console.log(error);
         setButtonDisabled(false)
      }
   };

   //Sorting and Getting SpecificProvider Data  
   const fetchData = async (params) => {
      try {
         const response = await dispatch(GetSpecificProviderTypeLogs(params));
         setSpecificData(response?.data);
      } catch (error) {
         console.error('Error fetching specific provider type logs:', error);
      }
   };
   const handleSpecificSort = (column) => {
      const newOrder = column === sortParams?.sort && sortParams?.order === 'asc' ? 'desc' : 'asc';
      setSortParams({ provider_type_id: editProviderId, sort: column, order: newOrder });
   };

   // After updating sortParams, call fetchData
   useEffect(() => {
      if (editProviderId && sortParams) {
         if (sortParams?.provider_type_id === "") {
            setSortParams((prevAllData) => ({
               ...prevAllData,
               provider_type_id: editProviderId
            }));

            fetchData({ provider_type_id: editProviderId, sort: 'created_date', order: 'asc' });
         }
      }
   }, [dispatch, editProviderId, sortParams]);

   // useEffect for fetching specific provider type logs when editProviderId changes
   useEffect(() => {
      if (editProviderId && !sortParams) {
         fetchData({ provider_type_id: editProviderId });
      }
   }, [dispatch, editProviderId]);



   //call permission api 
  
   return (
      <React.Fragment>
         <div className="overflow-hidden border rounded-lg border-gray-200">
            <Table>
               <THead>
                  <Tr>
                     <Th>
                        <span className='flex items-center gap-1' onClick={() => props?.handleSort('provider_type_name', props?.filterorder)}>
                           Provider type
                           <svg className={`transform ${props?.filtersort === 'provider_type_name' && props?.filterorder === 'asc' ? 'rotate-180' : ''}`} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round" />
                           </svg>
                        </span>
                     </Th>
                     <Th className="w-56">Payee</Th>
                     <Th className="w-56"> <span className='flex items-center gap-1'>
                        Global search
                     </span></Th>
                     <Th className="w-64">License $</Th>
                     <Th className="w-56">Assignment $</Th>
                     <Th className="w-56">Worker $</Th>
                     <Th className="w-64">Created by</Th>
                     <Th className="w-48">
                        <span className='flex items-center gap-1' onClick={() => props?.handleSort('created_date', props?.filterorder)}>
                           Created
                           <svg className={`transform ${props?.filtersort === 'created_date' && props?.filterorder === 'asc' ? 'rotate-180' : ''}`} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round" />
                           </svg>
                        </span>
                     </Th>
                  </Tr>
               </THead>
               <TBody>
                  {props?.allProviderData && props?.allProviderData?.length > 0 && (
                     props?.allProviderData.map((dataItem, index) => (
                        <Tr className='cursor-pointer' onClick={() => { openDialog(); setEditProviderId(dataItem?.provider_type_id) }} key={index}>
                           <Td>{dataItem?.provider_type_name ? dataItem?.provider_type_name : "-"}</Td>
                           <Td>{dataItem?.client_pay_default ? "Client" : "Contractor"}</Td>
                           <Td>{dataItem?.client_pay_default ? 'Yes' : 'No'}</Td>
                           <Td>{dataItem?.licence_fee ? dataItem?.licence_fee : "-"}</Td>
                           <Td>{dataItem?.assignment_fee ? dataItem?.assignment_fee : "-"}</Td>
                           <Td>{dataItem?.worker_fee ? dataItem?.worker_fee : "-"}</Td>
                           <Td>{dataItem?.createdByUser != (" " || null || "") ? dataItem?.createdByUser : "-"}</Td>

                           <Td>
                              {/* <span className='cursor-pointer' onClick={() => { openDialog(); setEditProviderId(dataItem?.provider_type_id) }}> */}
                              {dataItem?.created_date ? new Date(dataItem?.created_date).toLocaleDateString() : "-"}
                              {/* </span> */}
                           </Td>
                        </Tr>
                     ))
                  )}
               </TBody>

            </Table>
         </div>

         <Dialog
            isOpen={dialogIsOpen}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
            width={900}
         >
            <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
               <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
               </svg>
               </span>
               <div>
                  <h5 className="mb-0 text-gray-900 text-base font-semibold">Viewing Provider Type</h5>
               </div>
            </div>

            <div className="px-4">
               <Tabs defaultValue="tab1" variant="pill">
                  <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5">
                     <TabList>
                        <TabNav value="tab1" className="flex-1">Details</TabNav>
                        <TabNav value="tab2" className="flex-1">Log</TabNav>

                     </TabList>
                  </div>

                  <TabContent value="tab1" className="px-4">

                     <div className="flex mb-5 flex-wrap">
                        <Label className="font-semibold mb-1.5 text-xs w-4/12">Provider name*</Label>
                        <div className="w-8/12">

                           <ClientInput
                              placeholder="Enter account type name here"
                              className="h-9 w-full "
                              value={allData?.provider_type_name == "" ? "" : allData?.provider_type_name}
                              name="provider_type_name"
                              inputchangehandler={handleInputChange}
                              readOnly={role_name !== "super admin" ? true : false}
                           />
                           {errors.nameError && (
                              <p className="text-xs text-red-600 mt-2">{errors.nameError}</p>
                           )}
                        </div>

                     </div>

                     <div className="flex mb-5 flex-wrap">
                        <Label className="font-semibold mb-1.5 text-xs w-4/12">Does Client pay by default?*</Label>
                        <div className="w-8/12">
                           <Segment className="docType-btn"
                              value={allData?.client_pay_default ? "yes" : "no"}
                              onChange={(val) => role_name === "super admin" && onSingleSelectionSegmentCheck(val)}
                           >
                              <Segment.Item value="yes">Yes</Segment.Item>
                              <Segment.Item value="no">No</Segment.Item>
                           </Segment>

                           {errors.defaultPayError && (
                              <p className="text-xs text-red-600 mt-2">{errors.defaultPayError}</p>
                           )}
                        </div>

                     </div>

                     <div className="flex mb-5 flex-wrap ">
                        <Label className="font-semibold mb-1.5 text-xs w-4/12">Can account invite Workers?*</Label>
                        <div className="w-8/12">
                           <Segment
                              className="docType-btn"
                              value={allData?.can_invite_workers ? "yes" : "no"}
                              onChange={(val) => role_name === "super admin" && onSingleSelectionSegmentInviteWorker(val)}
                           >
                              <Segment.Item value="yes">Yes</Segment.Item>
                              <Segment.Item value="no">No</Segment.Item>
                           </Segment>

                           {errors.inviteWorkerError && (
                              <p className="text-xs text-red-600 mt-2">{errors.inviteWorkerError}</p>
                           )}
                        </div>

                     </div>

                     <div className="flex mb-5 flex-wrap">
                        <Label className="font-semibold mb-1.5 text-xs w-4/12">License Fee*</Label>
                        <div className=" w-8/12">
                           <div className="relative">
                              <div className="flex">
                                 <span className="absolute mt-2 ml-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M5 13.3337C5 15.1746 6.49238 16.667 8.33333 16.667H11.6667C13.5076 16.667 15 15.1746 15 13.3337C15 11.4927 13.5076 10.0003 11.6667 10.0003H8.33333C6.49238 10.0003 5 8.50794 5 6.66699C5 4.82604 6.49238 3.33366 8.33333 3.33366H11.6667C13.5076 3.33366 15 4.82604 15 6.66699M10 1.66699V18.3337" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                 </span>
                                 <ClientInput placeholder="Enter fee here" className="h-9 flex-1 pl-8"
                                    name="licence_fee" type="number"
                                    readOnly={role_name !== "super admin" ? true : false}
                                    value={allData?.licence_fee == "" ? "" : allData?.licence_fee}
                                    inputchangehandler={handleInputChange}
                                 />

                              </div>
                              <span className="text-xs text-gray-60 block mt-1">+ 10% GST will be applied</span>
                           </div>
                           {errors.licenseFeeError && (
                              <p className="text-xs text-red-600 mt-2">{errors.licenseFeeError}</p>
                           )}
                        </div>
                     </div>
                     <div className="flex mb-5 flex-wrap">
                        <Label className="font-semibold mb-1.5 text-xs w-4/12">Assignment Fee*</Label>
                        <div className="w-8/12">
                           <div className="relative">
                              <div className="flex">
                                 <span className="absolute mt-2 ml-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M5 13.3337C5 15.1746 6.49238 16.667 8.33333 16.667H11.6667C13.5076 16.667 15 15.1746 15 13.3337C15 11.4927 13.5076 10.0003 11.6667 10.0003H8.33333C6.49238 10.0003 5 8.50794 5 6.66699C5 4.82604 6.49238 3.33366 8.33333 3.33366H11.6667C13.5076 3.33366 15 4.82604 15 6.66699M10 1.66699V18.3337" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                 </span>
                                 <ClientInput placeholder="Enter fee here" className="h-9 flex-1 pl-8"
                                    readOnly={role_name !== "super admin" ? true : false}
                                    value={allData?.assignment_fee == "" ? "" : allData?.assignment_fee} name="assignment_fee"
                                    type="number" inputchangehandler={handleInputChange}
                                 />

                              </div>
                              <span className="text-xs text-gray-60 block mt-1">+ 10% GST will be applied</span>
                           </div>
                           {errors.assignmentFeeError && (
                              <p className="text-xs text-red-600 mt-2">{errors.assignmentFeeError}</p>
                           )}
                        </div>
                     </div>
                     {allData?.can_invite_workers && (
                        <div className="flex mb-5 flex-wrap">
                           <Label className="font-semibold mb-1.5 text-xs w-4/12">Worker Fee*</Label>
                           <div className="w-8/12">
                              <div className="relative">
                                 <div className="flex">
                                    <span className="absolute mt-2 ml-2">
                                       <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M5 13.3337C5 15.1746 6.49238 16.667 8.33333 16.667H11.6667C13.5076 16.667 15 15.1746 15 13.3337C15 11.4927 13.5076 10.0003 11.6667 10.0003H8.33333C6.49238 10.0003 5 8.50794 5 6.66699C5 4.82604 6.49238 3.33366 8.33333 3.33366H11.6667C13.5076 3.33366 15 4.82604 15 6.66699M10 1.66699V18.3337" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                       </svg>
                                    </span>
                                    <ClientInput placeholder="Enter fee here" className="h-9 flex-1 pl-8"
                                       value={allData?.worker_fee == "" ? "" : allData?.worker_fee}
                                       readOnly={role_name !== "super admin" ? true : false}
                                       name="worker_fee" type="number"
                                       inputchangehandler={handleInputChange}
                                    />

                                 </div>
                                 <span className="text-xs text-gray-60 block mt-1">+ 10% GST will be applied</span>
                              </div>
                              {errors.workerFeeError && (
                                 <p className="text-xs text-red-600 mt-2">{errors.workerFeeError}</p>
                              )}
                           </div>
                        </div>)}

                     <div className="flex mb-5 flex-wrap">
                        <Label className="font-semibold mb-1.5 text-xs w-4/12">Validation criteria limit?*</Label>
                        <div class="w-8/12 h-9">
                           <Select
                              className="select-options hide-seacrh-icon"
                              options={sectionOption}
                              readOnly={role_name !== "super admin" ? true : false}
                              value={sectionOption?.filter(function (option) {
                                 return option.value === allData?.validation_criteria_limit;
                              })}
                              placeholder="Select amount"
                              onChange={onChangeCriteriaLimit}
                           />
                           {errors.validation_criteria_limit && (
                              <p className="text-xs text-red-600 mt-2">{errors.nameError}</p>
                           )}
                        </div>

                     </div>

                     <div className="flex mb-5 flex-wrap">
                        <Label className="font-semibold mb-1.5 text-xs w-4/12">When would you like this change to<br /> take effect?*</Label>
                        <div class="w-8/12 h-9">
                           <DatePicker
                              disabled={role_name !== "super admin" ? true : false}
                              placeholder="Select Date"
                              selected={allData?.change_effective_date ? moment(allData?.change_effective_date, 'DD/MM/YYYY').toDate() : null}
                              onChange={(date) => {
                                 // Format the date before setting it in the state
                                 const formattedDate = date;
                                 setAllData({
                                    ...allData,
                                    change_effective_date: formattedDate,
                                 });
                                 // Clear the corresponding error
                                 setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    effectDateError: '',
                                 }));
                              }}
                              minDate={new Date()}
                           />
                           {errors.effectDateError && (
                              <p className="text-xs text-red-600 mt-2">{errors.effectDateError}</p>
                           )}
                        </div>

                     </div>

                  </TabContent>
                  <TabContent value="tab2">
                     <div className='h-96'>
                        <Scrollbar>
                           <div className='pr-3'>
                              <div className="overflow-hidden border rounded-lg border-gray-200">
                                 <Table>
                                    <THead>
                                       <Tr>
                                          <Th>
                                             <span className='flex items-center gap-1'>
                                                Action
                                             </span>
                                          </Th>

                                          <Th className="w-64">User</Th>

                                          <Th>
                                             <span className='flex items-center gap-1'>
                                                Timestamp
                                                <svg
                                                   className={`transform ${sortParams?.sort === 'created_date' && sortParams?.order === 'desc' ? 'rotate-180' : ''}`}
                                                   onClick={() => handleSpecificSort('created_date')}
                                                   width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                   <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                             </span>
                                          </Th>
                                       </Tr>
                                    </THead>
                                    <TBody>
                                       {specificData && specificData?.map((ele) => (
                                          <Tr key={ele?.provider_type_log_id}>
                                             <Td>
                                                {ele?.desc_html?.map((desc) => (
                                                   <span className='block mb-2' key={desc}>{desc}</span>
                                                ))}
                                             </Td>
                                             <Td>{`${ele?.proLogCreatedBy!=null?ele?.proLogCreatedBy?.first_name:""} ${ele?.proLogCreatedBy!=null?ele?.proLogCreatedBy?.last_name:""}`}</Td>
                                             <Td>{moment(ele?.created_date)?.format("DD/MM/YYYY")}</Td>
                                          </Tr>
                                       ))}

                                    </TBody>
                                 </Table>
                              </div>
                           </div>
                        </Scrollbar>
                     </div>
                  </TabContent>




               </Tabs>
            </div>
            <div className="flex dailog-footer justify-end gap-2">
               <BtnLight
                  className="px-4"
                  variant="plain"
                  onClick={onDialogClose}
               >
                  Close
               </BtnLight>
               <BtnPrimary className="px-4" variant="solid"
                  onClick={(e) => { updateProviderType(e) }}
                  disabled={role_name !== "super admin" ? true : false}
               >
                  Save changes
               </BtnPrimary>
            </div>

         </Dialog>
         <Dialog
            isOpen={dialogDeleteOpen}
            bodyOpenClassName="overflow-hidden"
            onClose={onDeleteDialogClose}
            onRequestClose={onDeleteDialogClose}

            className="customAlert"
         >
            <div className="px-6 pt-6">
               <span className="w-12 h-12 rounded-full m-auto bg-blue-100 flex items-center justify-center">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </span>
               <p className="text-base text-gray-900 font-semibold text-center mt-4">
                  Confirming that you're about to update{" "}
                  <span className="text-blue-600 font-bold">
                     {allData?.provider_type_name ? allData?.provider_type_name : "-"}
                  </span>,
                  which will have widespread implications throughout the entire Conserve system. Are you sure you want to proceed?
               </p>
                                                                                                                              
            </div>
            <div className='mt-8 flex justify-between pb-6 px-6 gap-2'>
               <BtnLight disabled={buttonDisabled} onClick={onDeleteDialogClose}>Close</BtnLight>
               <BtnPrimary disabled={buttonDisabled} className="px-4" variant="solid" onClick={onUpdateRequest}>
                  {buttonDisabled?<ThreeDotLoader/>:"Confirm"}
               </BtnPrimary>

            </div>
         </Dialog>
      </React.Fragment>
   )
}
export default AccountList