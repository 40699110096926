import React, { useState, useEffect } from "react";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import ScrollBar from "../../../../../../../common/element/ScrollBar";
import Input from "../../../../../../../common/element/Input";
import Label from "../../../../../../../common/element/Label";
import Segment from "../../../../../../../common/element/Segment";
import Select from "../../../../../../../common/element/Select/Select";
import Tooltip from "../../../../../../../common/element/Tooltip";
import {
  GetHighRiskActivityList,
  GetProviderDetailsById,
  UpdateProviderOrgOtherDetails,
} from "../../../../../../../actions/admin-provider/AllNotesEmailSettingApi";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Upload from "../../../../../../../common/element/Upload";
import PdfFile from "../../../../../../../assets/icons-svg/upload-pdf.svg";
import { decryptId } from "../../../../../../../common/encryptDecrypt/encryptdecrypt";

const Accreditations = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { id } = useParams();
  const orgId = decryptId(id);

  const [highRiskShow, setHighRiskShow] = useState(false);
  const [hideCheckList, setCheckList] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeOptional, setCountryCodeOptional] = useState("");
  
  const [phoneCode, SetPhoneCode] = useState([
   { label: "+93", value: "+93" },
   { label: "+355", value: "+355" },
   { label: "+213", value: "+213" },
   { label: "+376", value: "+376" },
   { label: "+244", value: "+244" },
   { label: "+54", value: "+54" },
   { label: "+374", value: "+374" },
   { label: "+61", value: "+61" },
   { label: "+43", value: "+43" },
   { label: "+994", value: "+994" },
   { label: "+1-242", value: "+1-242" },
   { label: "+973", value: "+973" },
   { label: "+880", value: "+880" },
   { label: "+1-246", value: "+1-246" },
   { label: "+375", value: "+375" },
   { label: "+32", value: "+32" },
   { label: "+501", value: "+501" },
   { label: "+229", value: "+229" },
   { label: "+975", value: "+975" },
   { label: "+591", value: "+591" },
   { label: "+387", value: "+387" },
   { label: "+267", value: "+267" },
   { label: "+55", value: "+55" },
   { label: "+673", value: "+673" },
   { label: "+359", value: "+359" },
   { label: "+226", value: "+226" },
   { label: "+257", value: "+257" },
   { label: "+855", value: "+855" },
   { label: "+237", value: "+237" },
   { label: "+1", value: "+1" },
   { label: "+238", value: "+238" },
   { label: "+236", value: "+236" },
   { label: "+235", value: "+235" },
   { label: "+56", value: "+56" },
   { label: "+86", value: "+86" },
   { label: "+57", value: "+57" },
   { label: "+269", value: "+269" },
   { label: "+242", value: "+242" },
   { label: "+243", value: "+243" },
   { label: "+506", value: "+506" },
   { label: "+385", value: "+385" },
   { label: "+53", value: "+53" },
   { label: "+357", value: "+357" },
   { label: "+420", value: "+420" },
   { label: "+45", value: "+45" },
   { label: "+253", value: "+253" },
   { label: "+1-767", value: "+1-767" },
   { label: "+1-809", value: "+1-809" },
   { label: "+593", value: "+593" },
   { label: "+20", value: "+20" },
   { label: "+503", value: "+503" },
   { label: "+240", value: "+240" },
   { label: "+291", value: "+291" },
   { label: "+372", value: "+372" },
   { label: "+268", value: "+268" },
   { label: "+251", value: "+251" },
   { label: "+679", value: "+679" },
   { label: "+358", value: "+358" },
   { label: "+33", value: "+33" },
   { label: "+241", value: "+241" },
   { label: "+220", value: "+220" },
   { label: "+995", value: "+995" },
   { label: "+49", value: "+49" },
   { label: "+233", value: "+233" },
   { label: "+30", value: "+30" },
   { label: "+1-473", value: "+1-473" },
   { label: "+502", value: "+502" },
   { label: "+224", value: "+224" },
   { label: "+245", value: "+245" },
   { label: "+592", value: "+592" },
   { label: "+509", value: "+509" },
   { label: "+504", value: "+504" },
   { label: "+36", value: "+36" },
   { label: "+354", value: "+354" },
   { label: "+91", value: "+91" },
   { label: "+62", value: "+62" },
   { label: "+98", value: "+98" },
   { label: "+964", value: "+964" },
   { label: "+353", value: "+353" },
   { label: "+972", value: "+972" },
   { label: "+39", value: "+39" },
   { label: "+1-876", value: "+1-876" },
   { label: "+81", value: "+81" },
   { label: "+962", value: "+962" },
   { label: "+7", value: "+7" },
   { label: "+254", value: "+254" },
   { label: "+686", value: "+686" },
   { label: "+965", value: "+965" },
   { label: "+996", value: "+996" },
   { label: "+856", value: "+856" },
   { label: "+371", value: "+371" },
   { label: "+961", value: "+961" },
   { label: "+266", value: "+266" },
   { label: "+231", value: "+231" },
   { label: "+218", value: "+218" },
   { label: "+423", value: "+423" },
   { label: "+370", value: "+370" },
   { label: "+352", value: "+352" },
   { label: "+261", value: "+261" },
   { label: "+265", value: "+265" },
   { label: "+60", value: "+60" },
   { label: "+960", value: "+960" },
   { label: "+223", value: "+223" },
   { label: "+356", value: "+356" },
   { label: "+692", value: "+692" },
   { label: "+222", value: "+222" },
   { label: "+230", value: "+230" },
   { label: "+52", value: "+52" },
   { label: "+691", value: "+691" },
   { label: "+373", value: "+373" },
   { label: "+377", value: "+377" },
   { label: "+976", value: "+976" },
   { label: "+382", value: "+382" },
   { label: "+212", value: "+212" },
   { label: "+258", value: "+258" },
   { label: "+95", value: "+95" },
   { label: "+264", value: "+264" },
   { label: "+674", value: "+674" },
   { label: "+977", value: "+977" },
   { label: "+31", value: "+31" },
   { label: "+64", value: "+64" },
   { label: "+505", value: "+505" },
   { label: "+227", value: "+227" },
   { label: "+234", value: "+234" },
 ]);
  const StateOptions = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
  ];
  const TitleOptions = [
    { value: "mr.", label: "Mr." },
    { value: "miss", label: "Miss" },
    { value: "mrs", label: "Mrs." },
  ];
  const [attachment, setAttachment] = useState();
  const [riskData, setRiskData] = useState();
  const [formData, setFormData] = useState({
    // high_risk_activity: false,
    high_risk_master_setting_id: "",
    whs_system_available: "",
    whs_system_certified: "",
    provider_org_detail_id: "",
  });
  const [whs_contact_info, setWHSContact] = useState({
    title: "",
    first_name: "",
    last_name: "",
    phone: "",
    phone_optional: "",
    email: "",
  });
  const [msaInfo, setMsaInfo] = useState({
    msa_ques_one:
      "Is your organisation required to report under the Modern Slavery Act (Cth) 2022?*",
    ans_ques_one: false,
    msa_ques_two:
      "Does your organisation have policies to deal with modern slavery?*",
    ans_ques_two: false,
    msa_ques_three:
      "Does your organisation have a person(s) overseeing modern slavery risks?*",
    ans_ques_three: false,
    msa_ques_four:
      "Are staff in your organisation trained to manage modern slavery risks?*",
    ans_ques_four: false,
    msa_ques_five:
      "Does your organisation engage low skilled migrant workers?*",
    ans_ques_five: false,
    msa_ques_six:
      "Does your organisation screen prospective suppliers to assess modern slavery risks?*",
    ans_ques_six: false,
  });
  const [uploadedFiles, setUploadedFiles] = useState();

  const highRiskShowHandle = (val) => {
    setHighRiskShow(val[0]);
  };
  const whsSystemHandle = (val) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      whs_system_available: val[0],
    }));
  };
  const whsCertifiedHandle = (val) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      whs_system_certified: val[0],
    }));
  };

  const msaInfoHandle = (val, field) => {
    setMsaInfo((prevFormData) => ({
      ...prevFormData,
      [field]: val[0],
    }));
  };

  // const showCheckHandle = (e) => {
  //       e.preventDefault();
  //       setCheckList(true);
  // };
  // const hideCheckHandle = (e) => {
  //    setCheckList(false);
  //    e.preventDefault();
  // };

  useEffect(() => {
    dispatch(GetProviderDetailsById({ organisation_id: orgId })).then(
      (response) => {
        if (response.success) {
          const arrayData = response.data;
          updateClientDatafromAPi(arrayData);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      }
    );
  }, []);

  const updateClientDatafromAPi = (arrayData) => {
    
    setHighRiskShow(
      JSON.stringify(arrayData?.provider_org_detail?.high_risk_activity)
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      high_risk_master_setting_id:
        arrayData?.provider_org_detail?.high_risk_master_setting_id,
      whs_system_available: JSON.stringify(
        arrayData?.provider_org_detail?.whs_system_available
      ),
      whs_system_certified: JSON.stringify(
        arrayData?.provider_org_detail?.whs_system_certified
      ),
      provider_org_detail_id:
        arrayData?.provider_org_detail?.provider_org_detail_id,
    }));
    setWHSContact((prevFormData) => ({
      title: arrayData?.provider_org_detail?.whs_contact_info?.title,
      first_name: arrayData?.provider_org_detail?.whs_contact_info?.first_name,
      last_name: arrayData?.provider_org_detail?.whs_contact_info?.last_name,
      phone: arrayData?.provider_org_detail?.whs_contact_info?.phone,
      phone_optional:
        arrayData?.provider_org_detail?.whs_contact_info?.phone_optional,
      email: arrayData?.provider_org_detail?.whs_contact_info?.email,
    }));
    setMsaInfo((prevFormData) => ({
      ...prevFormData,
      msa_ques_one: arrayData?.provider_org_detail?.msa_info?.msa_ques_one,
      ans_ques_one: arrayData?.provider_org_detail?.msa_info?.ans_ques_one,
      msa_ques_two: arrayData?.provider_org_detail?.msa_info?.msa_ques_two,
      ans_ques_two: arrayData?.provider_org_detail?.msa_info?.ans_ques_two,
      msa_ques_three: arrayData?.provider_org_detail?.msa_info?.msa_ques_three,
      ans_ques_three: arrayData?.provider_org_detail?.msa_info?.ans_ques_three,
      msa_ques_four: arrayData?.provider_org_detail?.msa_info?.msa_ques_four,
      ans_ques_four: arrayData?.provider_org_detail?.msa_info?.ans_ques_four,
      msa_ques_five: arrayData?.provider_org_detail?.msa_info?.msa_ques_five,
      ans_ques_five: arrayData?.provider_org_detail?.msa_info?.ans_ques_five,
      msa_ques_six: arrayData?.provider_org_detail?.msa_info?.msa_ques_six,
      ans_ques_six: arrayData?.provider_org_detail?.msa_info?.ans_ques_six,
    }));
    setCountryCode(arrayData?.provider_org_detail.whs_contact_info.phone_code);
    setCountryCodeOptional(
      arrayData?.provider_org_detail.whs_contact_info.phone_code_optional
    );
    // Fetch the file from the URL
    const fileData = [];
    const file = arrayData?.provider_org_detail?.msa_doc;
    const segments = file?.split("/");

    // Get the last segment, which should be the file name
    const fileNameWithQueryParams = segments?.[segments?.length - 1];

    // Decode the percent-encoded characters
    const decodedFileName = decodeURIComponent(fileNameWithQueryParams);

    fileData.push({
      file: file,
      fileName: decodedFileName,
    });
    //  });

    setUploadedFiles((prevAttachment) => ({
      ...prevAttachment,
      files: fileData,
    }));
  };

  useEffect(() => {
    dispatch(GetHighRiskActivityList()).then((response) => {
      if (response.success) {
        const arrayData = response?.data;
        const industryData = [];
        for (let key in arrayData) {
          industryData.push({
            label: arrayData[key]?.meta_value_one,
            value: arrayData[key]?.master_setting_id,
            // name:"ind_master_setting_ids",
          });
        }
        setRiskData(industryData);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      }
    });
  }, []);

  const inputchangehandler = (e, field) => {
    setWHSContact({ ...whs_contact_info, [field]: e });
  };

  const handleChange = (selectedOption, identifier) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      high_risk_master_setting_id: selectedOption.value,
    }));
  };

  const handleTitleChange = (selectedOption) => {
    setWHSContact((prevFormData) => ({
      ...prevFormData,
      title: selectedOption?.value,
    }));
  };

  // Handle file change in the attachment input
  const handleFileChange = (files) => {
    setAttachment(files);
  };

  const handleFileRemove = (files) => {
    // Handle the file remove logic here
    setAttachment(files);
  };

  const submitWHS = () => {
    const payload = {
      provider_org_detail_id: formData.provider_org_detail_id,
      whs_contact_info: {
        title: whs_contact_info?.title,
        first_name: whs_contact_info.first_name,
        last_name: whs_contact_info.last_name,
        phone: whs_contact_info.phone,
        phone_optional: whs_contact_info.phone_optional,
        email: whs_contact_info.email,
        phone_code:countryCode,
        phone_code_optional:countryCodeOptional
      },
      high_risk_master_setting_id: formData.high_risk_master_setting_id,
      high_risk_activity: highRiskShow,
      whs_system_available: formData.whs_system_available,
      whs_system_certified: formData.whs_system_certified,
      msa_doc: attachment,
      msa_info: { ...msaInfo },
    };

    dispatch(UpdateProviderOrgOtherDetails(payload))
      .then((response) => {
        if (response.success) {
          props.userSettingHide();
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {});
  };

  return (
    <React.Fragment>
      <div className="h-500 pr-1">
        <ScrollBar>
          <div className="pr-4 mt-7">
            <div className="flex justify-between flex-col mb-5 ">
              <h5 className="text-base text-gray-900 font-semibold mb-1">
                Work Health & Safety
              </h5>
              <p className="text-gray-600">
                Manage your WHS information and settings here.
              </p>
            </div>
            <div className="flex items-start  mb-5">
              <Label className="font-semibold w-72 font-sm">
                Do you perform high risk activities?
              </Label>
              <div className="flex items-center  pl-3 validation-filter ">
                <Segment
                  value={highRiskShow}
                  onChange={(val) => highRiskShowHandle(val)}
                  // onClick={preventDefault}
                >
                  <Segment.Item value="true">
                    <span>Yes</span>
                  </Segment.Item>
                  <Segment.Item value="false">
                    <span>No</span>
                  </Segment.Item>
                </Segment>
              </div>
            </div>
            {highRiskShow === "true" && (
              <div className="flex items-start  mb-5">
                <Label className="font-semibold w-72 font-sm">
                  High risk activity offered
                </Label>
                <div className="Pflex items-center   flex-1 pl-3 flex-wrap">
                  <Select
                    isSearchable={false}
                    placeholder="High Risk Activity"
                    options={riskData}
                    className="select-option w-full h-9"
                    value={
                      riskData &&
                      riskData.filter(
                        (options) =>
                          options.value ===
                          formData.high_risk_master_setting_id
                      )
                    }
                    onChange={(e) =>
                      handleChange(e, "high_risk_master_setting_id")
                    }
                  />
                </div>
              </div>
            )}
            <div className="flex items-start  mb-5">
              <Label className="font-semibold w-72 font-sm">WHS Contact</Label>
              <div className="Pflex items-center  flex-1 pl-3 flex-wrap">
                <div className="flex w-full gap-2">
                  <Select
                    isSearchable={false}
                    placeholder="Title*"
                    options={TitleOptions}
                    className="select-option w-2/12 mb-5"
                    value={
                      TitleOptions &&
                      TitleOptions.filter(
                        (options) => options.value === whs_contact_info.title
                      )
                    }
                    onChange={(e) => handleTitleChange(e, "title")}
                  />
                  <Input
                    type="text"
                    name="First Name"
                    placeholder="First Name*"
                    className="w-5/12 h-9 mb-5"
                    value={whs_contact_info.first_name}
                    inputchangehandler={(e) =>
                      inputchangehandler(e, "first_name")
                    }
                  />
                  <Input
                    type="text"
                    name="Last Name"
                    placeholder="Last Name*"
                    className="w-5/12 h-9 mb-5"
                    value={whs_contact_info.last_name}
                    inputchangehandler={(e) =>
                      inputchangehandler(e, "last_name")
                    }
                  />
                </div>
                <div className="flex w-full gap-2">
                  <Select
                    isSearchable={false}
                    placeholder="Title*"
                    options={phoneCode}
                    className="select-option w-2/5 mb-5"
                    value={
                      phoneCode &&
                      phoneCode.filter(
                        (options) => options.value === countryCode
                      )
                    }
                    onChange={(e) => setCountryCode(e.value)}
                  />
                  <Input
                    type="number"
                    name="Work"
                    placeholder="Work*"
                    className="w-5/12  h-9 mb-5 mr-1"
                    value={whs_contact_info.phone}
                    inputchangehandler={(e) => inputchangehandler(e, "phone")}
                  />
                  {/* <Input
                    className="w-1/12  h-9 mb-5 ml-1"
                    value={`+${countryCode}`}
                  /> */}
                  <Select
                    isSearchable={false}
                    placeholder="Title*"
                    options={phoneCode}
                    className="select-option w-2/5 mb-5"
                    value={
                      phoneCode &&
                      phoneCode.filter(
                        (options) => options.value === countryCodeOptional
                      )
                    }
                    onChange={(e) => setCountryCodeOptional(e.value)}
                  />
                  
                  <Input
                    type="text"
                    name="Mobile"
                    placeholder="Mobile(Optional)"
                    className="w-5/12  h-9 mb-5"
                    value={whs_contact_info.phone_optional}
                    inputchangehandler={(e) =>
                      inputchangehandler(e, "phone_optional")
                    }
                  />
                </div>
                <div className="flex w-full h-9">
                  <Input
                    type="text"
                    name="Email"
                    placeholder="Email"
                    className="w-6/12 h-9 mb-5"
                    value={whs_contact_info.email}
                    inputchangehandler={(e) => inputchangehandler(e, "email")}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-start  mb-5">
              <Label className="font-semibold w-72 font-sm">
                Do you have a WHS Management System?
              </Label>
              <div className="flex items-center  pl-3 validation-filter ">
                <Segment
                  value={formData.whs_system_available}
                  onChange={(val) => whsSystemHandle(val)}
                >
                  <Segment.Item value="true">
                    <span>Yes</span>
                  </Segment.Item>
                  <Segment.Item value="false">
                    <span>No</span>
                  </Segment.Item>
                </Segment>
              </div>
            </div>
            <div className="flex items-start  mb-5">
              <Label className="font-semibold w-72 font-sm">
                Is your WHS Management System externally certified?
              </Label>
              <div className="flex items-center  pl-3 validation-filter ">
                <Segment
                  value={formData.whs_system_certified}
                  onChange={(val) => whsCertifiedHandle(val)}
                >
                  <Segment.Item value="true">
                    <span>Yes</span>
                  </Segment.Item>
                  <Segment.Item value="false">
                    <span>No</span>
                  </Segment.Item>
                </Segment>
              </div>
            </div>
            <div className="flex justify-between flex-col mb-5   border-t border-gray-200 pt-5">
              <h5 className="text-base text-gray-900 font-semibold mb-1 flex gap-2  items-center">
                Modern Slavery Act (Cth) 2022
                <Tooltip
                  title="Modern Slavery Act (Cth) 2022"
                  placement="top-start"
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.06 6.5a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.833h.007m6.66-3.333a6.667 6.667 0 1 1-13.334 0 6.667 6.667 0 0 1 13.334 0z"
                      stroke="#98A2B3"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Tooltip>
              </h5>
            </div>

            <div className="flex items-start  mb-5">
              <Label className="font-semibold w-72 font-sm">
                Is your organisation required to report under the Modern Slavery
                Act (Cth) 2022*?
              </Label>
              <div className="flex items-center  pl-3 validation-filter ">
                <Segment
                  value={msaInfo.ans_ques_one}
                  onChange={(val) => msaInfoHandle(val, "ans_ques_one")}
                >
                  <Segment.Item value="true">
                    <span>Yes</span>
                  </Segment.Item>
                  <Segment.Item value="false">
                    <span>No</span>
                  </Segment.Item>
                </Segment>
              </div>
            </div>

            {/* {hideCheckList && ( */}

            <div>
              <div className="flex items-start  mb-5">
                <Label className="font-semibold w-72 font-sm">
                  Does your organisation have policies to deal with modern
                  slavery?*
                </Label>
                <div className="flex items-center  pl-3 validation-filter ">
                  <Segment
                    value={msaInfo.ans_ques_two}
                    onChange={(val) => msaInfoHandle(val, "ans_ques_two")}
                  >
                    <Segment.Item value="true">
                      <span>Yes</span>
                    </Segment.Item>
                    <Segment.Item value="false">
                      <span>No</span>
                    </Segment.Item>
                  </Segment>
                </div>
              </div>
              <div className="flex items-start  mb-5">
                <Label className="font-semibold w-72 font-sm">
                  Does your organisation have a person(s) overseeing modern
                  slavery risks?*
                </Label>
                <div className="flex items-center  pl-3 validation-filter ">
                  <Segment
                    value={msaInfo.ans_ques_three}
                    onChange={(val) => msaInfoHandle(val, "ans_ques_three")}
                  >
                    <Segment.Item value="true">
                      <span>Yes</span>
                    </Segment.Item>
                    <Segment.Item value="false">
                      <span>No</span>
                    </Segment.Item>
                  </Segment>
                </div>
              </div>
              <div className="flex items-start  mb-5">
                <Label className="font-semibold w-72 font-sm">
                  Are staff in your organisation trained to manage modern
                  slavery risks?*
                </Label>
                <div className="flex items-center  pl-3 validation-filter ">
                  <Segment
                    value={msaInfo.ans_ques_four}
                    onChange={(val) => msaInfoHandle(val, "ans_ques_four")}
                  >
                    <Segment.Item value="true">
                      <span>Yes</span>
                    </Segment.Item>
                    <Segment.Item value="false">
                      <span>No</span>
                    </Segment.Item>
                  </Segment>
                </div>
              </div>
              <div className="flex items-start  mb-5">
                <Label className="font-semibold w-72 font-sm">
                  Does your organisation engage low skilled migrant workers?*
                </Label>
                <div className="flex items-center  pl-3 validation-filter ">
                  <Segment
                    value={msaInfo.ans_ques_five}
                    onChange={(val) => msaInfoHandle(val, "ans_ques_five")}
                  >
                    <Segment.Item value="true">
                      <span>Yes</span>
                    </Segment.Item>
                    <Segment.Item value="false">
                      <span>No</span>
                    </Segment.Item>
                  </Segment>
                </div>
              </div>
              <div className="flex items-start  mb-5">
                <Label className="font-semibold w-72 font-sm">
                  Does your organisation screen prospective suppliers to assess
                  modern slavery risks?*
                </Label>
                <div className="flex items-center  pl-3 validation-filter ">
                  <Segment
                    value={msaInfo.ans_ques_six}
                    onChange={(val) => msaInfoHandle(val, "ans_ques_six")}
                  >
                    <Segment.Item value="true">
                      <span>Yes</span>
                    </Segment.Item>
                    <Segment.Item value="false">
                      <span>No</span>
                    </Segment.Item>
                  </Segment>
                </div>
              </div>

              <div className="flex items-start mb-5">
                <Label className="font-semibold w-72 font-sm">
                  The Modern Safety Statement Attachment
                </Label>
                <div className="Pflex items-center  flex-1 pl-3 validation-filter ">
                  {/* <div className="flex items-center   justify-center  flex-1 upload-img ">
                     <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white">
                        <div className="flex flex-col items-center justify-center">
                           <div className="rounded-full border-12 circle m-auto flex align-center justify-center bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10"><img src="/assets/icons-svg/upload.svg" alt="" className="w-4"/></div>
                           <p className="mb-2  text-gray-600 darks:text-gray-400"><span className="font-semibold text-blue-700">Click to upload</span><span className="text-xs"> or drag and drop</span></p>
                           <p className=" text-gray-600 darks:text-gray-400 font-inter">PDF only. Max 25mb.</p>
                        </div>
                        <input id="dropzone-file" type="file" className="hidden"
                        accept={".pdf"}
                        onChange={(e) => inputChangeHandlerFile(e.target.files[0])}/>
                     </label>
                  </div> */}

                  <div className="columns-input">
                    <div className="hideIcons wrap-doc">
                      <Upload
                        draggable
                        accept={".pdf"}
                        className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                        onChange={handleFileChange}
                        onFileRemove={handleFileRemove}
                        maxFileSize={25 * 1024 * 1024} // Max file size in bytes (25MB)
                      >
                        <div className="rounded-full border-12 circle m-auto flex align-center justify-center bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10">
                          <img
                            src="/assets/icons-svg/upload.svg"
                            alt=""
                            className="w-4"
                          />
                        </div>
                        <span className="text-blue-700 font-semibold">
                          Click to upload
                        </span>{" "}
                        <span className="text-xs"> or drag and drop</span>{" "}
                        <p className=" text-gray-600 darks:text-gray-400 font-inter">
                          PDF only. Max 25mb.
                        </p>
                      </Upload>

                      {uploadedFiles && uploadedFiles.files && (
                        <div className="upload-file flex items-center justify-between mt-2">
                          <div className="upload-file-left flex items-center">
                            <div className="upload-file-thumbnail">
                              <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                                <img src={PdfFile} alt="" />
                              </span>
                            </div>
                            <a
                              href={uploadedFiles.files[0].file}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="upload-file-name font-medium text-xs text-gray-700">
                                {uploadedFiles.files[0].fileName}
                              </span>
                            </a>
                          </div>
                          {/* <span className="upload-file-remove w-5" onClick={() => handleRemoveFile(item)}> */}
                          {/* <span className=""><img src={trashIcon} alt="" /></span>
                          </span> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollBar>
      </div>
      <div className="flex p-6 justify-end border-gray-200">
        <BtnLight
          // onClick={() => openDialogUnSave()}
          className="w-auto px-5 mr-3"
          variant="plain"
          
        >
          Close
        </BtnLight>
        <BtnPrimary onClick={() => submitWHS()} className="w-auto px-5">
          Save changes
        </BtnPrimary>
      </div>
    </React.Fragment>
  );
};
export default Accreditations;
